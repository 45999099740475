import { Fragment, useState, useEffect, } from "react";
import { Box, Typography, InputAdornment, } from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import {
  ReferenceInput,
  AutocompleteInput,
} from "react-admin";
import { PeopleDetail } from "../../../components/PeopleDetail";
import { LocationSelect } from "../../../components/LocationSelect";
import { CustomersQuickCreate } from "../../customers/quickcreate/PeopleQuickCreate";
import { ProducersQuickCreate } from "../../producers/quickcreate/PeopleQuickCreate";
import { EstablishmentSelect } from "../../../components/EstablishmentSelect";
import { useFormState } from "react-final-form";

export const PeopleSelect = ({ source, label, isSell, props }) => {
  const [sourceId, setSourceId] = useState();
  const { values } = useFormState();
  useEffect(() => {
    setSourceId(values[`${source}_id`]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values[`${source}_id`]])

  return (
    <Fragment>
      <Typography component="span" variant="h6" >{label}</Typography>
      <Box display="flex">
        <Box>
          <ReferenceInput label="nombre y apellido" source={`${source}_id`} reference={`${source}s`}    filterToQuery={searchText => ({ search: searchText })}>
            <AutocompleteInput optionText="name" optionValue="id" resettable options={{
              InputProps: {
                endAdornment: <InputAdornment position="start" style={{ marginTop: '5px' }}><SearchIcon style={{ fontSize: '1.8em' }} /></InputAdornment>
              }
            }} />
          </ReferenceInput >
        </Box>
        {sourceId &&
          <Box ml="1em" display="flex">
            <LocationSelect isSell={isSell} formData={values} source={source} label="Localidad" />
            <Box ml="1em">
              <EstablishmentSelect isSell={isSell} formData={values} source={source} label="Establecimiento" />
            </Box>
          </Box>
        }
        {source == "producer" ?
          <ProducersQuickCreate />
          :
          <CustomersQuickCreate />
        }

      </Box>
      <Box mb='2em'>
        {values[`${source}_id`] && <PeopleDetail {...props} id={values[`${source}_id`]} source={source} />}
      </Box>
    </Fragment>
  )
}
