import * as React from "react";
import { Datagrid, EditButton, Filter, List, TextField, TextInput } from 'react-admin';
import { EmptyPage } from "../../components/EmptyPage";
import { havePermission } from "../../utils/permissions";
const RoleFilter = (props) => (
    <Filter {...props}>
        <TextInput source="search" alwaysOn />
    </Filter>
);

export const RoleList = props => {
    return (
        <List filters={<RoleFilter />} {...props} sort={{ field: 'name', order: 'ASC' }} empty={<EmptyPage resourceName="Roles" />}>
            <Datagrid rowClick={havePermission('edit-roles') ? "edit" : ""}>
                <TextField source="id" />
                <TextField source="name" label="Nombre"/>
                <TextField source="title" label="Titulo"/>
                <TextField source="users_count" label="Cantidad"/>
                {havePermission('edit-roles') &&<EditButton />}
            </Datagrid>
        </List>
    );
}
