import { Fragment } from "react";
import {
  SimpleForm,
  Edit,
  ShowButton,
  useRecordContext,
} from "react-admin";
import { Box } from '@material-ui/core';

import { Title } from "../../../components/Title";
import { ActionsBack } from "../../../components/ActionsBack";
import { SimpleFieldTruck } from "../create/TruckCreate";

const Action = () => {
  const record = useRecordContext();
  return (
    <Fragment>
      <Title label="Editar Camion" />
      <Box alignSelf="flex-start">
        <ShowButton variant="outlined" basePath="/trucks" record={record} size="small" />
      </Box>
    </Fragment>
  )
}

export const TruckEdit = props => {

  return (
    <Edit
      {...props}
      actions={<ActionsBack />}
    >
      <SimpleForm
        redirect='list'
        variant='standard'
        margin='dense'
      >
        <Box display="flex" justifyContent="space-between" margin='1em 0' fullWidth >
          <Action />
        </Box>
        <SimpleFieldTruck />
      </SimpleForm>
    </Edit>
  );
};
