import * as React from 'react';
import { Box, Card, CardActions, Button, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import { styled } from '@material-ui/core/styles';
import { havePermission } from '../../utils/permissions';

const useStyles = makeStyles(({
  root: {
    backgroundColor: '#5C7B34',
    color: '#fff',
    padding: 20,
    margin: '16px 0',
  },
  actions: {
    padding: 0,
    flexWrap: 'wrap',
    '& a': {
      marginTop: '16px',
    },
  },
}));

const CustomButton = styled(Button)(({ theme }) => ({
  color: '#343434',
  backgroundColor: 'white',
  opacity: 0.9,
  '&:hover': {
    opacity: 1,
    backgroundColor: 'white'
  },
}));

const CustomCard = styled(Card)(({ theme }) => ({
  color: 'white',
  backgroundColor: '#5C7B34',
}));

const Welcome = () => {
  const classes = useStyles();
  return (
    <CustomCard className={classes.root}>
      <Box display="flex">
        <Box flex="1">
          <Typography variant="h4" component="h1" gutterBottom>
            Bienvenido a Ayasuar
          </Typography>
          {havePermission('see-dashboard-panel') && <>
            <Box maxWidth="40em">
              <Typography variant="body1" component="p" gutterBottom>
                Panel de acceso rápido.
              </Typography>
            </Box>
            <CardActions className={classes.actions}>
              <CustomButton
                color="primary"
                startIcon={<AddIcon />}
                href="#/customers/create"
              >
                Cliente
              </CustomButton>
              <CustomButton
                color="primary"
                href="#/producers/create"
                startIcon={<AddIcon />}
              >
                Productor
              </CustomButton>
              <CustomButton
                color="primary"
                href="#/truckowners/create"
                startIcon={<AddIcon />}
              >
                Dueño Camión
              </CustomButton>
            </CardActions>
          </>}
        </Box>

        {/* <Box
                    display={{ xs: 'none', sm: 'none', md: 'block' }}
                    width="16em"
                    height="9em"
                    overflow="hidden"
                /> */}
      </Box>
    </CustomCard>
  );
};

export default Welcome;
