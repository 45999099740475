import {
  List,
  Datagrid,
  FunctionField,
  TextField,
  ReferenceField,
  useListContext,
  TopToolbar,
  SelectField
} from "react-admin";
import { cloneElement } from "react";
import { DealFilter } from "./DealFilter";
import { StatusField } from "../../../components/StatusField";
import { havePermission } from '../../../utils/permissions';
import CreateDialog from "../DealCreateDialog";


const ListActions = (props) => {
  const {resource} = useListContext();
  return(
    <TopToolbar display="flex" justifyContent="space-between" alignItems="center">
    {cloneElement(props.filters, { context: 'button' })}
    {props.hasCreate && havePermission(`create-${resource}`) && <CreateDialog {...props}/>}
  </TopToolbar>
  );
}

const CEREAL_TYPE = [
  { id: "maiz", name: "Maiz" },
  { id: "trigo", name: "Trigo" },
  { id: "girasol", name: "Girasol" },
  { id: "soja", name: "Soja" },
  { id: "cebada", name: 'Cebada' },
  { id: "sorgo_granifero", name: 'Sorgo granifero' }
];


export const DealList = props => {
  return (
    <List
      {...props}
      resource="deals"
      empty={false}
      title={props.title}
      perPage={10}
      sort={{ field: "id", order: "DESC" }}
      filters={<DealFilter />}
      filter={{deal_type : props.deal_type}}
      bulkActionButtons={false}
      actions={<ListActions labelButton="Nuevo Negocio" {...props}/>}
    >
      <Datagrid title='Negocio Cereales' rowClick={"show"} {...props}>
        <TextField source="id" />
        <ReferenceField source="producer_id" reference="producers" label="Productor" link="show">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="customer_id" reference="customers" label="Cliente" link="show">
          <TextField source="name" />
        </ReferenceField>
        <SelectField source="type" choices={CEREAL_TYPE} label="Especie" />
        <FunctionField label="Compra/Venta" render={record => record ? `$  ${record?.price_buy} / $ ${record?.price_sell} ` : 0} />
        <FunctionField label="Vencimiento" render={record => record ? `${record?.due_date_buy ? record.due_date_buy : '-'} / ${record?.due_date_sell ? record.due_date_sell : '-'}` : ''} />
        <StatusField
          sortable={true}
          source='status'
          label='Estado' />
      </Datagrid>
    </List>
  );
};
