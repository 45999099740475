import {
  SelectInput,
  AutocompleteInput,
  ReferenceInput,
  TextInput,
  required,
} from "react-admin";
import { Box, Typography , makeStyles} from "@material-ui/core";
import { useFormState } from "react-final-form";
const useStyles = makeStyles({
    input:{
        maxWidth: '250px'
    }
})

export const OptionCharge = ({money_box_id}) => {
    const classes = useStyles();
    const {values} = useFormState();
    const choices=[{id:'customer', name:'Cliente'},{id:'other', name:'Otro'}];
    return(
      <Box>
         <Typography component="span" variant="h6">Origen</Typography>
        <Box display="flex">
         <Box mr="1em">
          <SelectInput choices={choices} source="origin_type" label="Origen" validate={[required()]}/>
         </Box>
          {values?.origin_type && values?.origin_type !== 'other' ? 
            <ReferenceInput source={"origin_id"} reference={'customers'} label={"Nombre"}  validate={[required()]} className={classes.input}    filterToQuery={searchText => ({ search: searchText })}>
              <AutocompleteInput source="origin_id" optionValue="id" optionText="name"  resettable/>
            </ReferenceInput>
            :
            values?.origin_type !== 'null' ?
            <TextInput source="entity" label="Nombre" className={classes.input} validate={[required()]}/>
            :
            null
          }
        </Box>
        <Typography component="span" variant="h6">Destino</Typography>
        <Box display="flex">
          <Box mr="1em">
            <SelectInput choices={[{id:'moneybox', name:'Caja'}]} defaultValue={'moneybox'} source="destination_type" label="Destino"/>
          </Box>
            {money_box_id && <ReferenceInput source={"destination_id"} reference={'money_box'} label={"Nombre"} defaultValue={money_box_id} validate={[required()]} className={classes.input}>
              <AutocompleteInput source="destination_id" optionValue="id" optionText="name"  resettable/>
            </ReferenceInput>
            }
        </Box>
      </Box>
    )
  }
