import React, {
  useState,
  useEffect,
  Fragment,
} from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useVersion } from 'react-admin';
import Welcome from './Welcome';
import MonetizationOnRoundedIcon from '@material-ui/icons/MonetizationOnRounded';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import CardWithIcon from './CardWithIcon';
import { MoneyBoxDetail } from './MoneyBoxDetail';
import { havePermission } from '../../utils/permissions';
import { formatter } from '../../utils/NumberFormatter';

const styles = {
  flex: { display: 'flex' },
  flexColumn: { display: 'flex', flexDirection: 'column' },
  leftCol: { flex: 1, marginRight: '0.5em' },
  rightCol: { flex: 1, marginLeft: '0.5em' },
  singleCol: { marginTop: '1em', marginBottom: '1em' },
  graph: { gridArea: '2 / 1 / 2 / 2' },
  income: { gridArea: '1 / 1 / 2 / 2' },
  stock: { gridArea: '2 / 3 / 3 / 4' },
  debt: { gridArea: '1 / 4 / 4 / 4' },
  totalorders: { gridArea: '1 / 2 / 2 / 3' },
  pendingorders: { gridArea: '1 / 3 / 2 / 4' },
  grid: {
    display: 'grid',
    gridGap: '1.5em',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridTemplateRows: '0.25fr  1fr)'
  }
};

const Spacer = () => <span style={{ width: '1em' }} />;
const VerticalSpacer = () => <span style={{ height: '1em', margin: '0.5em 0 0.5em 0' }} />;

export const Dashboard = (props) => {
  const [state, setState] = useState(null);
  const [money, setMoney] = useState(null);
  const version = useVersion();
  const theme = useTheme();
  const isXSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));

  const getDeals = async () => {
    try {
      const token = localStorage.getItem('token');
      fetch(process.env.REACT_APP_API_HOST_ADMIN + `/deals/dashboard`, {
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${token}`
        }
      })
        .then((res) => {
          if (!res.ok) {
            return null;
          }
          return res.json();
        }).then((json) => {
          if (json) {
            setState(json);
          } else {
            setState(null)
          }
        })
    } catch (e) {
      console.log(e)
    }
  }

  const getMoneyBox = async () => {
    try {
      const token = localStorage.getItem('token');
      fetch(process.env.REACT_APP_API_HOST_ADMIN + `/money_box/all`, {
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${token}`
        }
      })
        .then((res) => {
          if (!res.ok) {
            return null;
          }
          return res.json();
        }).then((json) => {
          if (json) {
            setMoney(json);
          } else {
            setMoney(null)
          }
        })
    } catch (e) {
      console.log(e)
    }
  }



  useEffect(() => {
    getDeals();
    getMoneyBox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [version]);


  return isXSmall ? (
    <div>
      <div style={styles.flexColumn}>
        <Welcome />
        {havePermission('list-dashboard-orders') &&
        <CardWithIcon
          to={{ pathname: '/deals', search: `filter=${JSON.stringify({ id: state?.pending_deals?.ids })}` }}
          icon={BusinessCenterIcon}
          title={"Negocios pendientes"}
          subtitle={state?.pending_deals?.total || 0}
          buttonText="VER DETALLE"
        />}
        {havePermission('list-dashboard-money') && 
        <Fragment>
          <VerticalSpacer />
          <CardWithIcon
            to={{ pathname: '/', search: 'filter:{}' }}
            icon={MonetizationOnRoundedIcon}
            title={'Estado fiscal'}
            subtitle={0}
            decoration={'+$'}
            buttonText="VER DETALLE"
          />
          <VerticalSpacer />
          <CardWithIcon
            to={{ pathname: '/deals', search: `filter=${JSON.stringify({ id: state?.outdated_payment?.ids })}` }}
            icon={MonetizationOnRoundedIcon}
            title={'Pagos vencidos'}
            color={true}
            subtitle={formatter.format(state?.outdated_payment?.total || 0)}
            decoration={'$'}
            buttonText="VER DETALLE"
          />
          <VerticalSpacer />
          <CardWithIcon
            to={{ pathname: '/deals', search: `filter=${JSON.stringify({ id: state?.outdated_payd?.ids })}` }}
            icon={MonetizationOnRoundedIcon}
            title={'Cobros vencidos'}
            color={true}
            subtitle={formatter.format(state?.outdated_payd?.total || 0)}
            decoration={'$'}
            buttonText="VER DETALLE"
          />
          <VerticalSpacer />
          <MoneyBoxDetail money_box={formatter.format(money)} />
          <VerticalSpacer />
        </Fragment>
        }

      </div>
    </div>
  ) : isSmall ? (
    <div style={styles.flexColumn}>
      <div style={styles.singleCol}>
        <Welcome />
      </div>
      {havePermission('list-dashboard-orders') &&
      <div style={styles.singleCol}>
        <CardWithIcon
          to={{ pathname: '/deals', search: `filter=${JSON.stringify({ id: state?.pending_deals?.ids })}` }}
          icon={BusinessCenterIcon}
          title={"Negocios pendientes"}
          subtitle={state?.pending_deals?.total || 0}
          buttonText="VER DETALLE"
        />
        <Spacer />
      </div>}
      {havePermission('list-dashboard-money') && 
      <Fragment>
      <div style={styles.singleCol}>
        <CardWithIcon
          to={{ pathname: '/', search: 'filter:{}' }}
          icon={MonetizationOnRoundedIcon}
          title={'Estado fiscal'}
          subtitle={formatter.format(0)}
          decoration={'$'}
          buttonText="VER DETALLE"
        />
        <Spacer />
      </div>
      <div style={styles.singleCol}>
        <CardWithIcon
          to={{ pathname: '/deals', search: `filter=${JSON.stringify({ id: state?.outdated_payment?.ids })}` }}
          icon={MonetizationOnRoundedIcon}
          title={'Pagos vencidos'}
          color={true}
          subtitle={formatter.format(state?.outdated_payment?.total || 0)}
          decoration={'$'}
          buttonText="VER DETALLE"
        />
        <Spacer />
      </div>
      <div style={styles.singleCol}>
        <CardWithIcon
          to={{ pathname: '/deals', search: `filter=${JSON.stringify({ id: state?.outdated_payd?.ids })}` }}
          icon={MonetizationOnRoundedIcon}
          title={'Cobros vencidos'}
          color={true}
          subtitle={formatter.format(state?.outdated_payd?.total || 0)}
          decoration={'$'}
          buttonText="VER DETALLE"
        />
      </div>
      <div style={styles.singleCol}>
        <MoneyBoxDetail money_box={formatter.format(money)} />
        <Spacer />
      </div>
      <div style={styles.singleCol}>
        <Spacer />
      </div>
      </Fragment>}
    </div>
  ) : (
    <>
      <Welcome />
      <div style={styles.grid}>
      {havePermission('list-dashboard-orders') &&
        <div style={styles.income}>
          <CardWithIcon
            to={{ pathname: '/deals', search: `filter=${JSON.stringify({ id: state?.pending_deals?.ids })}` }}
            icon={BusinessCenterIcon}
            title={"Negocios pendientes"}
            subtitle={state?.pending_deals?.total || 0}
            buttonText="VER DETALLE"
          />
        </div>}
        {havePermission('list-dashboard-money') && 
        <Fragment>
        <div style={styles.graph}>
          <MoneyBoxDetail money_box={money} />
        </div>
        <div style={styles.debt}>
          <CardWithIcon
            to={{ pathname: '/deals', search: `filter=${JSON.stringify({ id: state?.outdated_payd?.ids })}` }}
            icon={MonetizationOnRoundedIcon}
            title={'Cobros vencidos'}
            color={true}
            subtitle={state?.outdated_payd?.total || 0}
            decoration={'$'}
            buttonText="VER DETALLE"
          />
        </div>
        <div style={styles.stock}>
        </div>
        <div style={styles.totalorders}>
          <CardWithIcon
            to={{ pathname: '/', search: 'filter:{}' }}
            icon={MonetizationOnRoundedIcon}
            title={'Estado fiscal'}
            subtitle={0}
            decoration={'$'}
            buttonText="VER DETALLE"
          >
          </CardWithIcon>
        </div>
        <div style={styles.pendingorders}>
          <CardWithIcon
            to={{ pathname: '/deals', search: `filter=${JSON.stringify({ id: state?.outdated_payment?.ids })}` }}
            icon={MonetizationOnRoundedIcon}
            title={'Pagos vencidos'}
            color={true}
            subtitle={state?.outdated_payment?.total || 0}
            decoration={'$'}
            buttonText="VER DETALLE"
          />
          <Spacer />
        </div>
        </Fragment>}
      </div>

    </>
  );
};
