import {
  SimpleForm,
  TextInput,
  Edit,
  required,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin";
import { ActionsBack } from "../../../components/ActionsBack";

export const UserEdit = ({ permissions, ...props }) => {
  return (
    <Edit {...props} title="Editar usuario" actions={<ActionsBack />}>
      <SimpleForm variant="standard" margin="dense">
        <TextInput
          source="first_name"
          label="Nombre"
          variant="outlined"
          validate={required()}
        />
        <TextInput source="last_name" label="Apellido" variant="outlined" />
        <TextInput
          source="email"
          label="Emails"
          type="email"
          variant="outlined"
          validate={[required()]}
        />
        <TextInput
          source="phone"
          label="Telefono"
          type="phone"
          variant="outlined"
          validate={[required()]}
        />
        <TextInput
          source="password"
          label="Contraseña"
          type="password"
          variant="outlined"
        />
        {permissions &&
          permissions.some(
            (permission) =>
              permission.name === "*" || permission.name === "edit-roles"
          ) && (
            <ReferenceInput
              label="Perfil"
              source="roles"
              filterToQuery={searchText => ({ search: searchText })}
              reference="roles"
              variant="outlined"
              validate={[required()]}
            >
              <AutocompleteInput optionText="name" />
            </ReferenceInput>
          )}
      </SimpleForm>
    </Edit>
  );
};
