import { Fragment, useCallback, useEffect, useState } from "react";
import { Box, Divider } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import {
  TabbedForm,
  FormTab,
  UPDATE,
  Edit,
  TextInput,
  showNotification,
  useRedirect,
  useRefresh,
  RecordContextProvider,
  useRecordContext,
} from "react-admin";
import { ActionsBack } from "../../../components/ActionsBack";
import { PeopleSelect } from "./PeopleSelect";
import { Cargo } from "../cargo/Cargo";
import { Transport } from "./Transport";
import { getBody } from "./variables";
import { Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useFormState } from "react-final-form";

const useStyles = makeStyles({
  none: {
    display: "none",
    margin: 0,
    padding: 0,
    height: 0,
  },
  root: {
    "& .MuiTabs-root": {
      minHeight: 0,
    },
  },
  error: {
    color: "#FF0000!important",
  },
});

const Observation = ({ source }) => {
  return (
    <Box display="flex" flexDirection="column" mt="1.5em">
      <Typography component="span" variant="h6">
        Observaciones
      </Typography>
      <TextInput
        label="Comentarios"
        source={`${source}_observations`}
        multiline={true}
        style={{ maxWidth: "500px" }}
      />
    </Box>
  );
};
const getInvoices = (transactions) => {
  let arr = [];
  transactions?.forEach((item) => {
    if (item?.invoice) {
      arr.push(item.invoice);
    }
  });
  return arr;
};

const formatting = (deal, source = "buy") => {
  const newDeal = {};

  newDeal[`${source}_id`] = deal[`operation${source}`]?.id;
  newDeal[`${source}_due_date`] = deal[`operation${source}`]?.due_date;
  newDeal[`${source}_amount_paid`] = deal[`operation${source}`]?.amount_paid;
  newDeal[`${source}_expenses`] = deal[`operation${source}`]?.expenses;
  newDeal[`${source}_total_expenses`] =
    deal[`operation${source}`]?.total_expenses;
  newDeal[`${source == "buy" ? "producer" : "customer"}_location`] =
    deal[`operation${source}`]?.location;
  newDeal[`${source == "buy" ? "producer" : "customer"}_settlement`] =
    deal[`operation${source}`]?.settlement;
  newDeal[`${source}_observations`] = deal[`operation${source}`]?.observations;
  newDeal[`${source}_due_date`] = deal[`operation${source}`]?.due_date;
  newDeal[`${source}_date`] = deal[`operation${source}`]?.date;
  newDeal[`type`] = deal[`operation${source}`]?.type;
  newDeal[`${source}_operable_id`] = deal[`operation${source}`]?.operable_id;
  newDeal[`${source}_cargo_type`] =
    deal[`operation${source}`]?.operable?.cargo_type;
  newDeal[`${source == "buy" ? "producer" : "customer"}_id`] =
    deal[`operation${source}`]?.operable?.people_id;
  newDeal[`${source == "buy" ? "producer" : "customer"}_type`] =
    deal[`operation${source}`]?.operable?.people_type;
  newDeal[`${source}_invoices`] = getInvoices(
    deal[`operation${source}`]?.transactions
  );
  newDeal[`deal_type`] = deal?.deal_type;
  newDeal[`${source}_cargo_id`] =
    deal[`operation${source}`]?.operable?.cargo?.id;
  newDeal[`${source}_quantity`] =
    deal[`operation${source}`]?.operable?.cargo?.quantity;
  newDeal.transports = deal?.transportations ? deal?.transportations : null;
  if (deal?.deal_type != "ganaderia") {
    newDeal[`${source}_depletion`] =
      deal[`operation${source}`]?.operable?.cargo?.depletion;
    newDeal[`${source}_humidity`] =
      deal[`operation${source}`]?.operable?.cargo?.humidity;
    newDeal[`${source}_extra_percentages`] = deal[`operation${source}`]
      ?.operable?.cargo?.extra_percentages
      ? JSON.parse(
          deal[`operation${source}`]?.operable?.cargo?.extra_percentages
        )
      : [];
    newDeal[`${source}_price_per_unit`] =
      deal[`operation${source}`]?.operable?.cargo?.price_per_unit;
  }

  return newDeal;
};

const TabBuy = ({ dealType, ...props }) => {
  const classes = useStyles();
  const { errors } = useFormState();
  const keys = Object.keys(errors);
  const error = keys?.find((a) => a?.includes("buy"));
  return (
    <FormTab label="Compra" className={error ? classes.error : ""} {...props}>
      <Box mt="1em" fullWidth>
        <PeopleSelect label="Productor" source="producer" />
        <Divider />
        <Cargo source="buy" deal_type={dealType} />
        <Divider />
        <Observation source="buy" />
      </Box>
    </FormTab>
  );
};

const TabSell = ({ dealType, ...props }) => {
  const classes = useStyles();
  const { errors } = useFormState();
  const keys = Object.keys(errors);
  const error = keys?.find((a) => a?.includes("sell"));
  return (
    <FormTab label="Venta" className={error ? classes.error : ""} {...props}>
      <Box mt="1em" fullWidth>
        <PeopleSelect isSell={true} label="Cliente" source="customer" />
        <Divider />
        <Cargo source="sell" deal_type={dealType} />
        <Divider />
        <Transport source="sell" />
        <Observation source="sell" />
      </Box>
    </FormTab>
  );
};

const Tabs = ({ handleSave, submitting, dealType }) => {
  const classes = useStyles();
  const record = useRecordContext();
  const [showButton, setShowButton] = useState(false);
  const [hasSell, setHasSell] = useState(false);
  let newDeal = {};
  newDeal = record?.operationbuy ? formatting(record, "buy") : {};
  let sellDeal = record?.operationsell ? formatting(record, "sell") : {};
  Object.assign(newDeal, sellDeal);
  newDeal.id = record?.id;

  useEffect(() => {
    if (record?.operationsell == null) {
      setShowButton(true);
    }
  }, [record?.operationsell]);

  const handleSetDeal = () => {
    setHasSell(true);
    setShowButton(false);
  };

  if (!record || !newDeal?.type) return null;
  return (
    <Fragment>
      {showButton && (
        <Box m="1em">
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleSetDeal()}
          >
            Agregar Venta
          </Button>
        </Box>
      )}
      {record?.operationsell == null && !hasSell && (
        <RecordContextProvider record={newDeal}>
          <TabbedForm
            redirect="list"
            variant="standard"
            margin="dense"
            save={handleSave}
            saving={submitting}
            record={newDeal}
            className={classes.root}
          >
            <TabBuy dealType={dealType} />
            <FormTab label="Transporte">
              <Transport />
            </FormTab>
          </TabbedForm>
        </RecordContextProvider>
      )}
      {record?.operationbuy == null && (
        <RecordContextProvider record={newDeal}>
          <TabbedForm
            redirect="list"
            variant="standard"
            margin="dense"
            save={handleSave}
            saving={submitting}
            record={newDeal}
            className={classes.root}
          >
            <TabSell dealType={dealType}/>
            <FormTab label="Transporte">
              <Transport />
            </FormTab>
          </TabbedForm>
        </RecordContextProvider>
      )}
      {(record?.operationsell !== null || hasSell) &&
        record?.operationbuy !== null && (
          <RecordContextProvider record={newDeal}>
            <TabbedForm
              redirect="list"
              variant="standard"
              margin="dense"
              save={handleSave}
              record={newDeal}
              saving={submitting}
            >
              <TabBuy dealType={dealType} />
              <TabSell dealType={dealType}/>
              <FormTab label="Transporte">
                <Transport />
              </FormTab>
            </TabbedForm>
          </RecordContextProvider>
        )}
    </Fragment>
  );
};

export const DealEdit = (props) => {
  const submitting = useSelector((state) => state.admin.loading > 0);
  const dispatch = useDispatch();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const handleSave = useCallback(
    (values) => {
      let record = getBody(values, props); // obtiene el objeto que se envia a la api , en formato buy and sell
      dispatch({
        type: "UPDATE",
        payload: { id: record.id, data: record },
        meta: {
          fetch: UPDATE,
          resource: "deals",
          onSuccess: {
            callback: () => {
              dispatch(
                showNotification("El Negocio ha sido actualizado", "info"),
                redirect(props.basePath),
                refresh()
              );
            },
          },
          onFailure: {
            callback: ({ error }) => {
              dispatch(showNotification(error.message, "error"));
            },
          },
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  return (
    <Edit
      {...props}
      title={"Editar " + props?.title + " - " + props?.id}
      actions={<ActionsBack />}
      resource={"deals"}
    >
      <Tabs
        handleSave={handleSave}
        submitting={submitting}
        dealType={props?.deal_type}
      />
    </Edit>
  );
};
