import { useState, useEffect, Fragment } from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  NumberField,
  FunctionField,
  useRecordContext,
  SelectField,
  ReferenceField,
  DateField,
  ArrayField,
  Datagrid
} from 'react-admin';
import { Box, Divider, Button } from '@material-ui/core';
import { CustomLabeled } from '../../../components/CustomLabeled';
import { Typography } from '@material-ui/core';
import { StatusField } from '../../../components/StatusField';
import { Loading } from 'ra-ui-materialui';
import { makeStyles } from "@material-ui/core/styles";
import { InvoiceDetail } from "../../invoices/InvoiceDetail";
import { ActionsBack } from '../../../components/ActionsBack';
import { Link } from "react-router-dom";
import EditIcon from '@material-ui/icons/Edit';
import { havePermission } from '../../../utils/permissions';
import { formatter } from '../../../utils/NumberFormatter';
import FormattedNumberField from '../../../components/FormattedNumberField';

const useStyles = makeStyles({
  text: {
    fontWeight: 600,
    fontSize: '1.4em'
  },
  link: {
    fontWeight: 500,
    textDecoration: 'underline',
    fontSize: '1em'
  },
  textfield: {
    fontWeight: 500
  },
  spice: {
    fontSize: '1em',
    fontWeight: 500
  }
});

const CEREAL_TYPE = [
  { id: "maiz", name: "Maiz" },
  { id: "trigo", name: "Trigo" },
  { id: "girasol", name: "Girasol" },
  { id: "soja", name: "Soja" },
  { id: "cebada", name: 'Cebada' },
  { id: "sorgo_granifero", name: 'Sorgo granifero' }
];

const ComisionField = () => {
  const record = useRecordContext();
  const classes = useStyles();
  if (!record) return <Loading />
  const getComision = () => {
    if (record.price_sell && record.price_buy) {
      return (record.price_sell - record.price_buy);
    } else {
      if (record.price_buy) {
        return (-1) * record.price_buy
      } else {
        if (record.price_sell) {
          return record.price_buy
        }
      }
    }
    return 0;
  }
  const buy = record.price_buy ? record.price_buy : 0;
  const sell = record.price_sell ? record.price_sell : 0;
  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="subtitle1" component="span" className={classes.text}>${formatter.format(getComision())}</Typography>
      <Typography variant="subtitle2" component="span">{`C:$${formatter.format(buy)}/ V:$${formatter.format(sell)} `}</Typography>
    </Box>
  )
}

const getNeto = (cargo) => {
  if (cargo) {
    const quantity = cargo.quantity * 1000;
    const extra = cargo?.extra_percentages ? JSON.parse(cargo.extra_percentages)?.reduce((acc, item) => acc + item, 0) : 0;
    const merma = (quantity / 100 * (cargo.depletion + extra)).toFixed(2);
    return ((quantity - merma) / 1000).toFixed(2);
  }
  return 0;
}

const getMerma = (cargo) => {
  if (cargo) {
    const quantity = cargo.quantity * 1000;
    const extra = cargo?.extra_percentages ? JSON.parse(cargo.extra_percentages)?.reduce((acc, item) => acc + item, 0) : 0;
    return (quantity / 100 * (cargo.depletion + extra)).toFixed(2);
  }
  return 0;
}

const NetoField = () => {
  const { operationbuy, operationsell } = useRecordContext();
  const classes = useStyles();
  const [neto, setNeto] = useState(0);
  const [netoBuy, setNetoBuy] = useState(0);
  const [netoSell, setNetoSell] = useState(0);

  useEffect(() => {
    let netobuy = getNeto(operationbuy?.operable?.cargo);
    let netosell = getNeto(operationsell?.operable?.cargo);
    setNetoBuy(Number(netobuy));
    setNetoSell(Number(netosell));
    if (netobuy !== 0 && netosell !== 0) {
      setNeto((netosell - netobuy));
    } else {
      if (netobuy !== 0) {
        setNeto(netobuy)
      } else {
        setNeto(netosell);
      }
    }
  }, [operationbuy, operationsell])

  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="subtitle1" component="span" className={classes.text}>{formatter.format(Number(neto))}tn</Typography>
      <Typography variant="subtitle2" component="span">{`C:${netoBuy ? formatter.format(netoBuy) : 0}tn/ V:${netoSell ? formatter.format(netoSell) : 0}tn `}</Typography>
    </Box>
  )
}

const PeopleDetailShow = ({ source }) => {
  const classes = useStyles();
  const record = useRecordContext();
  const data = source === "buy" ? record.operationbuy : record.operationsell;
  return (
    <Box display="flex" justifyContent="space-between" maxWidth="50vw" mt="1em">
      <Box mr="1em">
        <CustomLabeled label="NOMBRE / RAZÓN SOCIAL">
          <ReferenceField source="people_id" record={data?.operable} reference={source == "buy" ? "producers" : "customers"} label={source == "buy" ? "Productor" : "Cliente"} link="show">
            <TextField source="name" className={classes.link} />
          </ReferenceField>
        </CustomLabeled>
      </Box>
      <Box mr="1em">
        <CustomLabeled label="LOCALIDAD">
          <TextField source="location" record={data} className={classes.textfield} />
        </CustomLabeled>
      </Box>
      <Box>
        <CustomLabeled label="ESTABLECIMIENTO">
          <TextField source="settlement" record={data} className={classes.textfield} />
        </CustomLabeled>
      </Box>
    </Box>
  )
}

const ExpensesDetail = ({ source }) => {
  const classes = useStyles();
  const record = useRecordContext();
  const data = source === "buy" ? record.operationbuy : record.operationsell;
  const expenses = data?.expenses ? data.expenses : null;
  return (
    <Box display="flex">
      {expenses?.map((item, key) => (
        <Box key={key} mt="0.5em" mr="0.5em">
          <CustomLabeled label={`${item.description}`}>
            <FormattedNumberField className={classes.textfield} source={item?.amount}/>
          </CustomLabeled>
        </Box>
      ))}
    </Box>)
}

const InvoicesDetailShow = ({ source }) => {
  const record = useRecordContext();
  const classes = useStyles();
  const data = source === "buy" ? record.operationbuy?.transactions : record.operationsell?.transactions;
  const [invoices, setInvoices] = useState([]);
  const [total, setTotal] = useState(0);

  const getInvoices = ( transactions ) =>{
    let arr = [];
    transactions?.forEach(item => 
    {
        if (item?.invoice){
            arr.push(item.invoice);
        }
    });
    return arr;
}
  
  useEffect(() => {
    if (data) {
      let invoices = getInvoices(data);
      setTotal(invoices?.reduce((acc, item) => { return acc + item.iva; }, 0))
      setInvoices(invoices);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record])

  const [show, setShow] = useState(null);
  if (data?.length === 0) return "-";

  const showDetail = (item) => {
    setShow(item);
  }
  return (
    <Fragment>
      <Box maxWidth="300px">
        {invoices?.map((item, index) => (
          <Box display="flex" justifyContent="space-between" alignItems="center" key={index}>
            <Box display="flex">
              <Box mr="1em">
                <DateField source="date" record={item} label="" locales="es-AR" options={{ timeZone: 'UTC' }} style={{fontSize: "16px", marginRight: "1em"}}/>
                <FormattedNumberField source={item?.amount}/>
              </Box>
            </Box>
            <Button onClick={() => showDetail(item)}>VER DETALLE</Button>
          </Box>
        ))}
        <InvoiceDetail show={show} setShow={setShow} />
      </Box>
      <Box mt="0.5em">
        <Typography className={classes.textfield}>Total IVA: ${formatter.format(total || 0)}</Typography>
      </Box>
    </Fragment>
  )
}

const ANIMAL_TYPE = [
  { id: "madre", name: "Madre" },
  { id: "ternero", name: "Ternero" },
  { id: "toro", name: "Toro" },
];

const AnimalsCargoDetail = ({ source }) => {
  const record = useRecordContext();
  const data = source === "buy" ? record.operationbuy?.operable?.cargo : record.operationsell?.operable?.cargo;
  return (
    <Fragment>
      <Typography variant="h6" component="span" style={{ fontSize: '1.2em' }}>Carga</Typography>
      <Box display="flex" flexDirection="column" mt="1em">
        <Box display="flex" justifyContent="space-between" maxWidth="800px">
          <ArrayField source="animals" label="Animales" record={data}>
            <Datagrid>
              <SelectField source={'type'} choices={ANIMAL_TYPE} label="Tipo de animal" />
              <FunctionField render={record => `$${formatter.format(record?.price_per_unit)}`} label="Precio unidad" />
              <TextField source={'quantity'} label="Cantidad" />
              <FunctionField render={record => `$${formatter.format(record?.quantity * record?.price_per_unit)}`} label="Subtotal" />
            </Datagrid>
          </ArrayField>
        </Box>
        <Box mt="1em">
          {/* TODO add extra_percentages merma array  */}
          <CustomLabeled label="Detalle de gastos">
            <ExpensesDetail source={source} />
          </CustomLabeled>
        </Box>
        <Box mt="1em">
          <CustomLabeled label="Facturación" >
            <InvoicesDetailShow source={source} />
          </CustomLabeled>

        </Box>
      </Box>
    </Fragment>
  )
}

const CerealCargoDetail = ({ source }) => {
  const classes = useStyles();
  const record = useRecordContext();
  const data = source === "buy" ? record.operationbuy?.operable?.cargo : record.operationsell?.operable?.cargo;
  const extra_percentages = data?.extra_percentages ? JSON.parse(data?.extra_percentages) : null;
  return (
    <Fragment>
      <Typography variant="h6" component="span" style={{ fontSize: '1.2em' }}>Carga</Typography>
      <Box display="flex" flexDirection="column" mt="1em">
        <Box display="flex" justifyContent="space-between" maxWidth="800px">
          <Box>
            <CustomLabeled label="Toneladas">
              <TextField source="quantity" record={data} className={classes.textfield} />
            </CustomLabeled>
          </Box>
          <Box>
            <CustomLabeled label="Humedad">
              <FunctionField
                label="Humidity"
                record={data}
                render={record => `${record.humidity == 1 ? 0 : record.humidity}`}
                className={classes.textfield}
              />
            </CustomLabeled>
          </Box>
          <Box>
            <CustomLabeled label="Merma">
              <FunctionField
                label="Merma"
                record={data}
                render={record => `${record.depletion}%`}
                className={classes.textfield}
              />
            </CustomLabeled>
          </Box>
          {extra_percentages &&
            <Box>
              <CustomLabeled label="Merma maniplueo">
                {extra_percentages?.map((item, key) => (
                  <Typography key={key} className={classes.textfield}>{item}% {key > 1 && key < extra_percentages.length ? ',' : ''}</Typography>
                ))}
              </CustomLabeled>
            </Box>
          }

          <Box>
            <CustomLabeled label="Merma KG">
              <Typography className={classes.textfield}>{getMerma(data)}</Typography>
            </CustomLabeled>
          </Box>
        </Box>
        <Box mt="1em">
          {/* TODO add extra_percentages merma array  */}
          <CustomLabeled label="Detalle de gastos">
            <ExpensesDetail source={source} />
          </CustomLabeled>
        </Box>
        <Box mt="1em">
          <CustomLabeled label="Facturación" >
            <InvoicesDetailShow source={source} />
          </CustomLabeled>
        </Box>
      </Box>
    </Fragment>
  )
}

const Cargo = ({ source, ...props }) => {
  const record = useRecordContext();
  if (!record) return null;
  return (
    <Fragment>
      {record?.deal_type !== "ganaderia" ?
        <CerealCargoDetail source={source} />
        :
        <AnimalsCargoDetail source={source} />
      }
    </Fragment>
  )
}

const Dates = ({ source }) => {
  const classes = useStyles();
  const record = useRecordContext();
  const data = source === "buy" ? record.operationbuy : record.operationsell;
  return (
    <Box display="flex">
      <Box mr="3em">
        <CustomLabeled label={source == "buy" ? "Fecha de carga" : "Fecha de descarga"}>
          <DateField source="date" record={data} locales="es-AR" options={{ timeZone: 'UTC' }} className={classes.textfield} />
        </CustomLabeled>
      </Box>
      <Box>
        <CustomLabeled label="Fecha de vencimiento">
          <DateField source="due_date" record={data} locales="es-AR" options={{ timeZone: 'UTC' }} className={classes.textfield} />
        </CustomLabeled>
      </Box>
    </Box>
  )
}

const PlantsDetail = ({ ...props }) => {
  if (props?.record?.deal_type == "ganaderia") return null;
  return (
    <Box display="flex" justifyContent="space-between" maxWidth='900px' >
      <Box >
        <CustomLabeled label="NETO">
          <NetoField />
        </CustomLabeled>
      </Box>
      <Box>
        <CustomLabeled label="COMISIÓN">
          <ComisionField />
        </CustomLabeled>
      </Box>
      <Box>
        <CustomLabeled label="ESTADO">
          <StatusField />
        </CustomLabeled>
      </Box>
    </Box>
  )
}

const AnimalsDetail = ({ ...props }) => {
  if (props?.record?.deal_type !== "ganaderia") return null;
  return (
    <Box display="flex" justifyContent="space-between" maxWidth='400px' >
      <Box>
        <CustomLabeled label="COMISIÓN">
          <ComisionField />
        </CustomLabeled>
      </Box>
      <Box>
        <CustomLabeled label="ESTADO">
          <StatusField />
        </CustomLabeled>
      </Box>
    </Box>
  )
}

const TransportDetail = () => {
  const record = useRecordContext();
  const classes = useStyles();
  const transports = record?.transportations;
  if (!transports || transports?.length  === 0 ) return null;
  return (
    <Fragment>
          <Box margin="1em 0">
            <Typography variant="h6" component="span" style={{ fontSize: '1.5em' }}>Transporte</Typography>
          </Box>
      {transports?.map((data, index) => (
        <Fragment key={index}>
          <Box display="flex" justifyContent="space-between" maxWidth='800px' >
            <Box>
              <CustomLabeled label="CHOFER">
                <TextField source='driver' record={data} className={classes.textfield} />
              </CustomLabeled>
            </Box>
            <Box>
              <CustomLabeled label="CAMION">
                <ReferenceField source="truck_id" record={data} reference='trucks' link="show">
                  <TextField source="plate" className={classes.textfield} />
                </ReferenceField>
              </CustomLabeled>
            </Box>
            <Box>
              <CustomLabeled label="TARIFA POR TN" >
                <NumberField source="transporting_per_ton" record={data} className={classes.textfield} locales="es-AR" options={{ style: 'currency', currency: 'ARS' }} />
              </CustomLabeled>
            </Box>
            <Box>
              <CustomLabeled label="TOTAL">
                <NumberField source="transporting_fee" record={data} className={classes.textfield} locales="es-AR" options={{ style: 'currency', currency: 'ARS' }} />
              </CustomLabeled>
            </Box>
          </Box>
          <Divider style={{ margin: '1.5em 0' }} />
        </Fragment>
      ))}
    </Fragment>
  )
}

const Buy = ({ ...props }) => {
  if (props?.record?.operationbuy == null) return null;
  return (
    <Fragment>
      <Box display="flex" flexDirection="column" mt="2em">
        <Typography variant="h5" component="span" style={{ fontWeight: 600 }}>Detalle de la compra</Typography>
        <Typography variant="h6" component="span" style={{ marginTop: "1em", fontSize: '1.2em' }}>Productor</Typography>
        <PeopleDetailShow source="buy" />
      </Box>
      <Divider style={{ margin: '1.5em 0' }} />
      <Box my="1em">
        <Typography variant="h6" component="span" style={{ marginBottom: "0.5em", fontSize: '1.2em' }}>Fechas</Typography>
        <Dates source="buy" />
      </Box>

      <Cargo source="buy" />
      <Divider style={{ margin: '1.5em 0' }} />
      <Box display="flex" flexDirection="column">
        <Typography variant="h6" component="span" style={{ marginBottom: "0.5em", fontSize: '1.2em' }}>Observaciones</Typography>
        <TextField source="operationbuy.observations" />
      </Box>
    </Fragment>
  )
}

const Sell = ({ ...props }) => {
  if (props?.record?.operationsell == null) return null;
  return (
    <Fragment>
      <Box display="flex" flexDirection="column" mt="2em">
        <Typography variant="h5" component="span" style={{ fontWeight: 600 }}>Detalle de la venta</Typography>
        <Typography variant="h6" component="span" style={{ marginTop: "1em" }}>Cliente</Typography>
        <PeopleDetailShow source="sell" />
      </Box>
      <Divider style={{ margin: '1.5em 0' }} />
      <Box my="1em">
        <Typography variant="h6" component="span" style={{ marginBottom: "0.5em", fontSize: '1.2em' }}>Fechas</Typography>
        <Dates source="sell" />
      </Box>
      <Cargo source="sell" />
      <Divider style={{ margin: '1.5em 0' }} />

      <Box display="flex" flexDirection="column" mb="2em">
        <Typography variant="h6" component="span" style={{ marginBottom: "0.5em" }}>Observaciones</Typography>
        <TextField source="operationsell.observations" />
      </Box>
    </Fragment>
  )
}

const Title = () => {
  const { type } = useRecordContext();
  return <span>Negocio {type ? `${type}` : ''}</span>;
}

const Spice = () => {
  const { deal_type } = useRecordContext();
  const classes = useStyles();
  if (deal_type == "ganaderia") return null;
  return (
    <SelectField source="type" choices={CEREAL_TYPE} className={classes.spice} />
  )
}

export const DealShow = ({ ...props }) => {

  return (
    <Show title={<Title />}{...props} resource="deals" actions={<ActionsBack />}>
      <SimpleShowLayout>
        <Box display="flex" justifyContent="space-between" m='1em 0' >
          <Typography variant='h3' >Negocio #{props.id} <Spice /></Typography>
          <Box>
            {havePermission('edit-deals') && <Button 
            style={{border:'none'}}
            component={Link}  
            startIcon={<EditIcon />}
            variant="outlined"
            to={`${props.basePath}/${props.id}`}>Editar</Button>
            }
            </Box>
        </Box>
        <PlantsDetail />
        <AnimalsDetail />
        <Buy />
        <Sell />
        <TransportDetail  />
      </SimpleShowLayout>
    </Show>
  )
}
