import React from 'react'
import Button from '@material-ui/core/Button'
import { withRouter } from 'react-router'
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import { useHistory } from 'react-router-dom';

const BackButton = ({ history: { goBack },redirect, children }) => {
  let history = useHistory();
  const handleClick = () =>{
    if (redirect){
      history.push(`${redirect}`);
    }else{
      goBack()
    }
  }
  return (<Button  onClick={()=>handleClick()} startIcon={<ChevronLeft />}>
    {children}
  </Button>
)
}

export default withRouter(BackButton)
