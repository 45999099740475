import React, { Fragment } from "react";
import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CREATE,
  UPDATE,
  SaveButton,
  SimpleForm,
  Toolbar,
  showNotification,
  TextInput,
  FormDataConsumer,
  SimpleFormIterator,
  ArrayInput,
  ReferenceInput,
  AutocompleteInput,
  Create,
} from "react-admin";
import { Box, makeStyles, InputAdornment } from "@material-ui/core";
import { CustomAddButton } from "../../../components/CustomAddButton";
import { CustomDeleteButton } from "../../../components/CustomDeleteButton";
import CancelButton from "./CancelButton";
import { TruckOwnerQuickCreate } from "../../truckowners/quickcreate/PeopleQuickCreate";
import SearchIcon from "@material-ui/icons/Search";
import { validateRequired } from "../../../utils/Validations";

export const SimpleTruckFields = (props) => {
  return (
    <Fragment>
      <Box>
        <TextInput source="plate" label="Patente" validate={validateRequired} />
      </Box>
      <Box>
        <TextInput source="brand" label="Marca" validate={validateRequired} />
      </Box>
      <Box>
        <TextInput source="model" label="Modelo" validate={validateRequired} />
      </Box>
      <ArrayInput
        source="drivers"
        label="Choferes"
        style={{ maxWidth: "46vw" }}
      >
        <SimpleFormIterator
          disableReordering={true}
          getItemLabel={(index) => ""}
          addButton={<CustomAddButton label={"AGREGAR CHOFER"} {...props} />}
          removeButton={<CustomDeleteButton {...props} />}
        >
          <FormDataConsumer>
            {({ getSource }) => {
              return (
                <Box display="flex" flexDirection="column" ml={"1em"}>
                  <Box display="flex">
                    <Box mr={3}>
                      <TextInput
                        source={getSource("name")}
                        label="Nombre"
                        variant="filled"
                        validate={validateRequired}
                      />
                    </Box>
                    <Box mr={3}>
                      <TextInput
                        source={getSource("phone")}
                        label="Telefono"
                        variant="filled"
                        validate={validateRequired}
                      />
                    </Box>
                  </Box>
                </Box>
              );
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </Fragment>
  );
};

const PostQuickCreateToolbar = ({ submitting, onCancel, ...props }) => (
  <Toolbar
    {...props}
    style={{
      width: "100%",
      boxSizing: "border-box",
      display: "flex",
      justifyContent: "space-between",
    }}
  >
    <SaveButton />
    <CancelButton onClick={onCancel} />
  </Toolbar>
);

const useStyles = makeStyles({
  form: { padding: 0 },
});

const TruckDialog = ({
  onCancel,
  onSave,
  record,
  owner_id,
  data,
  ...props
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const submitting = useSelector((state) => state.admin.loading > 0);

  const handleSave = useCallback(
    (values) => {
      if (!data) {
        dispatch({
          type: "QUICK_CREATE",
          payload: { data: values },
          meta: {
            fetch: CREATE,
            resource: "trucks",
            onSuccess: {
              callback: ({ payload: { data } }) => {
                onSave(data);
                dispatch(
                  showNotification(
                    "Camion " + data.plate + "creado con éxito",
                    "info"
                  )
                );
              },
            },
            onFailure: {
              callback: ({ error }) => {
                dispatch(showNotification(error.message, "error"));
              },
            },
          },
        });
      } else {
        dispatch({
          type: "UPDATE",
          payload: { id: data.id, data: values },
          meta: {
            fetch: UPDATE,
            resource: "trucks",
            onSuccess: {
              callback: ({ payload: { data } }) => {
                onSave(data);
                dispatch(
                  showNotification(
                    "Camion " + data.plate + "creado con éxito",
                    "info"
                  )
                );
              },
            },
            onFailure: {
              callback: ({ error }) => {
                dispatch(showNotification(error.message, "error"));
              },
            },
          },
        });
      }
    },
    [dispatch, onSave]
  );

  return (
    <Create {...props}>
      <SimpleForm
        save={handleSave}
        saving={submitting}
        redirect={false}
        initialValues={data}
        toolbar={
          <PostQuickCreateToolbar onCancel={onCancel} submitting={submitting} />
        }
        classes={{ form: classes.form }}
        {...props}
      >
        <Box display="flex" fullWidth maxWidth={"400px"}>
          <ReferenceInput
            source="truckowner_id"
            reference="truckowners"
            filterToQuery={searchText => ({ search: searchText })}
            label="Dueño del camion"
            defaultValue={owner_id}
          >
            <AutocompleteInput
              optionText="name"
              optionValue="id"
              resettable
              options={{
                InputProps: {
                  endAdornment: (
                    <InputAdornment
                      position="start"
                      style={{ marginTop: "5px" }}
                    >
                      <SearchIcon style={{ fontSize: "1.8em" }} />
                    </InputAdornment>
                  ),
                },
              }}
            />
          </ReferenceInput>
          {!owner_id && <TruckOwnerQuickCreate />}
        </Box>
        {/*** truck owen select adn quick create */}
        <SimpleTruckFields />
      </SimpleForm>
    </Create>
  );
};

export default TruckDialog;
