import { Fragment } from "react";
import {
  SimpleForm,
  TextInput,
  Edit,
  FormDataConsumer,
  SimpleFormIterator,
  ArrayInput,
  ShowButton,
  useRecordContext
} from "react-admin";
import { Box, makeStyles, Typography } from '@material-ui/core';
import { CustomAddButton } from '../../../components/CustomAddButton';
import { CustomDeleteButton } from '../../../components/CustomDeleteButton';
import { Title } from "../../../components/Title";
import { ActionsBack } from "../../../components/ActionsBack";
import { PeopleSimpleFields } from "../quickcreate/PeopleDialog";
import { LocationInput, CustomName } from "../create/LocationSelect";


const useStyles = makeStyles({
  array: {
    alignItems: 'center',
  }
})
const Action = () => {
  const record = useRecordContext();
  return (
    <Fragment>
      <Title label="Editar Cliente" />
      <Box alignSelf="flex-start">
        <ShowButton variant="outlined" basePath="/customers" record={record} size="small" />
      </Box>
    </Fragment>
  )
}

export const PeopleEdit = props => {
  const classes = useStyles();
  return (
    <Edit
      {...props}
      actions={<ActionsBack />}
    >
      <SimpleForm
        variant='standard'
        margin='dense'
      >
        <Box display="flex" justifyContent="space-between" margin='1em 0' fullWidth >
          <Action />
        </Box>
        <PeopleSimpleFields />
        <Typography variant="subtitle2" component="span">Establecimientos</Typography>
        <ArrayInput source='settlements' label="" style={{ maxWidth: '46vw' }} >
          <SimpleFormIterator disableReordering={true} getItemLabel={(index) => ''} addButton={<CustomAddButton label={"AGREGAR ESTABLECIMIENTO"} {...props} />} removeButton={<CustomDeleteButton  {...props} className={classes.array} />}>
            <FormDataConsumer>
              {({ getSource, scopedFormData }) => {
                return (
                  <Box display='flex' flexDirection='column' ml={'1em'}>
                    <Box display="flex">
                      <Box mr={3}>
                        <LocationInput source={getSource('locale')}/>
                        {scopedFormData?.locale && <CustomName source={getSource("localename")} record={scopedFormData}/>}
                      </Box>
                      <Box mr={3}>
                        <TextInput source={getSource("settlement")} label="Establecimiento" variant="filled" />
                      </Box>
                    </Box>
                  </Box>
                );
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};
