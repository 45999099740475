import { useState, useEffect } from "react";
import { Box, Typography, InputAdornment, makeStyles, Button } from "@material-ui/core";
import {
  required,
  DateInput,
  NumberInput,
  SelectInput,
  FormDataConsumer,
  ArrayInput,
  SimpleFormIterator,
  DateField,
  NumberField,
} from "react-admin";
import { useField, useFormState } from "react-final-form";
import { CustomAddButton } from '../../../components/CustomAddButton';
import { CustomDeleteButton } from '../../../components/CustomDeleteButton';
import { InvoiceQuickCreate } from "../../invoices/quickcreate/InvoiceQuickCreate";
import { InvoiceDetail } from "../../invoices/InvoiceDetail";
import { CargoAnimalDetail } from "./CargoAnimalDetail";

const useStyles = makeStyles({
  array: {
    alignItems: 'center',
    '& ul li:first-child': {
      width: '0!important',
    },
    '& div.ra-input': {
      marginLeft: '0!important'
    }
  },
  spaceR: {
    marginRight: '1em',
  },
  spaceL: {
    marginLeft: '1em',
  },
  mgtop: {
    marginTop: 0,
  },
  text: {
    fontWeight: '6  00'
  },
  button: {
    maxWidth: '200px',
    marginTop: '1em'
  }
})


/* // { id: "afrechillo", name: "Afrechillo" }, */

const ANIMAL_TYPE = [
  { id: "madre", name: "Madre" },
  { id: "ternero", name: "Ternero" },
  { id: "toro", name: "Toro" },
];


const EXPENSES_TYPE = [
  { id: 'vacunacion', name: 'Vacunación' },
  { id: 'sangrado', name: 'Sangrado' },
  { id: 'analisis', name: 'Analisis' },
  { id: 'combustible', name: 'Combustible' },
];

export const AnimalCargo = ({ formData, source, props }) => {
  const classes = useStyles();
  const { values } = useFormState();
  const [invoices, setInvoices] = useState(values[`${source}_invoices`] || []);
  const { input: invoices_items } = useField(`${source}_invoices`);
  const [show, setShow] = useState(null);

  useEffect(() => {
    if (invoices?.length > 0) {
      invoices_items.onChange(invoices);
    } else {
      invoices_items.onChange([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoices]);

  const showDetail = (item) => {
    setShow(item);
  }

  return (
    <Box mt="1.5em" mb="1.5em">
      <Typography component="span" variant="h6" >Carga</Typography>
      <Box>
        <Box display="flex">
          <DateInput source={`${source}_date`} validate={[required()]} label={`Fecha de ${source === 'buy' ? 'compra' : 'venta'}`} />
          <DateInput source={`${source}_due_date`} label="Vencimiento de pago" className={classes.spaceL} />
        </Box>
        <Box>
          <ArrayInput source={`${source}_animals`} label="" style={{ maxWidth: '46vw' }}  >
            <SimpleFormIterator disableReordering={true} getItemLabel={(index) => ''} addButton={<CustomAddButton label={"AGREGAR ANIMAL"} {...props} />} removeButton={<CustomDeleteButton  {...props} />}>
              <FormDataConsumer>
                {({ getSource }) => {
                  return (
                    <Box display='flex' flexDirection='column' ml={'1em'}>
                      <Box display="flex">
                        <Box mr="1em">
                          <SelectInput source={getSource("type")} choices={ANIMAL_TYPE} label="Tipo de animal" className={classes.spaceR}  />
                        </Box>
                        <Box mr="1em">
                          <NumberInput source={getSource('quantity')} label={"Cantidad"} style={{ marginRight: '1em' }} validate={[required()]} />
                        </Box>
                        <Box mr="1em">
                          <NumberInput source={getSource(`price_per_unit`)} label="Precio por Unidad" InputProps={{
                            startAdornment: (<InputAdornment position='start'>$</InputAdornment>)
                          }} validate={[required()]} />
                        </Box>
                      </Box>
                    </Box>
                  );
                }}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </Box>

        <Typography component="span" variant="h6" >Gastos</Typography>
        <Box>
          <ArrayInput source={`${source}_expenses`} label="" style={{ maxWidth: '46vw' }}  >
            <SimpleFormIterator disableReordering={true} getItemLabel={(index) => ''} addButton={<CustomAddButton label={"AGREGAR GASTO"} {...props} />} removeButton={<CustomDeleteButton  {...props} />}>
              <FormDataConsumer>
                {({ getSource }) => {
                  return (
                    <Box display='flex' flexDirection='column' ml={'1em'}>
                      <Box display="flex">
                        <Box mr={3}>
                          <SelectInput source={getSource("description")} label="Gasto" variant="filled" validate={[required()]} choices={EXPENSES_TYPE} />
                        </Box>
                        <Box mr={3}>
                          <NumberInput source={getSource("amount")} label="Importe" variant="filled" validate={[required()]} InputProps={{
                            startAdornment: (<InputAdornment position='start'>$</InputAdornment>)
                          }} />
                        </Box>
                      </Box>
                    </Box>
                  );
                }}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </Box>
        <Box >
          <CargoAnimalDetail source={source} formData={formData} />
        </Box>
        <Box display="flex" flexDirection="column" mt="1.5em" >
          <Typography component="span" variant="h6" >Facturación</Typography>

          {invoices?.length > 0 && invoices.map((item, index) => (
            <Box display="flex" justifyContent="space-between" key={index}>
              <Box display="flex">
                <Box mr="1em">
                  <DateField source="date" record={item} label="" locales="es-AR" options={{ timeZone: 'UTC' }} />
                </Box>
                <NumberField source="amount" record={item} label="" locales="es-AR" options={{ style: 'currency', currency: 'ARS' }} />
              </Box>
              <Button onClick={() => showDetail(item)}>VER DETALLE</Button>
            </Box>
          ))}
          <InvoiceQuickCreate setInvoices={setInvoices} />
          <InvoiceDetail show={show} setShow={setShow} />
        </Box>
      </Box>
    </Box>
  )
}
