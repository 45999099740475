
import { useState, useEffect } from "react";
import {
  SelectInput,
} from "react-admin";

import { useFormState } from "react-final-form";


export const DealShow = () => {
    const { values } = useFormState();
    const [data, setData] = useState();
    const getDeals = async (type, id, money_box_id) => {
      try {
        const token = localStorage.getItem('token');
        fetch(process.env.REACT_APP_API_HOST_ADMIN + `/deals/fromperson?type=${type}&id=${id}&money_box_id=${money_box_id}`, {
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${token}`
          }
        })
          .then((res) => {
            if (!res.ok) {
              return null;
            }
            return res.json();
          }).then((json) => {
            if (json?.length > 0) {
              setData(json);
            } else {
              setData(null)
            }
          })
      } catch (e) {
        console.log(e)
      }
    }
  
    useEffect(() => {
      if (values?.origin_type == "customer" && values?.origin_id) {
        getDeals(values?.origin_type, values?.origin_id,values?.money_box_id);
      }else{
        if (values?.destination_type == "producer" && values?.destination_id) {
          getDeals(values?.destination_type, values?.destination_id,values?.money_box_id);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values?.origin_type, values?.origin_id, values?.destination_id, values?.destination_type])
    if (!data) return null;
  
    return (
      <SelectInput source="deal_id" choices={data} optionText={v => `${v.id} - ${v.deal_type} - ${new Date(v.created_at).toLocaleDateString('es-AR')} | c:${v.price_buy}/v:${v.price_sell}`} optionValue="id" label="Negocio " />
    )
  }