
import { Fragment, useState, useEffect } from "react";
import { useField, useFormState } from "react-final-form";
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AutocompleteInput, SelectInput, ReferenceInput, NumberInput, required, useGetOne, FormDataConsumer, ArrayInput, SimpleFormIterator } from "react-admin";
import { Typography, Box, InputAdornment, makeStyles, LinearProgress } from "@material-ui/core";
import { BorderBox } from "../../../components/BorderBox";
import { CustomLabeled } from "../../../components/CustomLabeled";
import { PriceField } from "../../../components/PriceField";
import { TruckQuickCreate } from "../../trucks/quickcreate/TruckQuickCreate";
import { CustomAddButton } from '../../../components/CustomAddButton';
import { CustomDeleteButton } from '../../../components/CustomDeleteButton';
import { TruckOwnerQuickCreate } from "../../truckowners/quickcreate/PeopleQuickCreate";

const useStyles = makeStyles({
  text: {
    fontWeight: '6  00'
  },
  mediaquery: {
    width: '40%',
    ['@media (min-width:768px)']: { // eslint-disable-line no-useless-computed-key
      width: '100%',
    }
  },
  link: {
    color: '#5C7B34',
    fontWeight: '500',
    fontSize: '1.2em',
    textDecoration: 'none',
    '&:hover': {
      color: '#5C7B34',
    }
  },
});


const DriverSelect = ({ record, getSource }) => {

  const data = useSelector(state => state.admin.resources.trucks.data);
  if (!record?.truck_id) return null;
  const choices = data[record?.truck_id]?.drivers || [];

  return (
    <AutocompleteInput source={getSource(`driver`)} choices={choices} label="Conductor" optionValue="name" />
  )
}

const TruckownerStatus = ({ truckowner }) => {
  const { data, loaded } = useGetOne('truckowners', truckowner.id);
  const [value, setValue] = useState(null);
  useEffect(() => {
    if (data && data?.debt !== undefined) {
      setValue(data);
    }
  }, [data, truckowner?.id])
  if (!loaded) return <LinearProgress />
  return (
    <Fragment>
      <Box >
        <CustomLabeled label="DEBE" style={{ color: '#818181' }}>
          <PriceField source="debt" data={value} />
        </CustomLabeled>
      </Box>
      <Box marginleft='1em'>
        <CustomLabeled label="HABER" style={{ color: '#818181' }}>
          <PriceField source="having" data={value} />
        </CustomLabeled>
      </Box>
      <Box marginleft='1em'>
        <CustomLabeled label="SALDO" style={{ color: '#818181' }}>
          <PriceField source="balance" data={value} />
        </CustomLabeled>
      </Box>
    </Fragment>
  )
}

const TransportDetail = ({ record }) => {
  const classes = useStyles();
  const data = useSelector(state => state.admin.resources.trucks.data);
  if (!record?.truck_id) return null;
  const truckowner = data[record?.truck_id]?.truckowner || null;
  if (!truckowner) return null;
  const buttonAction = { pathname: `/truckowners/${truckowner?.id}/show` };
  return (
    <BorderBox >
      <Typography component="span" variant="h6" style={{ fontSize: '1em' }} >{truckowner?.name} </Typography>
      <Box display="flex" justifyContent="space-between" className={classes.mediaquery}>
        <TruckownerStatus truckowner={truckowner} />
      </Box>
      <Box>
        <Link to={buttonAction} target="_blank" className={classes.link}>VER DETALLE</Link>
      </Box>
    </BorderBox>
  )
}

const TransportFee = ({ getSource, record }) => {
  const { input: transport } = useField(`${getSource('transporting_fee')}`);
  const { values } = useFormState();
  const source = record?.transporting_payment || undefined;

  const getTransport = () => {
    let transporting = 0;
    if (values[`${source}_quantity`] !== 0 && record?.transporting_payment && record?.transporting_per_ton) {
      let extra = record?.transporting_extra || 0;
      if (values[`${source}_quantity`]) {
        transporting = record?.transporting_per_ton * values[`${source}_quantity`] + extra || 0;
      }
    }
    return transporting;
  }

  useEffect(() => {
    let result = getTransport();
    transport.onChange(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record?.transporting_per_ton, record?.transporting_extra, record?.transporting_payment, values[`${source}_quantity`]])
  return (
    <NumberInput source={getSource('transporting_fee')}
      label="Costo"
      style={{ marginLeft: '1em' }}
      disabled
      defaultValue={0}
      InputProps={{
        startAdornment: (<InputAdornment position='start'>$</InputAdornment>)
      }} />
  )
}

export const Transport = () => {
  const { values } = useFormState();
  return (
    <Box mt="1.5em">
      <Box display="flex" flexDirection="column" >
        <Typography component="span" variant="h6" >Transporte</Typography>
        <ArrayInput source="transports" label="Transportes utilizados">
          <SimpleFormIterator disableReordering={true} getItemLabel={(index) => ''} addButton={<CustomAddButton label={"AGREGAR TRANSPORTE"} />} removeButton={<CustomDeleteButton />}>
            <FormDataConsumer>
              {({ scopedFormData, getSource }) => {
                return (
                  <Box>
                  <Box display="flex">
                    <Box>
                      <ReferenceInput
                        source={getSource("truck_owners_id")}
                        reference="truckowners"
                        filterToQuery={searchText => ({ search: searchText })}
                        label="Dueño Camion"
                      >
                        <AutocompleteInput
                          source={getSource("truck_owners_id")}
                          optionValue="id"
                          optionText="name"
                          resettable
                        />
                      </ReferenceInput>
                    </Box>
                    <TruckOwnerQuickCreate />
                  </Box>
                  {scopedFormData?.truck_owners_id && (
                    <Box display="flex">
                      <Box>
                      <ReferenceInput
                        source={getSource("truck_id")}
                        filterToQuery={searchText => ({ search: searchText })}
                        filter={{
                          truckowner_id: scopedFormData.truck_owners_id,
                        }}
                        reference="trucks"
                        label="Camión Patente"
                      >
                        <AutocompleteInput
                          source={getSource("truck_id")}
                          optionValue="id"
                          optionText="plate"
                          resettable
                        />
                      </ReferenceInput>
                    </Box>
                     <TruckQuickCreate source={getSource('truck_id')} owner_id={scopedFormData?.truck_owners_id }  truck_id={scopedFormData?.truck_id} />
                     </Box>
                  )}
                  <DriverSelect
                    record={scopedFormData}
                    getSource={getSource}
                  />
                  {scopedFormData?.truck_id != undefined && (
                    <Box display="flex">
                      <NumberInput
                        source={getSource("transporting_distance")}
                        label="Km Realizados"
                        validate={[required()]}
                        style={{ marginRight: "1em" }}
                      />
                      <NumberInput
                        source={getSource("transporting_per_ton")}
                        label="Tarifa por Tn"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              $
                            </InputAdornment>
                          ),
                        }}
                        style={{ marginRight: "1em" }}
                        validate={[required()]}
                      />
                      <NumberInput
                        source={getSource("transporting_extra")}
                        label="Estadia"
                        style={{ marginRight: "1em" }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              $
                            </InputAdornment>
                          ),
                        }}
                      />
                      <SelectInput
                        source={getSource("transporting_payment")}
                        label="Carga transportada"
                        choices={[
                          {
                            id: "buy",
                            name: `Carga - ${values?.buy_quantity || 0}Tn`,
                          },
                          {
                            id: "sell",
                            name: `Descarga ${values?.sell_quantity || 0}Tn`,
                          },
                        ]}
                        validate={[required()]}
                      />
                      <TransportFee
                        getSource={getSource}
                        record={scopedFormData}
                      />
                    </Box>
                  )}
                  <TransportDetail record={scopedFormData} />
                </Box>
                );
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </Box>
    </Box>
  )
}
