import * as React from "react";
import { Edit, SimpleForm, TextInput, required } from 'react-admin';
import { RoleTitle } from './title';
import Abilities from './abilities';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    heading: {
        fontSize: theme.typography.pxToRem(18),
        fontWeight: theme.typography.fontWeightBold,
    },
}));

const PanelTitle = ({ record }) => {
    const classes = useStyles();
    if (record) {
        return (
            <Typography className={classes.heading}>
                Editar Rol ({record.id})
            </Typography>
        );
    }
}

export const RoleEdit = props => (
    <Edit title={<RoleTitle />} {...props}>
        <SimpleForm>
            <PanelTitle {...props} />
            <TextInput source="name" validate={[required()]} />
            <TextInput source="title" validate={[required()]} />
            <Abilities source="abilities" {...props} />
        </SimpleForm>
    </Edit>
);