import { Fragment } from "react";
import { useDispatch } from "react-redux";
import { useCallback, useState, useEffect } from "react";
import { Button, Dialog } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import AddIcon from '@material-ui/icons/Add';
import PeopleDialog from "./PeopleDialog";
import { useForm, useFormState } from "react-final-form";
import { crudGetMatching } from "react-admin"; // eslint-disable-line import/no-unresolved

export const TruckOwnerQuickCreate = ({source = 'truckowner_id' }) => {
    
    const dispatch = useDispatch();
    const { values } = useFormState();
    const { change } = useForm();
    const [showCreateDialog, setShowCreateDialog] = useState(false);
    const [newPeopleId, setNewPeopleId] = useState("");

    const handleNewClick = useCallback(
        (event) => {
            event.stopPropagation();
            event.preventDefault();
            setShowCreateDialog(true);
        },
        [setShowCreateDialog]
    );

    const handleCloseCreate = useCallback(() => {
        setShowCreateDialog(false);
    }, [setShowCreateDialog]);

    const handleSave = useCallback(
        truckowner => {
            setShowCreateDialog(false);
            setNewPeopleId(truckowner.id);
            change(source, truckowner.id);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [setShowCreateDialog, change],
    );

    useEffect(() => {
        dispatch(
            crudGetMatching(
                "truckowners",
                `truckowners@${source}`,
                { page: 1, perPage: 25 },
                { field: "id", order: "DESC" },
            ),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, newPeopleId]);
    return (
        <Fragment>
            <Button style={{ width: '160px' ,maxHeight: '40px', marginTop: '8px', border: '0', marginLeft: '1em' }} size="small" onClick={handleNewClick} startIcon={< AddIcon />}>
                NUEVO DUEÑO
            </Button>
            <Dialog
                fullWidth
                open={showCreateDialog}
                onClose={handleCloseCreate}
                onClick={(e) => e.stopPropagation()}
                aria-label="Nuevo Dueño"
            >
                <DialogTitle>Nuevo Dueño</DialogTitle>
                <DialogContent style={{ padding: 0 }}>
                    <PeopleDialog
                        onCancel={handleCloseCreate}
                        onSave={handleSave}
                        basePath="/truckowners"
                        resource="truckowners"
                        record={values}
                    />
                </DialogContent>
            </Dialog>
        </Fragment>
    )
}
