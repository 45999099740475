import { cloneElement, Fragment, useState, useEffect } from "react";
import {
  List,
  Datagrid,
  DateField,
  TextField,
  NumberField,
  TopToolbar,
  useGetOne,
  useRecordContext,
  ReferenceField,
  SelectField,
  Labeled,
} from "react-admin";
import { CashRegisterFilter } from "./CashRegisterFilter";
import { Header } from "./CashRegisterHeader";
import {
  Box,
  makeStyles,
  LinearProgress,
  Button,
  Card,
  Modal,
  Typography,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import { Title } from "../../../components/Title";
import { CashRegisterQuickCreate } from "../quickcreate/CashRegisterQuickCreate";
import { havePermission } from "../../../utils/permissions";
import CloseIcon from "@material-ui/icons/Close";
import LaunchIcon from "@material-ui/icons/Launch";
import { formatter } from "../../../utils/NumberFormatter";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  border: "none",
};

const useStyles = makeStyles({
  box: {
    minHeight: "40px",
  },
  button: {
    minHeight: "40px",
    marginLeft: "1em",
  },
  ul: {
    listStyle: "none",
    padding: "1em",
    "& > li": {
      textTransform: "capitalize",
      fontWeight: 500,
      fontSize: "1em",
      padding: "4px 0",
    },
  },
  card: {
    position: "absolute",
    minWidth: "220px",
    marginLeft: "50px",
    padding: "1em",
    boxSizing: "border-box",
    height: "100%",
    minHeight: "350px",
    zIndex: "2",
  },
  hidden: {
    display: "none",
  },
  show: {
    position: "absolute",
    transform: "rotate(270deg)",
    left: "-8px",
    top: "26px",
    background: "white",
    color: "#5C7B34",
    borderColor: "#5C7B34",
    borderWidth: "1px",
    borderStyle: "solid",
    borderBottom: "0",
    zIndex: "0",
  },
  min: {
    minWidth: "40px",
  },
  content: {
    minWidth: "254px",
  },
  debt: {
    marginRight: ".5em",
    marginLeft: ".5em",
  },
});

const PMETHOD = [
  { id: "credito", name: "Crédito" },
  { id: "efectivo", name: "Efectivo" },
  { id: "cheque", name: "Cheque" },
  { id: "transferencia", name: "Transferencia" },
];

const ListActions = ({ money_box_id, data, ...props }) => {
  const classes = useStyles();
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };
  return (
    <TopToolbar>
      <Box display="flex" className={classes.box}>
        <Box display="flex" alignItems="center">
          {cloneElement(props.filters, { context: "button" })}
          <Button
            variant="outlined"
            onClick={() => setShow(true)}
            className={classes.debt}
          >
            Deudas
          </Button>
          {havePermission("create-cash_registers") && (
            <CashRegisterQuickCreate money_box_id={money_box_id} {...props} />
          )}
          <Aside
            show={show}
            data={data}
            handleClose={handleClose}
            money_box_id={money_box_id}
          />
        </Box>
      </Box>
    </TopToolbar>
  );
};

const CustomEntity = ({ source, data = null }) => {
  const record = useRecordContext();
  const entity = data ? data : record;

  const reference =
    entity[`${source}_type`] === "customer"
      ? "customers"
      : entity[`${source}_type`] === "producer"
      ? "producers"
      : "money_box";
  if (!reference) return <LinearProgress />;
  if (entity[`${source}_id`] == 6 && entity[`${source}_type`] == "moneybox")
    return <p>Ajuste</p>;
  return (
    <>
      {entity[`${source}_id`] != null ? (
        <ReferenceField
          label={entity[`${source}_type`]}
          source={"id"}
          record={{ id: entity[`${source}_id`] }}
          reference={reference}
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
      ) : (
        <span>{entity?.entity}</span>
      )}
    </>
  );
};

const CashRegisterField = ({ source, label }) => {
  const { coin_type, movement, ...record } = useRecordContext();
  const coinType = coin_type == "usd" ? "USD" : "ARS";
  if (record[`${source}`] == 0 && source != "partial") return "-";
  return (
    <NumberField
      source={source}
      label={label}
      locales="es-AR"
      options={{ style: "currency", currency: `${coinType}` }}
      sortable={false}
    />
  );
};

const Aside = ({ show, handleClose, data }) => {
  const classes = useStyles();
  if (!data || !show) return null;

  return (
    <Modal open={show} onClose={() => handleClose()}>
      <Box sx={style}>
        <Card>
          <ul className={classes.ul}>
            {data &&
              Object.keys(data?.debts)?.map((key, index) => {
                return (
                  <li key={index}>
                    {data.money_box.find((v) => v.id == key)?.name}: ${" "}
                    {data?.debts[key]}{" "}
                  </li>
                );
              })}
          </ul>
        </Card>
      </Box>
    </Modal>
  );
};
const PAYMENTS_METHODS = [
  { id: "efectivo", name: "Efectivo" },
  { id: "credito", name: "Crédito" },
  { id: "cheque", name: "Cheque" },
  { id: "transferencia", name: "Transferencia" },
];
const PopUpPaymentShow = ({ data, show, handleClose }) => {
  const onclick = (e) => {
    e.stopPropagation();
    handleClose();
  };

  if (!show || !data) return null;
  return (
    <Dialog
      PaperProps={{ elevations: "0" }}
      open={show}
      onClose={(e) => onclick(e)}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle style={{ paddingBottom: 0 }}>
        <Box display="flex" justifyContent="space-between">
          <Typography
            style={{
              fontWeight: 700,
              fontSize: "22px",
              lineHeight: "30px",
              textTransform: "capitalize",
            }}
          >
            Movimiento # {String(data.id).padStart(5, "0")}
          </Typography>
          <IconButton onClick={(e) => onclick(e)}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent style={{paddingBottom:'1em'}}>
        <Typography>Fecha: {data.date}</Typography>
        <Box display="flex">
          <Box mr="1em">
            <Labeled label="Origen">
              <CustomEntity data={data} source="origin" />
            </Labeled>
          </Box>
          <Labeled label="Destino">
            <CustomEntity data={data} source="destination" />
          </Labeled>
        </Box>

        <Typography>
          Monto: {data.coin_type ? "$" : "US$"}
          {formatter.format(data.amount)}
        </Typography>
        {data.coin_type != "arg" && (
          <Typography>Valor cambio: {formatter.format(data.exchange_value)}</Typography>
        )}
        {data.concept && <Typography>Concepto: {data.concept}</Typography>}
        {data.bank && <Typography>Banco: {data.bank}</Typography>}
        <Typography>
          Metodo de pago:{" "}
          {PAYMENTS_METHODS.find((i) => i.id == data.payment_method)?.name}
        </Typography>
        {data.receipt_number && (
          <Typography>Numero Recibo: {data.receipt_number}</Typography>
        )}
        {data.verification_number && (
          <Typography>
            Numero Comprobante: {data.verification_number}
          </Typography>
        )}
        {data.details && <Typography>Detalles: {data.details}</Typography>}
      </DialogContent>
    </Dialog>
  );
};

const CustomShowButton = ({ showPopUp }) => {
  const record = useRecordContext();
  return (
    <Tooltip title="Ver Pago">
      <IconButton onClick={() => showPopUp(record)}>
        <LaunchIcon />
      </IconButton>
    </Tooltip>
  );
};

export const CashRegisterList = (props) => {
  const { data: money_box } = useGetOne("money_box", props?.money_box_id);
  const [money, setMoney] = useState(money_box);
  const [debts, setDebts] = useState();

  const [show, setShow] = useState();
  const [data, setData] = useState();

  const showPopUp = (record) => {
    setData(record);
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };
  useEffect(() => {
    if (money_box?.actual_value != 0) {
      setMoney(money_box);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [money_box]);

  const getDebts = async () => {
    try {
      const token = localStorage.getItem("token");
      fetch(
        process.env.REACT_APP_API_HOST_ADMIN +
          `/debts/${props.money_box_id}/getresumedebt`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          if (!res.ok) {
            return null;
          }
          return res.json();
        })
        .then((json) => {
          if (json) {
            setDebts(json);
          } else {
            setDebts(null);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    if (props?.money_box_id) {
      getDebts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.money_box_id]);

  return (
    <Fragment>
      <Title label={props.title} variant="h4" />
      <Header money={money} />
      <List
        {...props}
        empty={false}
        title={props.title}
        perPage={10}
        sort={{ field: "created_at", order: "DESC" }}
        filters={<CashRegisterFilter />}
        filter={{ money_box_id: props?.money_box_id }}
        bulkActionButtons={false}
        resource={"cash_registers"}
        actions={
          <ListActions
            labelButton="Nuevo Movimiento"
            data={debts}
            money_box_id={props?.money_box_id}
          />
        }
      >
        <Datagrid title="Movimientos">
          <DateField
            source="created_at"
            label="Fecha"
            locales="es-AR"
            options={{ timeZone: "UTC" }}
          />
          <CustomEntity
            source="origin"
            label="Origen"
            {...props}
            sortable={false}
          />
          <CustomEntity
            source="destination"
            label="Destino"
            {...props}
            sortable={false}
          />
          <SelectField
            source="payment_method"
            choices={PMETHOD}
            label="Tipo de pago"
          />
          <CashRegisterField source="debt" label="Debe" sortable={false} />
          <CashRegisterField source="having" label="Haber" sortable={false} />
          <CashRegisterField source="partial" label="Saldo" sortable={false} />
          <CustomShowButton showPopUp={showPopUp} />
        </Datagrid>
      </List>
      <PopUpPaymentShow show={show} data={data} handleClose={handleClose} />
    </Fragment>
  );
};
