import {
  SimpleForm,
  Create,
  TextInput,
  AutocompleteInput,
  ReferenceInput,
  ArrayInput,
  SimpleFormIterator,
  required,
  FormDataConsumer,
} from "react-admin";
import {
  InputAdornment,
  Box
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { ActionsBack } from "../../../components/ActionsBack";
import { TruckOwnerQuickCreate } from "../../truckowners/quickcreate/PeopleQuickCreate";
import { CustomAddButton } from '../../../components/CustomAddButton';
import { CustomDeleteButton } from '../../../components/CustomDeleteButton';
import { Fragment } from "react";
import { validateRequired } from "../../../utils/Validations";

export const SimpleFieldTruck = props => {
  return (
    <Fragment>
      <Box>
        <TextInput source="plate" label="Patente" validate={validateRequired} />
      </Box>
      <Box>
        <TextInput source="brand" label="Marca" validate={validateRequired}/>
      </Box>
      <Box>
        <TextInput source="model" label="Modelo" validate={validateRequired}/>
      </Box>
      <ArrayInput source="drivers" label="Choferes" style={{ maxWidth: '46vw' }}  >
        <SimpleFormIterator disableReordering={true} getItemLabel={(index) => ''} addButton={<CustomAddButton label={"AGREGAR CHOFER"} {...props} />} removeButton={<CustomDeleteButton  {...props} />}>
          <FormDataConsumer>
            {({ getSource }) => {
              return (
                <Box display='flex' flexDirection='column' ml={'1em'}>
                  <Box display="flex">
                    <Box mr={3}>
                      <TextInput source={getSource("name")} label="Nombre" variant="filled" validate={[required()]} />
                    </Box>
                    <Box mr={3}>
                      <TextInput source={getSource("phone")} label="Telefono" variant="filled" validate={[required()]} />
                    </Box>
                  </Box>
                </Box>
              );
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </Fragment>
  )
}

export const TruckCreate = props => {

  return (
    <Create
      {...props}
      actions={<ActionsBack />}
      resource="trucks"
    >
      <SimpleForm
        redirect='list'
        variant='standard'
        margin='dense'
      >
        <Box display="flex" fullWidth maxWidth={"400px"}>
          <ReferenceInput source="truckowner_id" reference="truckowners" label="Dueño del camion"    filterToQuery={searchText => ({ search: searchText })}> 
            <AutocompleteInput optionText="name" optionValue="id" resettable options={{
              InputProps: {
                endAdornment: <InputAdornment position="start" style={{ marginTop: '5px' }}><SearchIcon style={{ fontSize: '1.8em' }} /></InputAdornment>
              }
            }} />
          </ReferenceInput>
          <TruckOwnerQuickCreate />
        </Box>
        {/*** truck owen select adn quick create */}
        <SimpleFieldTruck />
      </SimpleForm>
    </Create>
  );
};
