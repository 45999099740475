import { DateInput, Filter, SearchInput ,SelectInput, TextInput } from "react-admin";

const TYPE = [
  {id:'moneybox',name:'Caja' },
  {id:'customer',name:'Cliente' },
  {id:'producer',name:'Productor' },
  {id:'truckowner',name:'Dueño de camión' },
];

const COIN = [
  {id:'arg',name:'Pesos' },
  {id:'usd',name:'Dólares' },
];

const PMETHOD = [
  {id:'cr',name:'Transferencia' },
  {id:'ef',name:'Efectivo' },
  {id:'ch',name:'Cheque'}
];

export const CashRegisterFilter = props => (
  <Filter {...props} variant='outlined' >
    <SearchInput source='search' alwaysOn  />
    <DateInput source="created_at" label="Fecha" locales="es-AR"  alwaysOn/>
    <SelectInput source='origin_type' choices={TYPE} label="Tipo Origen"  />
    <TextInput source="origin_name" label="Origen"/>
    <TextInput source="destination_name" label="Destino" />
    <SelectInput source='destination_type' choices={TYPE} label="Tipo Destino" />
    <SelectInput source='coin_type' choices={COIN} label="Moneda" />
    <SelectInput source="payment_method" choices={PMETHOD} label="Tipo de pago"/>
  </Filter>
);
