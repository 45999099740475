import { Fragment } from "react";
import {
  SimpleForm,
  Edit,
  ShowButton,
  useRecordContext,
  Datagrid,
  ArrayField,
  TextField,
  EditButton,
} from "react-admin";
import { Box } from '@material-ui/core';
import { Title } from "../../../components/Title";
import { ActionsBack } from "../../../components/ActionsBack";
import { PeopleSimpleFields } from "../quickcreate/PeopleDialog";


const Action = () => {
  const record = useRecordContext();
  return (
    <Fragment>
      <Title label="Editar Dueño de camion" />
      <Box alignSelf="flex-start">
        <ShowButton variant="outlined" basePath="/truckowners" record={record} size="small" />
      </Box>
    </Fragment>
  )
}

const CustomAction = () =>{
  const record= useRecordContext();
  return (
    <Fragment>
        <EditButton basePath="/trucks"  resource="trucks"  record={record}/>
    </Fragment>
  )
}

const TrucksDetail = () =>{
  const record = useRecordContext();
  if (!record?.trucks) return null;
  return(
    <ArrayField source="trucks" label="Camiones" record={record}>
        <Datagrid >
          <TextField source="plate" label="Patente"/>
          <TextField source="brand" label="Marca" />
          <TextField source="model" label="Modelo" />
          <CustomAction />
        </Datagrid>
    </ArrayField>
  )
}


export const PeopleEdit = props => {

  return (
    <Edit
      {...props}
      actions={<ActionsBack />}
    >
      <SimpleForm
        variant='standard'
        margin='dense'
      >
        <Box display="flex" justifyContent="space-between" margin='1em 0' fullWidth >
          <Action />
        </Box>
        <PeopleSimpleFields />
        <TrucksDetail {...props}/>
      </SimpleForm>
    </Edit>
  );
};
