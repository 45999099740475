import { useState, useEffect } from "react";
import { Box, Typography, InputAdornment, makeStyles, Button } from "@material-ui/core";
import {
  required,
  DateInput,
  NumberInput,
  SelectInput,
  FormDataConsumer,
  CheckboxGroupInput,
  ArrayInput,
  SimpleFormIterator,
  DateField,
  NumberField,
} from "react-admin";
import { useField, useFormState } from "react-final-form";
import { Humidity } from "../../../components/Merma/Humidity";
import { CustomAddButton } from '../../../components/CustomAddButton';
import { CustomDeleteButton } from '../../../components/CustomDeleteButton';
import { InvoiceQuickCreate } from "../../invoices/quickcreate/InvoiceQuickCreate";
import { InvoiceDetail } from "../../invoices/InvoiceDetail";
import { CargoDetail } from "./CargoDetail";

const useStyles = makeStyles({
  array: {
    alignItems: 'center',
    '& ul li:first-child': {
      width: '0!important',
    },
    '& div.ra-input': {
      marginLeft: '0!important'
    }
  },
  spaceR: {
    marginRight: '1em',
  },
  spaceL: {
    marginLeft: '1em',
  },
  mgtop: {
    marginTop: 0,
  },
  text: {
    fontWeight: '6  00'
  },
  button: {
    maxWidth: '200px',
    marginTop: '1em'
  }
})


/* // { id: "afrechillo", name: "Afrechillo" }, */

const CEREAL_TYPE = [
  { id: "maiz", name: "Maiz" },
  { id: "trigo", name: "Trigo" },
  { id: "girasol", name: "Girasol" },
];


const EXPENSES_TYPE = [
  { id: 'secado', name: 'Secado' },
  { id: 'paritaria', name: 'Paritaria' },
  { id: 'humedad', name: 'Humedad' },
  { id: 'combustible', name: 'Combustible' },
  { id: 'merma_zarandeo', name: 'Merma por zarandeo' },
  { id: 'merma', name: 'Merma dañada' }
];



const CustomQuantity = ({ formData, source, label }) => {
  const { input: quantity } = useField('sell_quantity');
  useEffect(() => {
    if (formData?.buy_neto && source === "sell") {
      quantity.onChange(formData?.buy_neto ? formData.buy_neto : 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData?.buy_neto])
  return (
    <NumberInput source={`${source}_quantity`} label={label} style={{ marginRight: '1em' }} validate={[required()]} />
  )
}


export const SowingCargo = ({ formData, source, props }) => {
  const classes = useStyles();
  const [options, setOptions] = useState([]);
  const { values } = useFormState();
  const [invoices, setInvoices] = useState(values[`${source}_invoices`] || []);
  const { input: invoices_items } = useField(`${source}_invoices`);
  const [show, setShow] = useState(null);

  useEffect(() => {
    if (invoices?.length > 0) {
      invoices_items.onChange(invoices);
    } else {
      invoices_items.onChange([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoices]);

  const showDetail = (item) => {
    setShow(item);
  }

  return (
    <Box mt="1.5em" mb="1.5em">
      <Typography component="span" variant="h6" >Carga</Typography>
      <Box>
        <Box display="flex">
          <SelectInput source="type" choices={CEREAL_TYPE} label="Tipo de especie" className={classes.spaceR} disabled={source === 'sell' && formData?.type != undefined ? true : false} />
          <DateInput source={`${source}_date`} validate={[required()]} label={`Fecha de ${source === 'buy' ? 'compra' : 'venta'}`} />
        </Box>
        <Box display="flex">
          <CustomQuantity source={source} label="Toneladas" formData={formData} />
          <Humidity source={source} label="Humedad" formData={formData} setOptions={setOptions} />
          <NumberInput source={`${source}_depletion`} label="Merma" className={classes.spaceL} InputProps={{
            endAdornment: (<InputAdornment position='end'>%</InputAdornment>)
          }}
            validate={[required()]}
          />
          <CheckboxGroupInput source={`${source}_extra_percentages`} label="Manipuleo" choices={options} className={`${classes.spaceL} ${classes.mgtop}`} />
        </Box>
        <Box>
          <NumberInput source={`${source}_price_per_unit`} label="Precio por TN" InputProps={{
            startAdornment: (<InputAdornment position='start'>$</InputAdornment>)
          }}
            validate={[required()]}
          />
          <DateInput source={`${source}_due_date`} label="Vencimiento de pago" className={classes.spaceL} />
        </Box>
        <Typography component="span" variant="h6" >Gastos</Typography>
        <Box>
          <ArrayInput source={`${source}_expenses`} label="" style={{ maxWidth: '46vw' }}  >
            <SimpleFormIterator disableReordering={true} getItemLabel={(index) => ''} addButton={<CustomAddButton label={"AGREGAR GASTO"} {...props} />} removeButton={<CustomDeleteButton  {...props} />}>
              <FormDataConsumer>
                {({ getSource }) => {
                  return (
                    <Box display='flex' flexDirection='column' ml={'1em'}>
                      <Box display="flex">
                        <Box mr={3}>
                          <SelectInput source={getSource("description")} label="Gasto" variant="filled" validate={[required()]} choices={EXPENSES_TYPE} />
                        </Box>
                        <Box mr={3}>
                          <NumberInput source={getSource("amount")} label="Importe" variant="filled" validate={[required()]} InputProps={{
                            startAdornment: (<InputAdornment position='start'>$</InputAdornment>)
                          }} />
                        </Box>
                      </Box>
                    </Box>
                  );
                }}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </Box>
        <Box >
          <CargoDetail source={source} formData={formData} />
        </Box>
        <Box display="flex" flexDirection="column" mt="1.5em" >
          <Typography component="span" variant="h6" >Facturación</Typography>

          {invoices?.length > 0 && invoices.map((item, index) => (
            <Box display="flex" justifyContent="space-between" key={index}>
              <Box display="flex">
                <Box mr="1em">
                  <DateField source="date" record={item} label="" locales="es-AR" options={{ timeZone: 'UTC' }} />
                </Box>
                <NumberField source="amount" record={item} label="" locales="es-AR" options={{ style: 'currency', currency: 'ARS' }} />
              </Box>
              <Button onClick={() => showDetail(item)}>VER DETALLE</Button>
            </Box>
          ))}
          <InvoiceQuickCreate setInvoices={setInvoices} />
          <InvoiceDetail show={show} setShow={setShow} />
        </Box>
      </Box>
    </Box>
  )
}
