import { useState, useEffect, } from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import { useField } from "react-final-form";
import { BorderBox } from "../../../components/BorderBox";
import { CustomLabeled } from "../../../components/CustomLabeled";
import { formatter } from "../../../utils/NumberFormatter";

const useStyles = makeStyles({
  array: {
    alignItems: 'center',
    '& ul li:first-child': {
      width: '0!important',
    },
    '& div.ra-input': {
      marginLeft: '0!important'
    }
  },
  spaceR: {
    marginRight: '1em',
  },
  spaceL: {
    marginLeft: '1em',
  },
  mgtop: {
    marginTop: 0,
  },
  text: {
    fontWeight: '6  00'
  },
  button: {
    maxWidth: '200px',
    marginTop: '1em'
  },
  mediaquery: {
    width: '40%',
    ['@media (min-width:768px)']: { // eslint-disable-line no-useless-computed-key
      width: '100%',
    }
  }
})




export const CargoDetail = ({ source, formData }) => {
  const classes = useStyles();
  const [total, setTotal] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [merma, setMerma] = useState(0);
  const [neto, setNeto] = useState(0);
  const { input: price_total } = useField(`${source}_price_total`);
  const { input: neto_total } = useField(`${source}_neto`);
  const { input: total_expense } = useField(`${source}_total_expenses`);

  const getMerma = () => {
    if (quantity !== 0 && formData[`${source}_depletion`]) {
      if (formData[`${source}_extra_percentages`]?.length > 0) {
        const extra = formData[`${source}_extra_percentages`]?.reduce((acc, item) => acc + item, 0);
        return (quantity / 100 * (formData[`${source}_depletion`] + extra));
      }
      return (quantity / 100 * formData[`${source}_depletion`]);
    }
    return 0;
  }

  const getNeto = () => {
    if (quantity !== 0) {
      return (((quantity - merma) / 1000));
    }
    return 0;
  }
  const getTotal = () => {
    let expenses = 0;
    let result = 0;

    if (formData[`${source}_expenses`]) {
      expenses = formData[`${source}_expenses`]?.reduce((acc, item) => { if (item?.amount) { return acc + item?.amount } else { return acc + 0 } }, 0);
    }

    if (formData[`${source}_price_per_unit`]) {
      result = (Number(neto) * formData[`${source}_price_per_unit`]) - expenses;
    }
    let total = expenses;

    price_total.onChange(result);
    total_expense.onChange(total);
    return result;
  }

  useEffect(() => {
    if (formData[`${source}_quantity`]) {
      setQuantity(formData[`${source}_quantity`] * 1000);
    } else {
      setQuantity(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData[`${source}_quantity`]])

  useEffect(() => {
    setMerma(getMerma());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantity, formData[`${source}_depletion`], formData[`${source}_extra_percentages`]])

  useEffect(() => {
    const result = getNeto();
    setNeto(result);
    neto_total.onChange(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantity, merma])

  useEffect(() => {
    setTotal(getTotal());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData[`${source}_price_per_unit`], formData[`${source}_expenses`], neto, quantity, merma])

  return (
    <Box mt='1em'>
      <BorderBox >
        <Typography component="span" variant="h6" style={{ fontSize: '1em' }} >Detalle de carga</Typography>
        <Box display="flex" justifyContent="space-between" className={classes.mediaquery}>
          <Box>
            <CustomLabeled label="KG CARGADOS">
              <span className={classes.text}>{quantity}Kg</span>
            </CustomLabeled>
          </Box>
          <Box>
            <CustomLabeled label="MERMA KG">
              <span className={classes.text}>{formatter.format(merma)}Kg</span>
            </CustomLabeled>
          </Box>
          <Box>
            <CustomLabeled label="NETO TONELADAS">
              <span className={classes.text}>{formatter.format(neto)}TN</span>
            </CustomLabeled>
          </Box>
          <Box>
            <CustomLabeled label="TOTAL">
              <span className={classes.text}>${formatter.format(total)}</span>
            </CustomLabeled>
          </Box>
        </Box>
      </BorderBox>
    </Box>
  )
}
