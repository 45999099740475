import { Fragment } from "react";
import { useCallback, useState } from "react";
import { Button, Dialog, makeStyles } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import AddIcon from '@material-ui/icons/Add';
import CashDialog from "./Dialog";
import { useRefresh } from "react-admin";
const useStyles = makeStyles({
  button: {
    padding: '4px 10px',
    fontSize: '0.8125rem',
    maxHeight: '50px',
    border: '0',
    marginLeft: '1em',
    background: '#5C7B34',
    color: 'white',
    width: '180px',
    marginTop: 0,
    minHeight: '40px',
    '&:hover': {
      background: '#5C7B34',
      color: 'white'
    }
  },
  title: {
    padding: '8px 12px'
  }
})

export const CashRegisterQuickCreate = ({ money_box_id, data,...props }) => {
  const classes = useStyles();
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const refresh = useRefresh();
  const handleNewClick = useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();
      setShowCreateDialog(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setShowCreateDialog]
  );

  const handleCloseCreate = useCallback(() => {
    setShowCreateDialog(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setShowCreateDialog]);


  const handleSave = useCallback(
    (event) => {
      setShowCreateDialog(false);
      refresh(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setShowCreateDialog]
  );
  return (
    <Fragment>
      <Button variant="contained" onClick={handleNewClick} startIcon={< AddIcon />} className={classes.button}>
        NUEVO MOVIMIENTO
      </Button>
      <Dialog
        fullWidth
        open={showCreateDialog}
        onClose={handleCloseCreate}
        onClick={(e) => e.stopPropagation()}
        aria-label="Nuevo Movimiento"
      >
        <DialogTitle className={classes.title}>Nuevo Movimiento</DialogTitle>
        <DialogContent style={{ padding: 0 }}>
          <CashDialog
            onCancel={handleCloseCreate}
            onSave={handleSave}
            basePath={props?.basePath}
            moneybox_debts={data}
            resource="cash_registers"
            money_box_id={money_box_id}
          />
        </DialogContent>
      </Dialog>
    </Fragment>
  )
}
