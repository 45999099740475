import { Fragment } from "react";
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import {List ,MenuItem,ListItemIcon,Typography,Collapse,Tooltip} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslate } from "react-admin";

const useStyles = makeStyles(theme => ({
  icon: { minWidth: theme.spacing(5) },
  sidebarIsOpen: {
    "& a": {
      paddingLeft: theme.spacing(4),
      transition: "padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
    },
  },
  sidebarIsClosed: {
    "& a": {
      paddingLeft: theme.spacing(2),
      transition: "padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
    },
  },
  selected : {background: 'rgba(0, 0, 0, 0.04)', color: 'white'},
          header:{
            borderLeft: '3px solid #fff !important',
          }
}));

const SubMenu = ({
  handleToggle,
  sidebarIsOpen,
  isOpen,
  name,
  icon,
  children,
  dense,
}) => {
  const translate = useTranslate();
  const classes = useStyles();

  const header = (
    <MenuItem dense={dense} button onClick={handleToggle}  className={`${classes.header} ${isOpen !== undefined && isOpen ? classes.selected :''}`}>
      <ListItemIcon className={classes.icon}>
        {isOpen ? <ExpandMoreRoundedIcon /> : icon}
      </ListItemIcon>
      <Typography variant='inherit' color='textSecondary' style={{fontSize: '1rem'}}>
        {translate(name)}
      </Typography>
    </MenuItem>
  );

  return (
    <Fragment>
      {sidebarIsOpen || isOpen ? (
        header
      ) : (
        <Tooltip title={translate(name)} placement='right'>
          {header}
        </Tooltip>
      )}
      <Collapse in={isOpen} timeout='auto' unmountOnExit>
        <List
          dense={dense}
          component='div'
          disablePadding
          className={
            sidebarIsOpen ? classes.sidebarIsOpen : classes.sidebarIsClosed
          }
        >
          {children}
        </List>
      </Collapse>
    </Fragment>
  );
};

export default SubMenu;
