import {
  SimpleShowLayout,
  SelectField,
  TextField,
  DateField,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  makeStyles,
  Divider,
  IconButton
} from "@material-ui/core";
import { CustomLabeled } from '../../components/CustomLabeled';
import FormattedNumberField from '../../components/FormattedNumberField';

const INVOICES_TYPE = [
  { id: 'a', name: 'A' },
  { id: 'b', name: 'B' },
  { id: 'c', name: 'C' },
  { id: 'e', name: 'E' },
  { id: 'm', name: 'M' }
];

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const CustomDialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});
const useInvoiceDetailStyles = makeStyles({
  amount: {
    fontSize: '1.5em',
    fontWeight: 500
  },
  iva: {
    fontSize: '0.9em',
    fontWeight: 500
  },
  date: {
    color: '#818181',
    fontSize: '1em'
  },
  container: {
    padding: '0 !important',
    marginBottom: '1em'
  },
  divider: {
    marginTop: '0.5em',
    marginBottom: '0.5em'
  }
})

export const InvoiceDetail = ({ show, setShow }) => {
  const classes = useInvoiceDetailStyles();
  if (!show) return null;

  const handleCloseCreate = () => {
    setShow(null);
  }

  return (
    <Dialog
      fullWidth
      open={true}
      onClose={handleCloseCreate}
      onClick={(e) => e.stopPropagation()}
      aria-label="Detalle de la factura"
    >
      <CustomDialogTitle onClose={handleCloseCreate}>Detalle de la factura</CustomDialogTitle>
      <DialogContent className={classes.container}>
        <SimpleShowLayout record={show}>
          <Box display="flex" flexDirection="column">
            <FormattedNumberField className={classes.amount} source={show?.amount}/>
            <DateField source="date" record={show} label="Fecha" className={classes.date} locales="es-AR" options={{ timeZone: 'UTC' }} />
          </Box>
          <Box display="flex" record={show} justifyContent="space-between" mt="1em" maxWidth='80%'>
            <CustomLabeled label="N° Comprobante">
              <TextField source="verification_number" record={show} />
            </CustomLabeled>
            <CustomLabeled label="Tipo de facturación">
              <SelectField source="type" record={show} choices={INVOICES_TYPE} />
            </CustomLabeled>
            <CustomLabeled label="IVA">
              <FormattedNumberField className={classes.iva} source={show?.iva}/>
            </CustomLabeled>
          </Box>
          <Divider className={classes.divider}/>
          <Box display="flex" record={show} justifyContent="space-between" mt="1em" maxWidth='80%'>
            <CustomLabeled label="Retención IVA">
              <FormattedNumberField className={classes.iva} source={show?.ret_iva} />
            </CustomLabeled>
            <CustomLabeled label="IVA CBU">
              <FormattedNumberField className={classes.iva} source={show?.iva_cbu} />
            </CustomLabeled>
            <CustomLabeled label="Retención Ganancia">
              <FormattedNumberField className={classes.iva} source={show?.ret_gan} />
            </CustomLabeled>
            <CustomLabeled label="RET. I.I.B.B">
              <FormattedNumberField className={classes.iva} source={show?.ret_iibb} />
            </CustomLabeled>
          </Box>
          <Box display="flex"  justifyContent="space-between" mt="1em" maxWidth='80%'>
            <CustomLabeled label="116 C">
              <FormattedNumberField className={classes.iva} source={show["116_c"]} />
            </CustomLabeled>
            <CustomLabeled label="COM.">
              <FormattedNumberField className={classes.iva} source={show?.com} />
            </CustomLabeled>
            <CustomLabeled label="C/FINAL">
              <FormattedNumberField className={classes.iva} source={show?.c_final} />
            </CustomLabeled>
            <CustomLabeled label="Bonificación">
              <FormattedNumberField className={classes.iva} source={show?.improvement} />
            </CustomLabeled>
          </Box>
          <Box display="flex"  justifyContent="space-between" mt="1em" maxWidth='80%'>
            <CustomLabeled label="G/BANC.">
              <FormattedNumberField className={classes.iva} source={show?.g_banc} />
            </CustomLabeled>
            <CustomLabeled label="P/CONTRATO">
              <FormattedNumberField className={classes.iva} source={show?.p_contract} />
            </CustomLabeled>
            <CustomLabeled label="Factor">
              <FormattedNumberField className={classes.iva} source={show?.factor} />
            </CustomLabeled>
          </Box>
        </SimpleShowLayout>
      </DialogContent>
    </Dialog>
  )
}

