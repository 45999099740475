import { useCallback } from "react";
import {
  SaveButton,
  SimpleForm,
  Toolbar,
  required,
  TextInput,
  NumberInput,
  SelectInput,
  DateInput,
  Create
} from "react-admin";
import { Box, makeStyles, InputAdornment, Typography } from "@material-ui/core";
import CancelButton from "./CancelButton";
import { useFormState } from "react-final-form";


const PostQuickCreateToolbar = ({ submitting, onCancel, ...props }) => (
  <Toolbar
    {...props}
    style={{
      width: "100%",
      boxSizing: "border-box",
      display: "flex",
      justifyContent: "space-between",
    }}
  >
    <SaveButton />
    <CancelButton onClick={onCancel} />
  </Toolbar>
);

const useStyles = makeStyles({
  form: { padding: 0 },
  marginRight: {
    marginRight: '1em'
  },
  input: {
    maxWidth: '250px',
  }
});

const INVOICES_TYPE = [
  { id: "a", name: "A" },
  { id: "b", name: "B" },
  { id: "c", name: "C" },
  { id: "e", name: "E" },
  { id: "m", name: "M" },
];

const InvoiceDialog = ({ onCancel, onSave, source, ...props }) => {
  const classes = useStyles();
  const { values } = useFormState();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSave = useCallback((v) => onSave(v), []);

  return (
    <Create {...props}>
    <SimpleForm
      save={handleSave}
      redirect={false}
      toolbar={<PostQuickCreateToolbar onCancel={onCancel} />}
      classes={{ form: classes.form }}
      {...props}
    >
      <Box
        display="flex"
        flexDirection="column"
        fullWidth
        style={{ maxWidth: "600px" }}
      >
        <Box display="flex" style={{ maxWidth: "400px" }}>
          <NumberInput
            source="amount"
            label="Total facturado"
            variant="filled"
            style={{ marginRight: "1em" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            initialValue={(values[`${source}_price_total`] || 0).toFixed(2)}
            defaultValue="0"
          />
          <DateInput
            source="date"
            label="Fecha"
            variant="filled"
            initialValue={new Date().toISOString().substr(0, 10)}
            validate={[required()]}
            locales="es-AR"
            options={{ timeZone: "UTC" }}
          />
        </Box>
        <TextInput
          source="verification_number"
          label="N° Comprobante"
          style={{ maxWidth: "400px" }}
          validate={[required()]}
        />
        <SelectInput
          source="type"
          style={{ maxWidth: "400px" }}
          label="Tipo de facturación"
          choices={INVOICES_TYPE}
          defaultValue={"a"}
          optionValue="id"
          optionText="name"
        />
        <Typography variant="h6" component="span">Detalle de facturación</Typography>
        <Box display="flex">
          <NumberInput
            source="iva"
            label="IVA"
            className={classes.marginRight}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            initialValue={((values[`${source}_price_total`] * 0.21)  || 0).toFixed(2)}
          />
          <NumberInput
            source="ret_iva"
            label="Retención IVA"
            className={classes.marginRight}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
          <NumberInput
            source="iva_cbu"
            label="IVA CBU"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        </Box>
        <Box display="flex" style={{ maxWidth: '400px' }}>
          <NumberInput
            source="ret_gan"
            label="Retención Ganancia"
            className={classes.marginRight}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
          <NumberInput
            source="ret_iibb"
            label="RET. I.I.B.B"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        </Box>
        <Box display="flex">

          <NumberInput
            source="116_c"
            label="116 C"
            className={classes.marginRight}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
          <NumberInput
            source="com"
            label="COM."
            className={classes.marginRight}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
          <NumberInput
            source="c_final"
            label="C/FINAL"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        </Box>
        <Box display="flex" style={{ maxWidth: '400px' }}>
          <NumberInput
            source="improvement"
            label="Bonificación"
            className={classes.marginRight}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
          <NumberInput
            source="g_banc"
            label="G/BANC."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        </Box>
        <Box display="flex" style={{ maxWidth: '400px' }}>
          <NumberInput
            source="p_contract"
            label="P/CONTRATO"
            className={classes.marginRight}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
          <NumberInput
            source="factor"
            label="Factor"
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
        </Box>
      </Box>
    </SimpleForm>
</Create>
  );
};

export default InvoiceDialog;
