import { useEffect } from "react";
import { useField } from "react-final-form";

export const OptionAdjust = ({ money_box_id, source }) => {
  const {input: field}=useField(`${source}_id`);
  const {input: type} = useField(`${source}_type`);

  useEffect(()=>{
    field.onChange(money_box_id);
    type.onChange('moneybox')

  },[money_box_id])

  return null;
  
};

