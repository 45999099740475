import { Fragment } from "react";
import { CerealCargo } from "./CerealCargo";
import { AnimalCargo } from "./AnimalCargo";
import { SowingCargo } from "./SowingCargo";
import { useFormState } from "react-final-form";

export const Cargo = ({ source, deal_type, props }) => {
  const { values } = useFormState();
  if (!deal_type) return null;
  return (
    <Fragment>
      {deal_type == "cereal" ?
        <CerealCargo source={source} formData={values} {...props} />
        :
        deal_type == "ganaderia" ?
          <AnimalCargo source={source} formData={values} {...props} />
          :
          <SowingCargo source={source} formData={values} {...props} />
      }
    </Fragment>
  )
}
