import { DateInput, Filter,ReferenceInput, AutocompleteInput, SearchInput ,SelectInput, BooleanInput } from "react-admin";

const PMETHOD = [
  {id:'cr',name:'Transferencia' },
  {id:'ef',name:'Efectivo' },
  {id:'ch',name:'Cheque'}
];

export const MoneyDebtFilter = props => (
  <Filter {...props} variant='outlined' >
    <SearchInput source='search' alwaysOn  />
    <ReferenceInput
      source="loaner_box_id"
      reference="money_box"
      label="Origen"
      filterToQuery={searchText => ({ search: searchText })}
      allowEmpty
      alwaysOn
    >
      <AutocompleteInput optionText="name" optionValue="id" resettable/>
    </ReferenceInput> 
    <ReferenceInput
      source="debtor_box_id"
      reference="money_box"
      filterToQuery={searchText => ({ search: searchText })}
      label="Destino"
      allowEmpty
      alwaysOn
    >
      <AutocompleteInput optionText="name" optionValue="id" resettable/>
    </ReferenceInput> 
    <SelectInput source="payment_method" choices={PMETHOD} label="Tipo de pago" />
    <DateInput source="created_at" label="Fecha" locales="es-AR"  alwaysOn/>
    <DateInput source="date_gte" label="Fecha Posterior" locales="es-AR"  />
    <BooleanInput source="montly" label="Ultimo mes" locales="es-AR" defaultValue={true}/>
  </Filter>
);
