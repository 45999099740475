import { useField, useFormState } from "react-final-form";
import { useEffect, useState } from "react";
import { AutocompleteInput  } from "react-admin";
import { getMerma, getMermaManipuleo } from "./mermas-mock";

export const Humidity = ({ formData, source, setOptions, props }) => {
    const { input: depletion } = useField(`${source}_depletion`);
    const { input: extra_per } = useField(`${source}_extra_percentages`);
    const [choices, setChoices] = useState([]);
    const { values } = useFormState();

    useEffect(() => {
        if (values?.type) {
            setChoices(getMerma(values.type));
            extra_per.onChange([]);
        }
    }, [values?.type]);

    useEffect(() => {
        if (values[`${source}_humidity`] !== undefined && values?.type && choices) {
            let result = choices.find(item => item.id === values[`${source}_humidity`]);
            depletion.onChange(result?.merma);
            let value = getMermaManipuleo(values?.type);
            if (value){
                setOptions([{id: value.manipuleo , name : `${value.manipuleo}`},{id: value.volatil, name:`Volatil ${value.volatil}`}]);
            }else{
                setOptions([]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values[`${source}_humidity`],choices])


    if (!values?.type == undefined || choices?.lenght === 0) return null;
    return (
        <AutocompleteInput 
            source={`${source}_humidity`}
            label="Humedad"
            record={values}
            choices={choices}
            optionText="name"
            optionValue="id"        
        />
    )
}
