import { useState, useEffect } from 'react';
import { NumberField } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { useRecordContext } from 'ra-core';


const useStyles = makeStyles({
    root: {
        color: '#FF0000',
    },
    clear: {
        color: 'green',
    },
    show: {
        fontSize: '1.5em'
    }
});

export const PriceField = ({ source, data = false, sortable = true, isShow }) => {
    const record = useRecordContext();
    const classes = useStyles();
    const [sign, setSign] = useState('');
    const getSign = () => {
        let result = '';
        if ((record && record[source] > 0) || (data &&  data[source] > 0)) {
            result = '+';
        } else if ((record && record[source] < 0) || (data &&  data[source] < 0)) {
            result = '-';
        }
        setSign(result);
    }

    useEffect(() => {
        getSign();
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [source,record,data])
    if (!data  && !record) return null;
    return (
        <NumberField
            source={source}
            record={data ? data : record}
            className={ source == 'balance'  ?`${sign === '-' ? classes.root : classes.clear} ${isShow ? classes.show : ''}` : ''}
            locales="es-AR"  
            sortable={sortable}
            options={{ style: 'currency', currency: 'ARS' }}
            />
    )
}
