import {
    SimpleForm,
    Create,
    TextInput,
    NumberInput,
  } from "react-admin";
  import {
    InputAdornment,
    Box
  } from '@material-ui/core';
  import { ActionsBack } from "../../../components/ActionsBack";
  import { Fragment } from "react";

  
  export const SimpleFieldExpense = props => {
    return (
      <Fragment>
        <Box>
            <TextInput source="description" label="Descripcion" />
        </Box>
        <Box>
            <NumberInput source="amount" label="Monto" InputProps={{
              startAdornment: (<InputAdornment position='start'>$</InputAdornment>)
            }}/>
        </Box>
        <Box>
            {/* TODO como asociar la opcion al deal que le corresponde mi idea es seleccionar el negocio e indicar el tipo si es de compra o de venta y asignarlo al necocio correspondiente en el back */}
            {/* <ReferenceInput source="deal_id" reference="deals" >
                <AutocompleteInput source="deal_id" optionText={ render => `${render.type} - ${new Date(render.created_at).toLocaleDateString('es-AR')}`} optionValue="id" resettable />
            </ReferenceInput> 
                <BooleanInput source="is_buy" label={values?.is_buy ? 'Es Compra' : 'Es Venta' } />
            */}

        </Box>
       
      </Fragment>
    )
  }
  
  export const ExpenseCreate = props => {
  
    return (
      <Create
        {...props}
        actions={<ActionsBack />}
        resource="trucks"
      >
        <SimpleForm
          redirect='list'
          variant='standard'
          margin='dense'
        >
          <SimpleFieldExpense />
        </SimpleForm>
      </Create>
    );
  };
  