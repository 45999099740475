
import { BorderBox } from "./BorderBox";
import { Typography, Box, LinearProgress } from "@material-ui/core";
import { PriceField } from "./PriceField";
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CustomLabeled } from "./CustomLabeled";
import { useGetOne } from "react-admin";
import { Fragment } from "react";
import { useEffect, useState } from "react";

const useStyles = makeStyles(theme => ({
    link: {
        color: '#5C7B34',
        fontWeight: '500',
        fontSize: '1.2em',
        textDecoration: 'none',
        '&:hover': {
            color: '#5C7B34',
        }
    },

}));

const Detail = ({ resource, id }) => {
    const { data, loaded } = useGetOne(resource, id);
    const [value,setValue] = useState(null);
    useEffect(()=>{
        if (data && data?.debt !== undefined){
            setValue(data);
        }
    },[data,id])

    if (!loaded || !value) return <LinearProgress />
    return (
        <Fragment>
            <Box >
                <CustomLabeled label="DEBE" style={{ color: '#818181' }}>
                    <PriceField source="debt" data={value} />
                </CustomLabeled>
            </Box>
            <Box marginleft='1em'>
                <CustomLabeled label="HABER" style={{ color: '#818181' }}>
                    <PriceField source="having" data={value} />
                </CustomLabeled>
            </Box>
            <Box marginleft='1em'>
                <CustomLabeled label="SALDO" style={{ color: '#818181' }}>
                    <PriceField source="balance" data={value} />
                </CustomLabeled>
            </Box>
        </Fragment>
    )
}

export const PeopleDetail = ({ id, source, props }) => {
    const classes = useStyles();
    const people = useSelector((state) => source == "customer" ? state.admin.resources.customers.data : state.admin.resources.producers.data);
    const buttonAction = { pathname: `/${source == "customer" ? "customers" : "producers"}/${id}/show` };
    if (!id) return null;
    return (
        <BorderBox>
            <Box diplay="flex" flexDirection="column">
                <Box>
                    <Typography variant="h6" component="span" >{people[id]?.name}</Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                    <Detail resource={source == "customer" ? "customers" : "producers"} id={id} />
                </Box>
                <Box>
                    <Link to={buttonAction} target="_blank" className={classes.link}>VER CLIENTE</Link>
                </Box>
            </Box>
        </BorderBox>
    )
}