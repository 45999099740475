import { fetchUtils } from 'react-admin';

const httpClient = fetchUtils.fetchJson;
export const TTL = 43200000 // milisegundos (1000*60*60*12) [12 horas]

export const authProvider = {
    // called when the user attempts to log in
    login: ({ username, password }) => {
        const body = {
            email: username,
            password,
            remember_me:1
        }
        const url = process.env.REACT_APP_API_HOST_ADMIN + '/auth/login';
        return httpClient(url, {
            method: 'POST',
            body: JSON.stringify(body),
        }).then(({ json }) => {
            localStorage.setItem('token', json.access_token);
            localStorage.setItem('auth', JSON.stringify(json.me));
            const item = {
                value: json.me.abilities,
                expiry: new Date().getTime() + TTL,
            }
            localStorage.setItem('permissions', JSON.stringify(item));
        }).catch(err => {
            throw new Error(err.statusText);
        })
    },
    // called when the user clicks on the logout button
    logout: () => {
        const token = localStorage.getItem('token');
        const options = {
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            })
        }
        const url = process.env.REACT_APP_API_HOST_ADMIN + '/auth/logout'
        return httpClient(url, options)
            .then(data => {
                localStorage.removeItem('token');
                localStorage.removeItem('auth');
                localStorage.removeItem('permissions');
            })
            .catch(err => {
                console.log(err)
            });
    },
    // called when the API returns an error
    checkError: ({ status }) => {
        if (status === 401 || status === 403) {
            localStorage.removeItem('token');
            localStorage.removeItem('auth');
            localStorage.removeItem('permissions');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {
        return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => {
        const item = localStorage.getItem('permissions');
        try {
            const permissions = JSON.parse(item)
            const now = new Date()
            if (now.getTime() > permissions.expiry) {
                const token = localStorage.getItem('token');
                const urlMe = process.env.REACT_APP_API_HOST_ADMIN + '/auth/me'
                const options = {
                    headers: new Headers({
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    })
                }
                return httpClient(urlMe, options)
                    .then(data => {
                        const now = new Date();
                        const item = {
                            value: data.json.abilities,
                            expiry: now.getTime() + TTL,
                        }
                        localStorage.setItem('permissions', JSON.stringify(item));
                        return data.json.abilities;
                    });
            }
            return Promise.resolve(permissions.value);
        } catch (error) {
            return Promise.reject()
        }
    }
};
