import * as React from 'react';
import { DataGrid } from '@material-ui/data-grid';
import { formatter } from '../utils/NumberFormatter';

const columns = [
    {
        field: 'date',
        headerName: 'Fecha',
        width: 120,
        valueFormatter: (params) => {
            const valueFormatted = new Date(params.row.created_at).toLocaleDateString('es-AR');
            return `${valueFormatted} `;
        },
    },
    {
        field: 'due_date',
        headerName: 'Expiración',
        width: 150,
        valueFormatter: (params) => {
            const valueFormatted =  params.row.due_date ? new Date(params.row.due_date).toLocaleDateString('es-AR') : '';
            return `${valueFormatted} `;
        },
    },
    {
        field: 'concept',
        headerName: 'Concepto',
        width: 150,
    },
    {
        field: 'debt',
        headerName: 'Debe',
        width: 150,
        valueFormatter: (params) => {
            let value =params.row.debt;
            let result = '-';
            if (value != 0) {
              result = `$${formatter.format(value)}`
            }
            return result;
        },
    },
    {
        field: 'having',
        headerName: 'Haber',
        width: 150,
        valueFormatter: (params) => {
            let value = (params.row.having);
            let result = '-';
            if (value != 0) {
              result = `$${formatter.format(value)}`
            }
            return result;
        },
    },
    {
        field: 'balance',
        headerName: 'Saldo',
        width: 150,
        valueFormatter: (params) => {
            const value = (params.row.balance);
            return `$${formatter.format(value)}`;
        },
    }
];



export default function ListMovements({ data }) {
    const [selectionModel, setSelectionModel] = React.useState([]);

    return (
        <div style={{ height: 400, width: '100%' }}>
            <DataGrid
                rows={data}
                columns={columns}
                pageSize={15}
                disableCheckboxSelection
                disableSelectionOnClick
                onSelectionModelChange={(newSelectionModel) => {
                    setSelectionModel(newSelectionModel);
                }}
                selectionModel={selectionModel}
                {...data}
            />
        </div>
    );
}
