import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import Add from '@material-ui/icons/Add';
import { useCallback } from 'react';

export const DealCreateLink = ({
  pathname,
  text,
  deal,
  ...props
}) => {
  const handleClick = useCallback(
    (event) => {
      event.stopPropagation();

    },
    []
  );
  return (
    <Button
      startIcon={<Add />}
      color="primary"
      variant="contained"
      component={Link}
      to={{
        pathname: `${pathname}${deal == 2 ? '/1' : ''}`,
        state: { deal: deal },
      }}
      onClick={handleClick}
    >
      {text}
    </Button>
  )
};

export default DealCreateLink;
