import {
  ReferenceInput,
  AutocompleteInput,
  showNotification,
  useCreateSuggestionContext,
  crudGetMatching,
  useCreate,
  required,
} from "react-admin";
import {
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Button
} from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector  } from "react-redux";
import { useForm, useField  } from "react-final-form";

export const CustomName =({source,record}) =>{
  const { input: location} = useField(source);
  const data = useSelector(state => state.admin.resources.locale.data);
  const locale  =  data ? data[record?.locale] : null;
  const [value,setValue] = useState(null);

  useEffect(() => {
    if (locale && (locale?.id !== value?.id || !value)){
      setValue(locale);
      location.onChange(locale?.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale,record?.locale]);

  return null;
}

const CreateLocale = ({ source }) => {
  const dispatch = useDispatch();
  const { filter, onCancel } = useCreateSuggestionContext();
  const [value, setValue] = useState(filter || '');
  const [newLocation, setNewLocation] = useState("");
  const { change } = useForm();
  const [create] = useCreate('locale');

  const handleSave = useCallback(
    location => {
      setNewLocation(location.id);
      change(source, location.id);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [change],
  );

  const handleSubmit = (event) => {
    event.preventDefault();
    create(
      {
        payload: {
          data: {
            name: value,
          },
        },
      },
      {
        onSuccess: ({ data }) => {
          setValue('');
          handleSave(data);
          dispatch(showNotification("Localidad " + data.name + " creada con éxito", "info"));
          onCancel();

        },
        onFailure: ({ error }) => {
          dispatch(showNotification(error.message, "error"));
          onCancel();
        },
      }
    );
  };
  useEffect(() => {
    dispatch(
      crudGetMatching(
        "locale",
        "locale@name",
        { page: 1, perPage: 25 },
        { field: "id", order: "DESC" },
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newLocation])
  return (
    <Dialog open onClose={onCancel}>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <TextField
            label="Nueva Localidad"
            value={value}
            onChange={event => setValue(event.target.value)}
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit">Save</Button>
          <Button onClick={onCancel}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export const LocationInput = ({source}) => {
  return (
    <ReferenceInput
      reference='locale'
      source={source}
      label='Localidad'
      variant="filled"
      filterToQuery={searchText => ({ search: searchText })}
      allowEmpty
      validate={[required()]}
    >
      <AutocompleteInput
        create={<CreateLocale source={source} />}
        optionText="name"
        optionValue='id'
        resettable
      />
    </ReferenceInput>
  )
}
