import {
  CreateButton,
  useListContext,
} from "react-admin";
import {Typography,Box} from '@material-ui/core';
import { havePermission } from "../utils/permissions";

export const EmptyPage = ({ resourceName, canCreate = true }) => {
  const { basePath , resource} = useListContext();
  return (
    <Box textAlign='center' m={1} mt='4em'>
      <Typography variant='h4' paragraph>
        No existen {resourceName} todavía
      </Typography>
    {canCreate ?
    <Box>
      <Typography variant='body1'>Ingrese el primer registro</Typography>
      {havePermission(`create-${resource}`)&& <CreateButton basePath={basePath} />}
    </Box>
      : null }
    </Box>
  );
};
