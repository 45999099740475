import { useState, useEffect } from "react";
import { useRecordContext } from 'ra-core';
import ListMovements from "../../../components/ListMovements";

export default function SpanningTable({ record }) {

  const [data, setData] = useState([]);
  useEffect(() => {
    let arr = [];
    if (record?.deals != null) {
      record.deals.forEach(element => {
        const obj = {
          type_id: element.id,
          type: 'deal',
          concept: element.type,
          debt: 0,
          having: (element.buy.price - element.buy.total_expenses),
          balance: 0,
          created_at: element.created_at,
          due_date: element.buy.due_date,
        }
        arr.push(obj);
      });
    }
    if (record?.movements != null) {
      record.movements.forEach(element => {
        const obj = {
          type_id: element.id,
          type: 'cash_register',
          concept: 'Pago',
          debt: element.amount,
          having: 0,
          balance: 0,
          created_at: element.created_at,
          due_date: null
        }
        if (element.money_box_id == 6 ){
          obj.concept = "Ajuste";
          if (element.amount < 0){
            obj.debt = 0;
            obj.having = (-1) * element.amount;
          }
        }
        arr.push(obj);
      });
    }
    if (arr.length > 0) {
      let id = 1;
      arr.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
      if (Array.isArray(arr) && arr.length) {
        for (let i = arr.length - 1; i >= 0; i--) {
          arr[i].id = id;
          if (i == arr.length - 1) {
            arr[i].balance = arr[i].having - arr[i].debt;
          } else {
            arr[i].balance = arr[i + 1]?.balance + arr[i]?.having - arr[i].debt;
          }
          id++;
        }
      }
      setData(arr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record]);

  return (
    record ? (
      <ListMovements data={data} />
    )
      :
      null
  );
}

export const MovementsDetails = () => {
  const record = useRecordContext();
  return (<SpanningTable record={record} />);
};
