import { useCallback } from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  useRecordContext,
} from "react-admin";
import { ExpenseFilter } from "./ExpenseFilter";
import { EmptyPage } from "../../../components/EmptyPage";
import { ListActions } from "../../../components/ListActions";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import VisibilityIcon from '@material-ui/icons/Visibility';

const useStyles = makeStyles(theme => ({
  link: {
    color: '#5C7B34',
    fontWeight: '500',
    fontSize: '1.1em',
    textDecoration: 'none',
    '&:hover': {
      color: '#5C7B34',
    }
  },

}));

const CustomLink = () => {
  const { deal_operation } = useRecordContext();
  const classes = useStyles();
  const handleClick = useCallback(
    (event) => {
      event.stopPropagation();
    },
    []
  );
  if (!deal_operation) return null;
  return (
    <Link to={`/deals/${deal_operation}/show`} onClick={e => handleClick(e)} className={classes.link}><VisibilityIcon /></Link>
  )
}

export const ExpenseList = props => {
  return (
    <List
      {...props}
      empty={<EmptyPage resourceName='Gastos' canCreate={false} />}
      title='Gastos'
      perPage={10}
      sort={{ field: "created_at", order: "DESC" }}
      filters={<ExpenseFilter />}
      actions={<ListActions labelButton="Nuevo Gasto" hasCreate={false} />}
      bulkActionButtons={false}
    >
      <Datagrid >
        <DateField source='created_at' label='Fecha' locales={'es-AR'} />
        <TextField source="amount" label='Monto' />
        <TextField source='description' label='Descripción' />
        <CustomLink label="Negocio" sortable={false} />
      </Datagrid>
    </List>
  );
};
