import {
    List,
    Datagrid,
    TextField,
    EditButton,
    ReferenceField,
} from "react-admin";
import { TruckFilter } from "./TruckFilter";
import { EmptyPage } from "../../../components/EmptyPage";
import { ListActions } from "../../../components/ListActions";


export const TruckList = props => {
    return (
        <List
            {...props}
            empty={<EmptyPage resourceName='Camiones' />}
            title='Camiones'
            perPage={10}
            sort={{ field: "created_at", order: "DESC" }}
            filters={<TruckFilter />}
            actions={<ListActions labelButton="Nuevo Camion" />}
            bulkActionButtons={false}
        >
            <Datagrid rowClick={"show"}>
                <TextField source="plate" label='Patente' />
                <TextField source='brand' label='Marca' />
                <TextField source='model' label='Modelo' />
                <ReferenceField source="truckowner_id" reference="truckowners" label={'Dueño'} rowClick="show">
                    <TextField source="name" />
                </ReferenceField>
                <EditButton />
            </Datagrid>
        </List>
    );
};
