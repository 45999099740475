/* eslint-disable import/no-anonymous-default-export */
import { CerealList } from './list';
import { CerealCreate } from './create';
import { CerealShow } from './show';
import { CerealEdit } from './edit';

export const DealCerealList = CerealList;
export const DealCerealCreate = CerealCreate;
export const DealCerealShow =  CerealShow;
export const DealCerealEdit =  CerealEdit;

export default {
    list: CerealList,
    create: CerealCreate,
    show: CerealShow,
    edit: CerealEdit,
};