import { useState, useEffect, } from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import { useField } from "react-final-form";
import { BorderBox } from "../../../components/BorderBox";
import { CustomLabeled } from "../../../components/CustomLabeled";
import { formatter } from "../../../utils/NumberFormatter";

const useStyles = makeStyles({
  array: {
    alignItems: 'center',
    '& ul li:first-child': {
      width: '0!important',
    },
    '& div.ra-input': {
      marginLeft: '0!important'
    }
  },
  spaceR: {
    marginRight: '1em',
  },
  spaceL: {
    marginLeft: '1em',
  },
  mgtop: {
    marginTop: 0,
  },
  text: {
    fontWeight: '6  00'
  },
  button: {
    maxWidth: '200px',
    marginTop: '1em'
  },
  mediaquery: {
    width: '40%',
    ['@media (min-width:768px)']: { // eslint-disable-line no-useless-computed-key
      width: '100%',
    }
  }
})




export const CargoAnimalDetail = ({ source, formData }) => {
  const classes = useStyles();
  const [total, setTotal] = useState(0);

  const { input: price_total } = useField(`${source}_price_total`);
  const { input: total_expense } = useField(`${source}_total_expenses`);

  const getTotal = () => {
    let expenses = 0;
    let animals = 0;
    let result = 0;

    if (formData[`${source}_expenses`]) {
      expenses = formData[`${source}_expenses`]?.reduce((acc, item) => { if (item?.amount) { return acc + item?.amount } else { return acc + 0 } }, 0);
    }
    if (formData[`${source}_animals`]) {
      animals = formData[`${source}_animals`]?.reduce((acc, item) => { if (item?.price_per_unit && item?.quantity) { return acc + (item?.price_per_unit * item?.quantity) } else { return acc + 0 } }, 0);
    }
    result = animals + expenses;

    price_total.onChange(result);
    total_expense.onChange(expenses);
    return result;
  }

  useEffect(() => {
    setTotal(getTotal());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData[`${source}_animals`], formData[`${source}_expenses`]])

  return (
    <Box mt='1em'>
      <BorderBox >
        <Typography component="span" variant="h6" style={{ fontSize: '1em' }} >Detalle de carga</Typography>
        <Box display="flex" justifyContent="space-between" className={classes.mediaquery}>
          <Box>
            <CustomLabeled label="GASTOS">
              <span className={classes.text}>${formatter.format(total_expense.value)}</span>
            </CustomLabeled>
          </Box>
          <Box>
            <CustomLabeled label="TOTAL">
              <span className={classes.text}>${formatter.format(total)}</span>
            </CustomLabeled>
          </Box>
        </Box>
      </BorderBox>
    </Box>
  )
}
