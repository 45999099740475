import * as React from 'react';
import AddIcon from '@material-ui/icons/Add';
import { Button } from '@material-ui/core';

export const CustomAddButton = (props) => {
    return (
        <Button  {...props} startIcon={<AddIcon />} variant="outlined" style={{ marginTop: '1em' }}>
            {props?.label ? props.label : 'Agregar'}
        </Button>
    );
};