import {
  SelectInput,
  AutocompleteInput,
  ReferenceInput,
  required,
  TextInput,
} from "react-admin";
import { Box, Typography, makeStyles } from "@material-ui/core";
const useStyles = makeStyles({
  input: {
    maxWidth: "250px",
  },
});
export const OptionLoan = ({ money_box_id }) => {
  const choices = [{ id: "moneybox", name: "Caja" }];
  const classes = useStyles();
  return (
    <Box>
      <Typography component="span" variant="h6">
        Origen
      </Typography>
      <Box display="flex">
        <Box mr="1em">
          <SelectInput
            choices={[{ id: "other", name: "Otros" }]}
            source="origin_type"
            label="Origen"
            defaultValue={"other"}
          />
        </Box>
        <TextInput source="entity" label="Nombre" className={classes.input} validate={[required()]}/>
      </Box>
      <Typography component="span" variant="h6">
        Destino
      </Typography>
      <Box display="flex">
        <Box mr="1em">
          <SelectInput
            choices={choices}
            source="destination_type"
            label="Destino"
            defaultValue={"moneybox"}
          />
        </Box>
        <ReferenceInput
          source={"destination_id"}
          reference={"money_box"}
          label={"Nombre"}
          defaultValue={money_box_id}
          filterToQuery={searchText => ({ search: searchText })}
          validate={[required()]}
          className={classes.input}
        >
          <AutocompleteInput
            source="destination_id"
            optionValue="id"
            optionText="name"
          />
        </ReferenceInput>
      </Box>
    </Box>
  );
};
