import React, { useEffect, Fragment, useState } from "react";
import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CREATE,
  SaveButton,
  SimpleForm,
  Toolbar,
  TextInput,
  NumberInput,
  SelectInput,
  required,
  DateInput,
  FormDataConsumer,
  showNotification,
  ReferenceInput,
  Create,
  AutocompleteInput,
} from "react-admin";
import { makeStyles, Typography, Box, InputAdornment } from "@material-ui/core";
import CancelButton from "./CancelButton";
import { OptionCharge } from "../create/OptionCharge";
import { OptionPayment } from "../create/OptionPayment";
import { OptionMovement } from "../create/OptionMovement";
import { OptionLoan } from "../create/OptionLoan";
import { OptionAdjust } from "../create/OptionAdjust";
import { Invoice } from "../create/Inovice";
import { useField, useFormState } from "react-final-form";
import { DealShow } from "../DealShow";
import { convertDateToString } from "../../../utils/datesFormatter";

const PostQuickCreateToolbar = ({ onCancel, ...props }) => (
  <Toolbar
    {...props}
    style={{
      width: "100%",
      boxSizing: "border-box",
      display: "flex",
      justifyContent: "space-between",
    }}
  >
    <SaveButton />
    <CancelButton onClick={onCancel} />
  </Toolbar>
);

const useStyles = makeStyles({
  form: { padding: 0 },
  margin: {
    marginLeft: "1em",
  },
  detail: {
    minHeight: "60px",
  },
  input: {
    maxWidth: "300px",
  },
  date: {
    maxWidth: "150px",
  },
  concept: {
    maxWidth: "400px",
  },
});

const CustomSelect = ({ money_box_id, moneybox_debts }) => {
  const { values } = useFormState();
  const { input: origin_type } = useField("origin_type");
  const { input: origin_id } = useField("origin_id");
  const { input: destination_type } = useField("destination_type");
  const { input: destination_id } = useField("destination_id");
  const { input: entity } = useField("entity");
  const [selected, setSelected] = useState();

  useEffect(() => {
    if (values?.movement_type != selected && selected) {
      setSelected(values?.movement_type);
    } else {
      setSelected(values?.movement_type);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.movement_type]);

  useEffect(() => {
    if (selected != values?.movement_type) {
      origin_type.onChange(undefined);
      origin_id.onChange(undefined);
      destination_type.onChange(undefined);
      destination_id.onChange(undefined);
      entity.onChange(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, values?.movement_type]);

  return (
    <Fragment>
      {values?.movement_type == "charge" &&
        selected == values?.movement_type && (
          <OptionCharge money_box_id={money_box_id} />
        )}
      {values?.movement_type == "payment" &&
        selected == values?.movement_type && (
          <OptionPayment
            money_box_id={money_box_id}
            moneybox_debts={moneybox_debts}
          />
        )}
      {values?.movement_type == "move" && selected == values?.movement_type && (
        <OptionMovement />
      )}
      {values?.movement_type == "loan" && selected == values?.movement_type && (
        <OptionLoan money_box_id={money_box_id} />
      )}
      {values?.movement_type == "adjust-in" &&
        selected == values?.movement_type && (
          <OptionAdjust money_box_id={money_box_id} source="destination" />
        )}
      {values?.movement_type == "adjust-out" &&
        selected == values?.movement_type && (
          <OptionAdjust money_box_id={money_box_id} source="origin" />
        )}
    </Fragment>
  );
};

const MoneyBox = ({ money_box }) => {
  const { input: money_box_input } = useField("money_box_id");
  useEffect(() => {
    if (money_box) {
      money_box_input.onChange(money_box);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [money_box]);
  return null;
};

const ShowConversion = () => {
  const { values } = useFormState();
  const price =
    values?.exchange_value && values?.amount
      ? values.exchange_value * values.amount
      : 0;
  if (price == 0) return null;
  return (
    <Typography
      component="span"
      style={{ lineHeigth: "48px", marginBottom: "10px", marginLeft: "1em" }}
    >
      Total ${price}
    </Typography>
  );
};

const SelectDebts = () => {
  const { values } = useFormState();
  const [debts, setDebts] = useState();
  const getDebts = async (obj) => {
    try {
      const token = localStorage.getItem("token");
      let params = "";
      Object.entries(obj)?.forEach((item, index) => {
        params += `${item[0]}=${encodeURIComponent(item[1])}`;
        if (Object.entries(obj).length - 1 != index) {
          params += "&";
        }
      });

      fetch(
        process.env.REACT_APP_API_HOST_ADMIN + `/debts/finddebts?${params}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          if (!res.ok) {
            return null;
          }
          return res.json();
        })
        .then((json) => {
          if (json) {
            setDebts(json);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    let obj = {};
    if (values?.origin_id) {
      obj.debtor_id = values.origin_id;
    }
    if (values?.destinarion_id) {
      obj.loaner_box_id = values.destinarion_id;
    }
    if (values?.entity && values?.entity?.length >= 4) {
      obj.entity = values.entity;
    }
    if (Object.keys(obj).length == 2) {
      getDebts(obj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values?.origin_id,
    values?.destinarion_id,
    values?.entity,
    values?.payDebt,
  ]);

  if (!debts) return null;
  return (
    <SelectInput
      style={{ width: "280px" }}
      source="debt_id"
      choices={debts}
      resettable
      allowEmpty
      optionText={(item) =>
        `fecha:${
          item?.date
            ? new Date(item.date).toISOString().substring(0, 10)
            : new Date(item.created_at).toISOString().substring(0, 10)
        } debe:${item.amount - item.amount_paid}`
      }
      label="seleccione deuda a saldar"
    />
  );
};

const Dialog = ({
  onCancel,
  onSave,
  money_box_id,
  moneybox_debts,
  ...props
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const submitting = useSelector((state) => state.admin.loading > 0);
  const choisesType = [
    { id: "charge", name: "Cobro" },
    { id: "move", name: "Movimiento" },
    { id: "payment", name: "Pago" },
    { id: "loan", name: "Prestamo" },
    { id: "dolar_conversion", name: "Conversión dolar a pesos" },
    { id: "adjust-in", name: "Ajuste Entrada" },
    { id: "adjust-out", name: "Ajuste Salida" },
  ];

  const today = convertDateToString(new Date());

  const validateData = (values) => {
    if (
      (values?.origin_type == "moneybox" &&
        values?.origin_id == values?.money_box_id) ||
      values?.movement_type == "dolar_conversion" ||
      (values?.destination_type == "moneybox" &&
        values?.destination_id == values?.money_box_id)
    ) {
      return true;
    }
    return false;
  };

  const handleSave = useCallback((values) => {
    if (validateData(values)) {
      dispatch(
        {
          type: "QUICK_CREATE",
          payload: { data: values },
          meta: {
            fetch: CREATE,
            resource: "cash_registers",
            onSuccess: {
              callback: ({ payload: { data } }) => {
                onSave(data);
                dispatch(
                  showNotification("Movimiento creado exitosamente", "info")
                );
              },
            },
            onFailure: {
              callback: ({error}) => {
                dispatch(showNotification(error.message, "error"));
              },
            },
          },
          // eslint-disable-line react-hooks/exhaustive-deps
        },
        [dispatch, onSave]
      );
    } else {
      dispatch(
        showNotification(
          "El movimiento que quiere realizar pertenece a otra caja",
          "danger"
        )
      );
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Create title=' ' {...props}>
      <SimpleForm
        save={handleSave}
        saving={submitting}
        redirect={false}
        toolbar={
          <PostQuickCreateToolbar onCancel={onCancel} submitting={submitting} />
        }
        classes={{ form: classes.form }}
      >
        <FormDataConsumer>
          {({ formData }) => {
            return (
              <Fragment>
                <MoneyBox money_box={money_box_id} />
                <SelectInput
                  resettable
                  source="movement_type"
                  label="Tipo de movimiento"
                  choices={choisesType}
                  optionValue="id"
                  optionText="name"
                  style={{ minWidth: "260px" }}
                />
                {formData?.movement_type !== undefined &&
                  formData?.movement_type !== null && (
                    <CustomSelect
                      money_box_id={money_box_id}
                      moneybox_debts={moneybox_debts}
                    />
                  )}
                {(formData?.movement_type == "charge" ||
                  (formData?.movement_type == "payment" &&
                    formData?.destination_type == "producer")) && <DealShow />}
                {formData?.movement_type == "payment" && formData?.payDebt && (
                  <SelectDebts />
                )}

                <Box style={{ marginTop: "1.5em" }}>
                  <Typography component="span" variant="h6">
                    Detalle del movimiento
                  </Typography>
                  <Box display="flex" flexDirection="column">
                    <DateInput
                      source="date"
                      defaultValue={today}
                      label="Fecha"
                      className={classes.date}
                      locales="es-AR"
                    />
                    <TextInput
                      source="concept"
                      validate={[required()]}
                      label="Concepto"
                      className={classes.concept}
                      multiline
                    />
                    {formData?.movement_type != "dolar_conversion" ? (
                      <Box display="flex">
                        <SelectInput
                          source="coin_type"
                          defaultValue={"arg"}
                          choices={[
                            { id: "arg", name: "Pesos ARG" },
                            { id: "usd", name: " Dólar" },
                          ]}
                          label="Moneda"
                          className={classes.input}
                        />
                        {formData?.coin_type &&
                          formData?.coin_type !== "arg" && (
                            <NumberInput
                              source="exchange_value"
                              label="Tipo de cambio"
                              className={classes.margin}
                              style={{ maxWidth: "150px" }}
                            />
                          )}
                      </Box>
                    ) : (
                      <NumberInput
                        source="exchange_value"
                        label="Valor cambio dolar a pesos"
                        className={classes.input}
                        style={{ maxWidth: "250px" }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                        min={0}
                        step={0.1}
                      />
                    )}
                    <Box display="flex" alignItems="center" marginBottom="1em">
                      <NumberInput
                        source="amount"
                        validate={[required()]}
                        label="Monto"
                        className={classes.input}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                        min={0}
                        step={0.1}
                      />
                      <ShowConversion />
                    </Box>
                    {formData?.movement_type == "payment" &&
                      formData?.payDebt &&
                      formData?.debt_id && (
                        <Box display="flex" flexDirection="column" mt={1}>
                          <NumberInput
                            source="interest_amount"
                            label="Interés monto"
                            style={{ width: "250px" }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ),
                            }}
                            min={0}
                            step={0.1}
                          />
                          <ReferenceInput
                            source="interest_box_id"
                            label="Caja cobro Interés"
                            reference={"money_box"}
                            filterToQuery={searchText => ({ search: searchText })}
                            defaultValue={5}
                          >
                            <AutocompleteInput
                              source="interest_box_id"
                              optionValue="id"
                              optionText="name"
                              resettable
                            />
                          </ReferenceInput>
                        </Box>
                      )}
                    {formData?.movement_type != "dolar_conversion" &&
                      formData?.movement_type != "move" &&
                      formData?.movement_type != "adjust-in" &&
                      formData?.movement_type != "adjust-out" && (
                        <SelectInput
                          source="payment_method"
                          defaultValue={"efectivo"}
                          choices={[
                            { id: "efectivo", name: "Efectivo" },
                            { id: "credito", name: "Crédito" },
                            { id: "cheque", name: "Cheque" },
                            { id: "transferencia", name: "Transferencia" },
                          ]}
                          label="Metodo"
                          className={classes.input}
                        />
                      )}
                    {formData?.payment_method &&
                      formData?.payment_method !== "efectivo" &&
                      formData?.payment_method !== "transferencia" && (
                        <Box display="flex">
                          <TextInput
                            source="receipt_number"
                            label="N° Recibo"
                            className={classes.input}
                          />
                          <TextInput
                            source="verification_number"
                            label="N° Comprobante"
                            className={`${classes.input} ${classes.margin}`}
                          />
                        </Box>
                      )}
                    {formData?.payment_method &&
                      formData?.payment_method == "cheque" && (
                        <DateInput
                          source="expiration_date"
                          label="fecha vencimiento"
                          style={{ width: "254px" }}
                        />
                      )}
                    {formData?.movement_type != "dolar_conversion" &&
                      formData?.movement_type != "move" &&
                      formData?.movement_type != "adjust-in" &&
                      formData?.movement_type != "adjust-out" && (
                        <TextInput
                          source="bank"
                          label="Banco"
                          className={classes.input}
                        />
                      )}
                  </Box>
                </Box>
                {formData?.movement_type != "dolar_conversion" &&
                  formData?.movement_type != "move" &&
                  formData?.movement_type != "adjust-in" &&
                  formData?.movement_type != "adjust-out" && <Invoice />}
              </Fragment>
            );
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};

export default Dialog;
