import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
    label:{
        color: '#818181',
        fontSize: '0.8em!important',
        marginBottom: '0.3em!important'
    },
    container:{
        maxWidth: '90vw'
    }
});

export const CustomLabeled = ({label, children}) =>{
    const classes = useStyles();
    return (
        <Box>
        <Typography  className={classes.label} >{label}</Typography>
        {children}
        </Box>
        )
}
