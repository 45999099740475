import { Fragment } from "react";
import { useCallback, useState } from "react";
import { Button, Dialog } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import AddIcon from '@material-ui/icons/Add';
import InvoiceDialog from "./InvoiceDialog";

export const InvoiceQuickCreate = ({ source, setInvoices}) => {
    const [showCreateDialog, setShowCreateDialog] = useState(false);

    const handleNewClick = useCallback(
        (event) => {
            event.stopPropagation();
            event.preventDefault();
            setShowCreateDialog(true);
        },
        [setShowCreateDialog]
    );

    const handleCloseCreate = useCallback(() => {
        setShowCreateDialog(false);
    }, [setShowCreateDialog]);

    const handleSave = useCallback(
        invoice => {
            setShowCreateDialog(false);
            setInvoices(prev => [...prev,invoice]);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [setShowCreateDialog],
    );

    return (
        <Fragment>
            <Button variant="outlined" style={{ maxHeight: '40px', marginTop: '1em', marginLeft: '1em', maxWidth: '200px' }}  onClick={handleNewClick} startIcon={< AddIcon />}>
            ADJUNTAR FACTURA
            </Button>
            <Dialog
                fullWidth
                open={showCreateDialog}
                onClose={handleCloseCreate}
                onClick={(e) => e.stopPropagation()}
                aria-label="Adjuntar Factura"
            >
                <DialogTitle>Adjuntar Factura</DialogTitle>
                <DialogContent style={{ padding: 0 }}>
                    <InvoiceDialog
                        source={source}
                        onCancel={handleCloseCreate}
                        onSave={handleSave}
                        basePath="/invoices"
                        resource="invoices"
                    />
                </DialogContent>
            </Dialog>
        </Fragment>
    )
}
