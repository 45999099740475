import { Fragment } from 'react';
import {
  List,
  Datagrid,
  DateField,
  TextField,
  NumberField,
  useRecordContext,
  ReferenceField,
} from "react-admin";
import { MoneyDebtFilter } from "./MoneyDebtFilter";
import { EmptyPage } from "../../../components/EmptyPage";
import { LinearProgress } from "@material-ui/core";

const CustomEntity = ({ source, label }) => {
  const record = useRecordContext();

  const reference = 'money_box';
  if (!reference || !record) return <LinearProgress />

  return (
    <Fragment>
      {record[`${source}`] != null ? (
        <ReferenceField label={label} source={"id"} record={{ id: record[`${source}`] }} reference={reference} link="show">
          <TextField source="name" />
        </ReferenceField>
      )
        :
        (<span>{record?.entity}</span>)
      }
    </Fragment>
  )
}

export const MoneyDebtList = props => {
  return (
    <List
      {...props}
      empty={<EmptyPage resourceName="deudas" canCreate={false}/>}
      title={'Deudas entre cajas'}
      perPage={10}
      sort={{ field: "created_at", order: "DESC" }}
      filters={<MoneyDebtFilter />}
      filter={{ money_box_id: props?.money_box_id }}
      bulkActionButtons={false}
      resource={"debts"}
    >
      <Datagrid title='Movimientos' >
        <DateField source="created_at" label="Fecha" locales="es-AR" options={{ timeZone: 'UTC' }} />
        <CustomEntity source="loaner_box_id" label="Origen" {...props} />
        <CustomEntity source="debtor_box_id" label="Destino" {...props} />
        <NumberField source="amount" label="Monto Operacion" locales="es-AR" options={{ style: 'currency', currency: 'ARS' }} />
        <NumberField source="amount_paid" label="Monto Pago" locales="es-AR" options={{ style: 'currency', currency: 'ARS' }} />
      </Datagrid>
    </List>
  );
};
