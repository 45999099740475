import {useState, useEffect } from "react";
import {
  DateField,
  NumberField,
} from "react-admin";
import { Box, Typography, Button } from "@material-ui/core";
import { useField, useFormState } from "react-final-form";
import { InvoiceQuickCreate } from "../../invoices/quickcreate/InvoiceQuickCreate";
import { InvoiceDetail } from "../../invoices/InvoiceDetail";
export const  Invoice = () => {
  const { values } = useFormState();
  const [invoices, setInvoices] = useState(values[`invoices`] || []);
  const { input: invoices_items } = useField(`invoices`);
  const [show, setShow] = useState(null);

  useEffect(() => {
    if (invoices?.length > 0) {
      invoices_items.onChange(invoices);
    } else {
      invoices_items.onChange([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoices]);

  const showDetail = (item) => {
    setShow(item);
  }
  return (
    <Box display="flex" flexDirection="column" mt="1.5em" >
      <Typography component="span" variant="h6" >Facturación</Typography>
      <Box margin="0.5em 0" maxWidth={"300px"}>
      {/* <ReferenceInput source="invoice_selected" reference="invoices" label="Seleccione Factura" fullWidth >
        <AutocompleteInput resettable source="invoice_selected" optionText={item => item ? `${item?.verification_number} - ${new Date(item?.date).toLocaleDateString('es-AR')} - $ ${item?.amount}`: ''} optionValue="id" />
      </ReferenceInput> */}
      </Box>
      {invoices?.length > 0 && invoices.map((item, index) => (
        <Box display="flex" justifyContent="space-between" key={index}>
          <Box display="flex">
            <Box mr="1em">
              <DateField source="date" record={item} label="" locales="es-AR" options={{ timeZone: 'UTC' }} />
            </Box>
            <NumberField source="amount" record={item} label="" locales="es-AR" options={{ style: 'currency', currency: 'ARS' }} />
          </Box>
          <Button onClick={() => showDetail(item)}>VER DETALLE</Button>
        </Box>
      ))}
      <InvoiceQuickCreate setInvoices={setInvoices} />
      <InvoiceDetail show={show} setShow={setShow} />
    </Box>
  )
}
