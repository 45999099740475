export const getBody = (values, props) => {
  let buy = {
    operation_id : values?.buy_id,
    operable_id : values?.buy_operabe_id,
    cargo_id : values?.buy_cargo_id,
    date: values?.buy_date,
    due_date: values?.buy_due_date,
    price_total: (values?.buy_price_total ? values.buy_price_total.toFixed(6) : 0),
    expenses: values?.buy_expenses,
    total_expenses: values?.buy_total_expenses,
    type: values?.type,
    people_id: values?.producer_id,
    people_type: 'producer',
    location: values?.producer_location,
    settlement: values?.producer_settlement,
    observations: values?.buy_observations,
    invoices: values?.buy_invoices,
    deal_type: props?.deal_type,
  };
  let sell = {
    operation_id : values?.sell_id,
    operable_id : values?.sell_operabe_id,
    cargo_id : values?.sell_cargo_id,
    date: values?.sell_date,
    due_date: values?.sell_due_date,
    price_total: (values?.sell_price_total ? values.sell_price_total.toFixed(6) : 0),
    expenses: values?.sell_expenses,
    total_expenses: values?.sell_total_expenses,
    type: values?.type,
    people_id: values?.customer_id,
    people_type: 'customer',
    settlement: values?.customer_settlement,
    location: values?.customer_location,
    observations: values?.sell_observations,
    invoices: values?.sell_invoices,
    deal_type: props?.deal_type,
  }

  


  if (props.deal_type != 'ganaderia') {
    /** cereal  and sowing*/
    buy = {
      ...buy,
      quantity: values?.buy_quantity,
      depletion: values?.buy_depletion,
      humidity: values?.buy_humidity,
      extra_percentages: values?.buy_extra_percentages,
      price_per_unit: values?.buy_price_per_unit,
    };
    sell = {
      ...sell,
      quantity: values?.sell_quantity,
      depletion: values?.sell_depletion,
      humidity: values?.sell_humidity,
      extra_percentages: values?.sell_extra_percentages,
      price_per_unit: values?.sell_price_per_unit,
    };
  } else {
    /** animals */
    buy = {
      ...buy,
      animals: values?.buy_animals,
      type: 'animales'
    }

    sell = {
      ...sell,
      animals: values?.buy_animals,
      type: 'animales'
    }
  }

  let record = {
    id: values?.id,
    type: values.type,
    deal_type: props?.deal_type,
    transport: values?.transports || null
  };

  if (values?.customer_id) {
    record = { ...record, sell: sell };
  }
  if (values?.producer_id) {
    record = { ...record, buy: buy };
  }
  return record;
}
