import * as React from "react";
import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CREATE,
  SaveButton,
  SimpleForm,
  Toolbar,
  showNotification,
  TextInput,
  FormDataConsumer,
  SimpleFormIterator,
  ArrayInput
} from "react-admin";
import { Box, makeStyles, Typography } from '@material-ui/core';
import { CustomAddButton } from '../../../components/CustomAddButton';
import { CustomDeleteButton } from '../../../components/CustomDeleteButton';
import CancelButton from "./CancelButton";
import { LocationInput, CustomName } from "../create/LocationSelect";
import { validateCUITCUILRequired, validateEmail, validateRequired, validateRequiredNumbers } from "../../../utils/Validations";
import patternMatch from "../../../components/patternMatch.js";


export const PeopleSimpleFields = ({title}) => {
  const cuilCuitFormat = value => {
    const pattern = "xx-xxxxxxxx-x";
    return patternMatch(value, pattern);
  }
  return (
    <Box display="flex" flexDirection="column" style={{ maxWidth: "360px" }}>
      <Typography variant="h5" component="span" >{title}</Typography>
      <TextInput
        source='name'
        label='Nombre y Apellido o Razón Social'
        variant="filled"
        style={{ maxWidth: "360px" }}
        validate={validateRequired}
      />
      <TextInput
        source='email'
        label='Email'
        type="email"
        variant="filled"
        validate={validateEmail}
      />
      <TextInput
        source='phone_number'
        label='Telefono'
        type="phone"
        variant="filled"
        validate={validateRequiredNumbers}
      />
      <TextInput
        source="cuil_cuit"
        label="CUIL-CUIT"
        variant="filled"
        helperText={"formato válido xx-xxxxxxxx-x"}
        validate={validateCUITCUILRequired}
        format={cuilCuitFormat}
      />
      <TextInput
        source="cbu_alias"
        label="CBU ALIAS"
        variant="filled"
      />
    </Box>
  );
}

const PostQuickCreateToolbar = ({ submitting, onCancel, ...props }) => (
  <Toolbar {...props} style={{ width: '100%', boxSizing: 'border-box', display: 'flex', justifyContent: 'space-between' }}>
    <SaveButton />
    <CancelButton onClick={onCancel} />
  </Toolbar>
);

const useStyles = makeStyles({
  form: { padding: 0 },
});

const PeopleDialog = ({ onCancel, onSave, record, ...props }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const submitting = useSelector(state => state.admin.loading > 0);

  const handleSave = useCallback(
    values => {
      dispatch({
        type: "QUICK_CREATE",
        payload: { data: values },
        meta: {
          fetch: CREATE,
          resource: "producers",
          onSuccess: {
            callback: ({ payload: { data } }) => {
              onSave(data);
              dispatch(showNotification("Productor " + data.name + "creado con éxito", "info"));
            },
          },
          onFailure: {
            callback: ({ error }) => {
              dispatch(showNotification(error.message, "error"));
            },
          },
        },
      });
    },
    [dispatch, onSave],
  );

  return (
    <SimpleForm
      save={handleSave}
      saving={submitting}
      redirect={false}
      toolbar={
        <PostQuickCreateToolbar onCancel={onCancel} submitting={submitting} />
      }
      classes={{ form: classes.form }}
      initialValues={{initial_adjustment:0}}
      {...props}
    >
      <PeopleSimpleFields/>
      <Typography variant="subtitle2" component="span">Establecimientos</Typography>
      <ArrayInput source='settlements' label="" style={{ maxWidth: '46vw' }} >
        <SimpleFormIterator disableReordering={true} getItemLabel={(index) => ''} addButton={<CustomAddButton label={"AGREGAR ESTABLECIMIENTO"} {...props} />} removeButton={<CustomDeleteButton  {...props} className={classes.array} />}>
          <FormDataConsumer>
            {({ getSource, scopedFormData }) => {
              return (
                <Box display='flex' flexDirection='column' ml={'1em'}>
                  <Box display="flex">
                    <Box mr={3}>
                        <LocationInput source={getSource('locale')}/>
                        {scopedFormData?.locale && <CustomName source={getSource("localename")} record={scopedFormData}/>}
                    </Box>
                    <Box mr={3}>
                      <TextInput source={getSource("settlement")} label="Establecimiento" variant="filled" />
                    </Box>
                  </Box>
                </Box>
              );
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm >
  );
};

export default PeopleDialog;
