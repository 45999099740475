import { useState, useEffect } from "react";
import { useRecordContext } from "ra-core";
import ListMovements from "../../../components/ListMovements";

export default function SpanningTable({ record }) {
  const [data, setData] = useState([]);

  const getDetail = (element) => {
    let value = 0;
    let due_date = null;
    element?.transport?.forEach((transport) => {
      if (record.trucks.find((item) => item.id == transport?.truck_id)) {
        value += transport?.transporting_fee ? transport.transporting_fee : 0;
        due_date = transport?.transporting_payment
          ? element[`due_date_${transport?.transporting_payment}`]
          : null;
      }
    });

    return { debt: value, due_date: due_date };
  };

  useEffect(() => {
    let arr = [];
    if (record?.deals != null) {
      record.deals.forEach((element) => {
        let aux = getDetail(element);
        const obj = {
          type_id: element.id,
          type: "deal",
          concept: element.type,
          debt: aux.debt,
          having: 0,
          balance: 0,
          created_at: element.created_at,
          due_date: aux.due_date,
        };
        arr.push(obj);
      });
    }
    if (record?.movements != null) {
      record.movements.forEach((element) => {
        const obj = {
          type_id: element.id,
          type: "cash_register",
          concept: "flete",
          debt: 0,
          having: element.amount,
          balance: 0,
          created_at: element.created_at,
          due_date: null,
        };
        if (element.money_box_id == 6 ){
          obj.concept = "Ajuste";
          if (element.amount < 0){
            obj.debt = (-1) * element.amount;
            obj.having = 0;
          }
        }
        arr.push(obj);
      });
    }
    if (arr.length > 0) {
      let id = 1;
      arr.sort(
        (a, b) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      );
      if (Array.isArray(arr) && arr.length) {
        for (let i = arr.length - 1; i >= 0; i--) {
          arr[i].id = id;
          if (i == arr.length - 1) {
            arr[i].balance = arr[i].having - arr[i].debt;
          } else {
            arr[i].balance = arr[i + 1]?.balance + arr[i]?.having - arr[i].debt;
          }
          id++;
        }
      }
    }
    setData(arr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record]);

  return record ? <ListMovements data={data} /> : null;
}

export const MovementsDetails = () => {
  const record = useRecordContext();
  return <SpanningTable record={record} />;
};
