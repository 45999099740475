import {
  SelectInput
} from 'react-admin';
import { useSelector } from 'react-redux';
import { useField  } from "react-final-form";
import { useEffect, useState } from 'react';

export const EstablishmentSelect = ({ isSell = false, formData, source, label }) => {
  const src = `${source}_settlement`;
  const location = `${source}_location`;
  const [current,setCurrent] = useState(null);
  const data = useSelector(state => state.admin.resources[`${source}s`].data);
  const choices = isSell ? data[formData.customer_id]?.settlements : data[formData.producer_id]?.settlements;
  const locationname = formData[location];
  const arrOpts = choices?.filter((item) => item?.localename === locationname && item?.settlement != undefined) || [];
  const { input: source_settlement } = useField(src);

  useEffect( () => {
    if (formData[location]) {
      if (!current){
        setCurrent(formData[location]);
      }else{
        if (current !== formData[location]){
          source_settlement.onChange('');
          setCurrent(formData[location]);
        }
      }
    }
  }, [formData[location]])

  if (!data || !choices || choices?.length === 0 || arrOpts?.length === 0 ) return null;
  return (
    <SelectInput
      source={src}
      choices={arrOpts}
      optionText={'settlement'}
      optionValue={'settlement'}
      record={formData}
      label={label} />
  )
}
