import React, { Fragment } from 'react';
import {
    Show,
    SimpleShowLayout,
    EditButton,
    TextField,
    useRecordContext,
    ArrayField,
    Datagrid,
} from 'react-admin';
import {
    Box, 
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { CustomLabeled } from '../../../components/CustomLabeled';
import { ActionsBack } from "../../../components/ActionsBack";



const useStyles = makeStyles({
    container: {
        maxWidth: '90vw'
    },
});

const Action = () => {
    const record = useRecordContext();
    return (
        <Fragment>
            <TextField source="plate" style={{ fontSize: '1.6em', fontWeight: '500', textTransform: 'capitalize' }} />
            <EditButton variant="outlined" basePath="/trucks" record={record} size="small" />
        </Fragment>
    )
}

const TrucksDetail = ({...props}) =>{
    const record = useRecordContext();
    if (!record?.trucks) return null;
    return(
      <ArrayField source="trucks" label="Camiones" record={record}>
          <Datagrid >
            <TextField source="brand" label="Marca" />
            <TextField source="model" label="Modelo" />
          </Datagrid>
      </ArrayField>
    )
  }  

export const TruckShow = ({ ...props }) => {
    const classes = useStyles();
    return (
        <Show {...props} actions={<ActionsBack />}>
            <SimpleShowLayout >
                <Box display="flex" justifyContent="space-between" m='1em 0' className={classes.container}>
                    <Action />
                </Box>
                <Box>
                    <CustomLabeled label={"Patente"}>
                    <TextField source='plate' />
                    </CustomLabeled>
                </Box>
                <Box>
                    <CustomLabeled label={"Marca"}>
                    <TextField source='brand' />
                    </CustomLabeled>
                </Box>
                <Box>
                    <CustomLabeled label={"Modelo"}>
                    <TextField source='model' />
                    </CustomLabeled>
                </Box>
                <TrucksDetail />
            </SimpleShowLayout>
        </Show>
    )
}
