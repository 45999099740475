import {
	required,
	number,
	email,
  } from "react-admin";
  
  
  const confirmedPassword = (value, allValues) => {
	if (value != allValues['password']) return 'Las contraseñas no coinciden';
	return undefined;
  }
  
  function CUITCUIL(value,allValues)
	  {
		  if (!value || value?.length == 0) return 'El cuil/cuit es requerido';
		  if (value?.length < 13 && value?.length >1) return 'Ingrese un cuil/cuit válido xx-xxxxxxxx-x';
		  
		  let rv = false;
		  let resultado = 0;
		  let cuit_nro = value.replace("-", "");
		  let codes = "6789456789";
		  let verificador = parseInt(cuit_nro[cuit_nro.length-1]);
		  var x = 0;
		  
		  while (x < 10)
		  {
			  let digitoValidador = parseInt(codes.substring(x, x+1));
			  if (isNaN(digitoValidador)) digitoValidador = 0;
			  let digito = parseInt(cuit_nro.substring(x, x+1));
			  if (isNaN(digito)) digito = 0;
			  let digitoValidacion = digitoValidador * digito;
			  resultado += digitoValidacion;
			  x++;
		  }
		  resultado = resultado % 11;
		  rv = (resultado == verificador);
		  if (!rv){
			  return 'Ingrese un cuil/cuit válido xx-xxxxxxxx-x';
		  }
		  return undefined;
	  }
  export const validateCUITCUIL = CUITCUIL;
  export const validateCUITCUILRequired = [CUITCUIL,required()];
  export const validateRequiredNumbers = [required(), number()];
  export const validateNumbers = [number()];
  export const validateRequired = [required()];
  export const validateEmail = [required(), email()];
  export const validateConfirmationPassword = [required(), confirmedPassword];