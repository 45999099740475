export default function patternMatch(value, pattern){
  try {
    let j = 0;
    let plaintext = "";
    let countj = 0;
    while (j < pattern.length) {
      // code block to be
      if (countj > value?.length - 1) {
        pattern = pattern.substring(0, j);
        break;
      }

      if (pattern[j] == value[j]) {
        j++;
        countj++;
        continue;
      }

      if (pattern[j] == "x") {
        pattern = pattern.substring(0, j) + value[countj] + pattern.substring(j + 1);
        plaintext = plaintext + value[countj];
        countj++;
      }
      j++;
    }

    return pattern
  } catch {
    return ""
  }
}
