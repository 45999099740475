import {
  SelectInput,
  AutocompleteInput,
  ReferenceInput,
  required,
  BooleanInput,
} from "react-admin";
import { Box, Typography, makeStyles } from "@material-ui/core";
import { useFormState } from "react-final-form";
const useStyles = makeStyles({
    input:{
        maxWidth: '250px'
    }
})
export const OptionMovement = () => {
    const classes = useStyles();
  const { values } = useFormState();
  const choices = [{ id: "moneybox", name: "Caja" }];

  return (
    <Box>
      <Typography component="span" variant="h6">
        Origen
      </Typography>
      <Box display="flex">
        <Box mr="1em">
          <SelectInput
            choices={choices}
            source="origin_type"
            label="Origen"
            defaultValue={"moneybox"}
          />
        </Box>
        {values?.origin_type ? (
          <ReferenceInput
            source={"origin_id"}
            reference={"money_box"}
            label={"Nombre"}
            filterToQuery={searchText => ({ search: searchText })}
            validate={[required()]}
            className={classes.input}
          >
            <AutocompleteInput
              source="origin_id"
              optionValue="id"
              optionText="name"
              resettable
            />
          </ReferenceInput>
        ) : null}
      </Box>
      <Typography component="span" variant="h6">
        Destino
      </Typography>
      <Box display="flex">
        <Box mr="1em">
          <SelectInput
            choices={choices}
            source="destination_type"
            label="Destino"
            defaultValue={"moneybox"}
          />
        </Box>
        {values?.destination_type ? (
          <ReferenceInput
            source={"destination_id"}
            reference={"money_box"}
            label={"Nombre"}
            filterToQuery={searchText => ({ search: searchText })}
            validate={[required()]}
            className={classes.input}
          >
            <AutocompleteInput
              source="destination_id"
              optionValue="id"
              optionText="name"
              resettable
            />
          </ReferenceInput>
        ) : null}
      </Box>
      {values?.destination_type == "moneybox" && (
        <BooleanInput source="isDebt" label="Genera deuda" />
      )}
    </Box>
  );
};
