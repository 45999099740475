import { useState } from "react";
import { MenuItemLink } from "react-admin";
import { useSelector } from "react-redux";
import { Box } from '@material-ui/core';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ViewListIcon from '@material-ui/icons/ViewList';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PeopleIcon from '@material-ui/icons/People';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import InboxIcon from '@material-ui/icons/Inbox';
import SubMenu from "./SubMenu";
import ReceiptIcon from '@material-ui/icons/Receipt';
import { havePermission } from "../utils/permissions";

export const Menu = ({ onMenuClick, logout, dense = false }) => {
  const [state, setState] = useState({
    usersMenu: false,
    dealsMenu: false,
    moneyboxesMenu: false,
  });
  const open = useSelector(state => state.admin.ui.sidebarOpen);
  useSelector(state => state.theme);

  const handleToggle = menu => {
    setState(state => ({ ...state, [menu]: !state[menu] }));
  };

  return (
    <Box mt={3} >
      <MenuItemLink
        to={`/`}
        primaryText={"Home"}
        leftIcon={<HomeRoundedIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
        exact
      />

      {/* Negocios */}
      {havePermission('list-deals') && <SubMenu
        handleToggle={() => handleToggle("dealsMenu")}
        isOpen={state.dealsMenu}
        sidebarIsOpen={open}
        name='Negocios'
        icon={<BusinessCenterIcon />}
        dense={dense}
      >
        <MenuItemLink
          to={'/negocios/cereal'}
          primaryText={"Cereales"}
          leftIcon={<AssignmentIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          role="submenu"
          dense={dense}
        />
        <MenuItemLink
          to={'/negocios/ganaderia'}
          primaryText={"Ganaderia"}
          leftIcon={<AssignmentIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          role="submenu"
          dense={dense}
        />
        <MenuItemLink
          to={'/negocios/siembra'}
          primaryText={"Siembra"}
          leftIcon={<AssignmentIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          role="submenu"
          dense={dense}
        />
      </SubMenu>}
      {havePermission('list-customers') && <MenuItemLink
        to={`/customers`}
        primaryText={"Clientes"}
        leftIcon={<PeopleIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        role="menu"
        dense={dense}
      />}
      {havePermission('list-producers') &&<MenuItemLink
        to={`/producers`}
        primaryText={"Productores"}
        leftIcon={<PeopleIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        role="menu"
        dense={dense}
      />}

      {havePermission('list-truckowners') && <MenuItemLink
        to={`/truckowners`}
        primaryText={"Transporte"}
        leftIcon={<LocalShippingIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        role="menu"
        dense={dense}
      />}
      {havePermission('list-expenses') &&<MenuItemLink
        to={'/expenses'}
        primaryText={"Gastos"}
        leftIcon={<MonetizationOnIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        role="menu"
        dense={dense}
      />}
       {havePermission('list-debts') &&<MenuItemLink
        to={'/debts'}
        primaryText={"Deudas"}
        leftIcon={<ImportExportIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        role="menu"
        dense={dense}
      />}
      {/* Cajas */}
      {havePermission('list-cash_registers') && <SubMenu
        handleToggle={() => handleToggle("moneyboxesMenu")}
        isOpen={state.moneyboxesMenu}
        sidebarIsOpen={open}
        name='Cajas'
        icon={<InboxIcon />}
        dense={dense}
      >
        <MenuItemLink
          to={'/caja/cereal'}
          primaryText={"Cereales"}
          leftIcon={<MonetizationOnIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          role="submenu"
          dense={dense}
        />
        <MenuItemLink
          to={'/caja/ganaderia'}
          primaryText={"Ganaderia"}
          leftIcon={<MonetizationOnIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          role="submenu"
          dense={dense}
        />
        <MenuItemLink
          to={'/caja/siembra'}
          primaryText={"Siembra"}
          leftIcon={<MonetizationOnIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          role="submenu"
          dense={dense}
        />
        <MenuItemLink
          to={'/caja/transporte'}
          primaryText={"Transporte"}
          leftIcon={<MonetizationOnIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          role="submenu"
          dense={dense}
        />
        <MenuItemLink
          to={'/caja/socios'}
          primaryText={"Socios"}
          leftIcon={<MonetizationOnIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          role="submenu"
          dense={dense}
        />
      </SubMenu>}
      {/** FACTURAS */}
      {havePermission('list-invoices') && <MenuItemLink
          to={'/invoices'}
          primaryText={"Facturas"}
          leftIcon={<ReceiptIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          role="menu"
          dense={dense}
        />
      }
      {/**  USUARIOS */}
      {(havePermission('list-users') || havePermission('list-roles')) && <SubMenu
        handleToggle={() => handleToggle("usersMenu")}
        isOpen={state.usersMenu}
        sidebarIsOpen={open}
        name='Usuarios'
        icon={<AccountCircleIcon />}
        dense={dense}
      >
         {havePermission('list-users') && <MenuItemLink
          to={`/users`}
          primaryText={"Listado"}
          leftIcon={<ViewListIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          role="submenu"
          dense={dense}
        />}
         {havePermission('list-cash_roles') &&<MenuItemLink
          to={`/roles`}
          primaryText={"Roles y permisos"}
          leftIcon={<SupervisedUserCircleIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          role="submenu"
          dense={dense}
        />}
      </SubMenu>}
      {logout}
    </Box>
  );
};
