import DealCreateLink from "./list/DealCreateLink.jsx";
import {
  Button,
  Dialog,
  makeStyles,
  InputLabel,
  Select,
  Box,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useCallback, useState } from "react";

const useStyles = makeStyles({
  button: {
    padding: '4px 10px',
    fontSize: '0.8125rem',
    maxHeight: '50px',
    border: '0',
    marginLeft: '1em',
    background: '#5C7B34',
    color: 'white',
    width: '170px',
    marginTop: 0,
    minHeight: '40px',
    '&:hover': {
      background: '#5C7B34',
      color: 'white'
    }
  },
  title: {
    padding: '8px 12px'
  },
  dialog:{
    width: '500px',
    margin: '0 auto',
    minHeight: '400px'
  }
})

export const CreateDialog = (props) => {
  const [deal, setDeal] = useState(3);
  const classes= useStyles();
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const handleNewClick = useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();
      setShowCreateDialog(true);
  }, [setShowCreateDialog]); // eslint-disable-line react-hooks/exhaustive-deps
  const handleCloseCreate = useCallback(() => {
    setShowCreateDialog(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setShowCreateDialog]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      <Button variant="contained" onClick={handleNewClick} startIcon={< AddIcon />} className={classes.button}>
        NUEVO NEGOCIO
      </Button>
      <Dialog
      className={classes.dialog}
        open={showCreateDialog}
        onClose={handleCloseCreate}
        onClick={(e) => e.stopPropagation()}
        aria-label="Nuevo Movimiento"
      >
        <DialogTitle className={classes.title}>Nuevo Negocio</DialogTitle>
        <DialogContent style={{ padding: '1em' }}>
        <Box>
          <InputLabel htmlFor="deal">Tipo de Negocio</InputLabel>
          <Select
            onChange={event => setDeal(event.target.value)}
            variant="standard"
            defaultValue={3}
            style={{height: '40px', padding: '4px 8px', marginTop: '.2em', marginBottom: '1em'}}
          >
            <MenuItem value={1}>Compra</MenuItem>
            <MenuItem value={2}>Venta</MenuItem>
            <MenuItem value={3}>Compra y Venta</MenuItem>
          </Select>
        </Box>
        </DialogContent>
        <DialogActions>
          <DealCreateLink text="Nuevo Negocio" deal={deal} pathname={`${props.basePath}/create`} {...props}/>
          {/* <CreateButton variant="contained" deal={deal} label={props.labelButton} className={classes.button}/> */}
        </DialogActions>
      </Dialog>
    </>
  )
}

export default CreateDialog;
