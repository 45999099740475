import { Fragment, useState } from "react";
import { Box, Divider } from "@material-ui/core";
import { useDispatch } from "react-redux";
import {
  TabbedForm,
  FormTab,
  Create,
  TextInput,
  showNotification,
  useRedirect,
  useRefresh,
  useNotify,
} from "react-admin";
import { ActionsBack } from "../../../components/ActionsBack";
import { PeopleSelect } from "./PeopleSelect";
import { Cargo } from "../cargo/Cargo";
import { Transport } from "./Transport";
import { getBody } from "./variables";
import { Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useFormState } from "react-final-form";

const useStyles = makeStyles({
  none: {
    display: "none",
    margin: 0,
    padding: 0,
    height: 0,
  },
  root: {
    "& .MuiTabs-root": {
      minHeight: 0,
    },
  },
  error:{
    color: "#FF0000!important",
  }
});

const Observation = ({ source }) => {
  return (
    <Box display="flex" flexDirection="column" mt="1.5em">
      <Typography component="span" variant="h6">
        Observaciones
      </Typography>
      <TextInput
        label="Comentarios"
        source={`${source}_observations`}
        multiline={true}
        style={{ maxWidth: "500px" }}
      />
    </Box>
  );
};

const CreateActions = ({ basePath }) => {
  return (
    <Box display="flex">
      <ActionsBack redirect={`${basePath}`} />
    </Box>
  );
};

const TabSell = ({deal_type,...props}) => {
  const classes = useStyles();
  const { errors } = useFormState();
  const keys = Object.keys(errors);
  const error = keys?.find((a) => a?.includes("sell"));
  return (
    <FormTab label="Venta" className={error ? classes.error : ""} {...props}>
      <Box mt="1em" fullWidth>
        <PeopleSelect isSell={true} label="Cliente" source="customer" />
        <Divider />
        <Cargo source="sell" deal_type={deal_type} />
        <Divider />
        <Observation source="sell" />
      </Box>
    </FormTab>
  );
};

const TabBuy = ({deal_type,...props}) => {
  const classes = useStyles();
  const { errors } = useFormState();
  const keys = Object.keys(errors);
  const error = keys?.find((a) => a?.includes("buy"));
  return (
    <FormTab label="Compra" className={error ? classes.error : ""} {...props}>
      <Box mt="1em" fullWidth>
        <PeopleSelect label="Productor" source="producer" />
        <Divider />
        <Cargo source="buy" deal_type={deal_type} />
        <Divider />
        <Observation source="buy" />
      </Box>
    </FormTab>
  );
};

export const DealCreate = ({ deal = 3, ...props }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const notify = useNotify();
  const [dealSelected, setDealSelected] = useState(deal);

  const onFailure = (error) => {
    dispatch(showNotification(error.message, "error"));
  };
  const onSuccess = () => {
    notify(`Negocio guardado`);
    refresh();
    redirect(props.basePath);
  };
  const transform = (data) => {
    return getBody(dealSelected, data, props);
  };

  const handleSetDeal = () => {
    setDealSelected(3);
  };

  return (
    <Fragment>
      {dealSelected == 2 && (
        <Button onClick={() => handleSetDeal()}>Agregar Venta</Button>
      )}
      <Create
        {...props}
        resource="deals"
        title="Nuevo negocio"
        actions={<CreateActions {...props} />}
        transform={transform}
        onSuccess={onSuccess}
        onFailure={onFailure}
      >
        <TabbedForm
          sanitizeEmptyValues={false}
          variant="standard"
          margin="dense"
          className={classes.root}
        >
          {dealSelected == 1 || dealSelected == 3 ? <TabBuy deal_type={props.deal_type} /> : null}
          {dealSelected == 2 || dealSelected == 3 ? <TabSell deal_type={props.deal_type}/> : null}
          <FormTab label="Transporte">
            <Transport />
          </FormTab>
        </TabbedForm>
      </Create>
    </Fragment>
  );
};
