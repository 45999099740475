import {
    List,
    Datagrid,
    TextField,
    EditButton
} from "react-admin";
import { PeopleFilter } from "./PeopleFilter";
import { EmptyPage } from "../../../components/EmptyPage";
import { ListActions } from "../../../components/ListActions";
import { PriceField } from "../../../components/PriceField";


export const PeopleList = props => {
    return (
        <List
            {...props}
            empty={<EmptyPage resourceName='clientes' />}
            title='Lista de Clientes'
            perPage={10}
            sort={{ field: "name", order: "ASC" }}
            filters={<PeopleFilter />}
            actions={<ListActions labelButton="Nuevo Cliente" />}
            bulkActionButtons={false}
        >
            <Datagrid rowClick={"show"}>
                <TextField source="name" label='Nombre y Apellido o Razón Social' />
                <TextField source='email' label='Email' />
                <TextField source='phone_number' label='Telefono' />
                <PriceField source="balance" label="Estado de cuenta" sortable={false}/> 
                <EditButton />
            </Datagrid>
        </List>
    );
};
