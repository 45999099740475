import React from 'react';
import styled, { ThemeProvider as SCThemeProvider } from "styled-components";
import { useTheme, StylesProvider } from "@material-ui/core/styles";

const CustomBox = styled.div`
    border: 1px solid #BFBFBF;
    padding: 21px 16px;
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    width: 100%;
    background: white;
    min-width: 500px;
    ${props => props.theme.breakpoints.up("md")}{
        max-width: 30vw;
    }
  `;

export const BorderBox = ({props, children}) =>{
    const muiTheme = useTheme();
  return (
    <StylesProvider injectFirst>
      <SCThemeProvider theme={muiTheme}>
      <CustomBox>
        {children}
    </CustomBox>
      </SCThemeProvider>
    </StylesProvider>
  );
}
