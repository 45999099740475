import { cloneElement } from 'react';
import {
  TopToolbar,
  CreateButton,
  useListContext
} from "react-admin";
import { makeStyles } from "@material-ui/core";
import { havePermission } from '../utils/permissions';
const useStyles = makeStyles({
  button:{
    height: '40px',
  }
})

export const ListActions = (props) => {
  const classes= useStyles();
  const {resource} = useListContext();
  return(
    <TopToolbar display="flex" justifyContent="space-between" alignItems="center">
    {cloneElement(props.filters, { context: 'button' })}
    {props.hasCreate && havePermission(`create-${resource}`) && <CreateButton variant="contained"  label={props.labelButton} className={classes.button}/>}
  </TopToolbar>
  );
}
