import { Create, SimpleForm } from "react-admin";
import PeopleCreate from "../../../components/PeopleCreate";
import { ActionsBack } from "../../../components/ActionsBack";

const title = 'Productor';

export const ProducerCreate = props => {
  return (
    <Create
      {...props}
      title={`Crear ${title}`}
      actions={<ActionsBack />}
    >
      <SimpleForm
        redirect='list'
        variant='standard'
        margin='dense'
      >
        <PeopleCreate title={title} />
      </SimpleForm>
    </Create>
  );
};
