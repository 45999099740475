import {
  List,
  Datagrid,
  TextField,
  NumberField,
  DateField,
} from "react-admin";
import { InvoiceFilter } from "./InvoiceFilter";
import { EmptyPage } from "../../../components/EmptyPage";
import { ListActions } from "../../../components/ListActions";

export const InvoiceList = props => {
  return (
    <List
      {...props}
      empty={<EmptyPage resourceName='Facturas' />}
      title='Facturas'
      perPage={10}
      sort={{ field: "created_at", order: "DESC" }}
      filters={<InvoiceFilter />}
      actions={<ListActions labelButton="Nueva Factura" hasCreate={false} />}
      bulkActionButtons={false}
    >
      <Datagrid >
        <DateField source='created_at' label='Fecha' locales={'es-AR'} />
        <TextField source='type' label='Tipo' />
        <TextField source='verification_number' label='N° Comprobante' />
        <NumberField source="iva" locales="es-AR" options={{ style: 'currency', currency: 'ARS' }}/>
      </Datagrid>
    </List>
  );
};
