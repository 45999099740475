import { Fragment } from "react";
import { useRecordContext } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import Chip from '@material-ui/core/Chip';

const useOrderStyles = makeStyles({
  chipCa: { padding: '2px 4px', backgroundColor: '#D11616', color: 'white' },
  chipCo: { padding: '2px 4px', backgroundColor: '#5CA445', color: 'white' },
  chipPe: { padding: '2px 4px', backgroundColor: '#EEF2E1' },
});

export const StatusField = () => {
  const record = useRecordContext();
  const classes = useOrderStyles();
  return (
    <Fragment>
      {
        record.status === "completed" &&
        <Chip
          size="small"
          className={classes.chipCo}
          label={'Terminado'}
        />
      }
      {
        record.status === "cancelled" &&
        <Chip
          size="small"
          className={classes.chipCa}
          label={'Cancelado'}
        />
      }
      {
        record.status === 'in_process' &&
        <Chip
          size="small"
          className={classes.chipPe}
          label={'En proceso'}
        />
      }
    </Fragment>
  )
}