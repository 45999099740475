import React, { Fragment } from 'react';
import {
  Show,
  SimpleShowLayout,
  EditButton,
  TextField,
  useRecordContext,
  useLocale,
} from 'react-admin';
import {
  Box, Divider, Table, TableHead, TableRow, TableCell, TableBody, Typography, Card,
  CardContent, Stepper, Step, StepLabel, StepContent
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { BorderBox } from '../../../components/BorderBox';
import { PriceField } from '../../../components/PriceField';
import { Title } from '../../../components/Title';
import { CustomLabeled } from '../../../components/CustomLabeled';
import { MovementsDetails } from './MovementsDetail';
import { ActionsBack } from "../../../components/ActionsBack";
import AssignmentIcon from '@material-ui/icons/Assignment';
import { Link } from "react-router-dom";
import { useCallback } from 'react';
import { havePermission } from '../../../utils/permissions';

const useEventStyles = makeStyles({
  stepper: {
    background: 'none',
    border: 'none',
    padding: '16px'
  },
});

const useStyles = makeStyles({
  container: {
    maxWidth: '90vw'
  },
  thead: {
    color: '#818181',
    border: 0,
    padding: 0,
    fontSize: '0.8em',
    fontWeight: 400,
    lineheight: '1.5'
  },
  tcell: {
    border: 0,
    padding: 0
  },
  table: {
    marginTop: '1em'
  }
});

const Action = () => {
  const record = useRecordContext();
  return (
    <Fragment>
      <TextField source="name" style={{ fontSize: '1.6em', fontWeight: '500', textTransform: 'capitalize' }} />
      {havePermission('edit-producers') && <EditButton variant="outlined" basePath="/producers" record={record} size="small" />}
    </Fragment>
  )
}

const Settlements = ({ type = 'localidad' }) => {
  const record = useRecordContext();
  const classes = useStyles();

  return (<Table className={classes.table}>
    <TableHead >
      <TableRow>
        {type === 'localidad' && <TableCell className={classes.thead}>LOCALIDAD</TableCell>}
        {type === 'establecimiento' && <TableCell className={classes.thead}>ESTABLECIMIENTO</TableCell>}
      </TableRow>
    </TableHead>
    <TableBody>
      {record.settlements && record.settlements.map((item, index) => (
        <TableRow key={index} display="flex" >
          {type === 'localidad' && <TableCell className={classes.tcell}> {item?.localename} </TableCell>}
          {type === 'establecimiento' && <TableCell className={classes.tcell}> {item.settlement}</TableCell>}
        </TableRow>))
      }
    </TableBody>

  </Table>)
}

const LinkItem = ({ pathname, text }) => {
  const handleClick = useCallback(
    (event) => {
      event.stopPropagation();
    }, []
  );
  return (<Link style={{ textDecoration: 'none', color: '#5C7B34' }}
    to={{
      pathname: `${pathname}`,
    }}
    onClick={handleClick}
  >
    {text}
  </Link>)
}

const Aside = () => {
  const record = useRecordContext();
  const classes = useEventStyles();
  const locale = useLocale();
  const events = record?.deals ? record.deals : [];
  return (
    <>
      <Box m="0 0 1em 1em">
        <Card>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Historial de negocios
            </Typography>

            <Stepper orientation="vertical" classes={{ root: classes.stepper }}>
              {events.map((event, index) => (
                <Step
                  key={index}
                  expanded
                  active
                  completed
                >
                  <StepLabel
                    StepIconComponent={() => (
                      <AssignmentIcon
                        fontSize="small"
                        style={{ paddingLeft: 3, color: '#5C7B34' }}
                      />)
                    }
                  >
                    <Box>
                      {new Date(event.created_at).toLocaleString(locale, {
                        dateStyle: 'long'
                      })}
                    </Box>

                  </StepLabel>
                  <StepContent>
                    <Box>
                      <LinkItem pathname={`/deals/${event.id}/show`} text={event.type} />
                    </Box>
                    <Box mt={'0.2em'}>
                      <span style={{ color: '#343434', fontSize: '14px' }}>{`${event?.buy?.location ? event.buy.location : ' '} > ${event?.sell?.location ? event.sell.location : ' '}`}</span>
                    </Box>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}

export const PeopleShow = ({ ...props }) => {
  const classes = useStyles();
  return (
    <Show {...props} actions={<ActionsBack />} aside={<Aside />}>
      <SimpleShowLayout >
        <Box display="flex" justifyContent="space-between" m='1em 0' className={classes.container}>
          <Action />
        </Box>
        <Box display="flex" justifyContent="space-between" maxWidth='900px' >
          <Box textAlign="left">
            <CustomLabeled label="TELÉFONO">
              <TextField source="phone_number" />
            </CustomLabeled>
            <Settlements />
          </Box>
          <Box >
            <CustomLabeled label="EMAIL">
              <TextField source="email" />
            </CustomLabeled>
            <Settlements type="establecimiento" />
          </Box>
          <Box>
            <CustomLabeled label="CUIL/CUIT">
              <TextField source="cuil_cuit" />
            </CustomLabeled>
          </Box>
          <Box>
            <CustomLabeled label="CBU/ALIAS">
              <TextField source="cbu_alias" />
            </CustomLabeled>
          </Box>
        </Box>
        <Divider style={{ margin: '1.5em 0' }} />
        <BorderBox>
          <Box display="flex" justifyContent="space-between">
            <Title label="Cuenta Corriente" />
            <Box>
              <PriceField source="balance" isShow={true}  {...props} />
            </Box>
          </Box>
        </BorderBox>

        <Box display="flex" justifyContent="space-between" mt={4}>
          <Title label="Detalle de movimientos" />
          <Box>
            {/* <Button startIcon={<AddIcon />} variant="outlined" disabled style={{ marginRight: '1em' }}>GENERAR REPORTE</Button> */}
          </Box>
        </Box>
        <Box mt={2}>
          <MovementsDetails />
        </Box>
      </SimpleShowLayout>
    </Show>
  )
}
