import { Resource } from 'react-admin';

import { UserList, UserCreate , UserEdit} from '../resources/users';
import { NonPermission } from '../components/NonPermission';
import { havePermission } from '../utils/permissions';
import { CustomerList,CustomerCreate, CustomerEdit,CustomerShow } from '../resources/customers';
import { ProducerList, ProducerCreate, ProducerEdit, ProducerShow } from '../resources/producers';
import { TruckownersList, TruckownersCreate, TruckownersEdit , TruckownersShow } from '../resources/truckowners';
import { TruckList,TruckCreate, TruckEdit, TruckShow } from '../resources/trucks';
import { DealList, DealCreate, DealEdit, DealShow } from '../resources/deals';
import { CashRegisterList } from '../resources/cashregisters';
import { ExpenseCreate, ExpenseList } from '../resources/expenses';
import { MoneyDebtList } from '../resources/money_debt';
import { DealCerealList, DealCerealCreate, DealCerealEdit, DealCerealShow } from '../resources/negocios/cereal';
import { DealAnimalList, DealAnimalCreate, DealAnimalEdit, DealAnimalShow } from '../resources/negocios/ganaderia';
import { DealSowingList, DealSowingCreate, DealSowingEdit, DealSowingShow } from '../resources/negocios/siembra';

import { CerealList } from "../resources/cajas/cereal";
import { GanaderiaList } from "../resources/cajas/ganaderia";
import { SiembraList } from "../resources/cajas/siembra";
import {TransporteList} from "../resources/cajas/transporte";
import { SociosList } from "../resources/cajas/socios";
import { FiscalList } from "../resources/cajas/fiscal";
import { RoleList, RoleCreate , RoleEdit} from '../resources/roles';
import { InvoiceList } from '../resources/invoices/list/InvoiceList';

export const Resources = () => [
    <Resource name='users' 
        list={havePermission('list-users') ? UserList : null}
        edit={havePermission('edit-users') ? UserEdit : NonPermission}
        create={havePermission('create-users') ? UserCreate : NonPermission}
    />,
    <Resource name='customers' 
        list={havePermission('list-customers') ? CustomerList : null}
        edit={havePermission('edit-customers') ? CustomerEdit : NonPermission}
        create={havePermission('create-customers') ? CustomerCreate : NonPermission}
        show={CustomerShow}
    />,
    <Resource name='producers'  
        list={havePermission('list-producers') ? ProducerList : null}
        edit={havePermission('edit-producers') ? ProducerEdit : NonPermission}
        create={havePermission('create-producers') ? ProducerCreate : NonPermission}
        show={ProducerShow} 
    />,  
    <Resource name='truckowners'  
        list={havePermission('list-truckowners') ? TruckownersList : null}
        edit={havePermission('edit-truckowners') ? TruckownersEdit : NonPermission}
        create={havePermission('create-truckowners') ? TruckownersCreate : NonPermission}
        show={TruckownersShow} 
    />,
    <Resource name='trucks' 
        list={havePermission('list-truckowners') ? TruckList : null}
        edit={havePermission('edit-truckowners') ? TruckEdit : NonPermission}
        create={havePermission('create-truckowners') ? TruckCreate : NonPermission}
        show={TruckShow} 
    />,
    <Resource name='locale' 
        create={TruckCreate}
    />,
    <Resource name='deals'  
        list={havePermission('list-deals') ? DealList : null}
        edit={havePermission('edit-deals') ? DealEdit : NonPermission}
        create={havePermission('create-deals') ? DealCreate : NonPermission}
        show={DealShow} 
    />,
    <Resource name="cash_registers" 
        list={havePermission('list-cash_registers') ? CashRegisterList : null}
    />,
    <Resource name='operations'/>,
    <Resource name='money_box'/>,
    <Resource name='invoices'list={InvoiceList}/>,
    <Resource name='expenses' 
        list={havePermission('list-expenses') ? ExpenseList : null}
        create={havePermission('create-expenses') ? ExpenseCreate  : NonPermission}
    />,
    <Resource name="negocios/cereal" options={{ label: 'negocios/cereal' }} list={DealCerealList} create={DealCerealCreate} show={DealCerealShow} edit={DealCerealEdit}/>,
    <Resource name="negocios/ganaderia" options={{ label: 'negocios/ganaderia' }} list={DealAnimalList} create={DealAnimalCreate} show={DealAnimalShow} edit={DealAnimalEdit}/>,
    <Resource name="negocios/siembra" options={{ label: 'negocios/siembra' }} list={DealSowingList} create={DealSowingCreate} show={DealSowingShow} edit={DealSowingEdit}/>,

    <Resource name="caja/cereal"  options={{ label: 'caja/cereal' }} list={CerealList}  />,
    <Resource name="caja/siembra" options={{ label: 'caja/siembra' }} list={SiembraList} />,
    <Resource name="caja/ganaderia" options={{ label: 'caja/ganaderia' }} list={GanaderiaList} />,
    <Resource name="caja/transporte" options={{ label: 'caja/transporte' }} list={TransporteList}  />,
    <Resource name="caja/socios" options={{ label: 'caja/socios' }} list={SociosList}  />,
    <Resource name="caja/fiscal" options={{ label: 'caja/fiscal' }} list={FiscalList} />,

    <Resource name="debts" list={havePermission('list-debts') ? MoneyDebtList : null}/>,
    <Resource name="roles" 
        list={havePermission('list-roles') ? RoleList : null} 
        create={havePermission('create-roles')? RoleCreate : NonPermission} 
        edit={havePermission('edit-roles') ? RoleEdit : NonPermission}/>,
    <Resource name="abilities" />
]

export default Resources;
