import { Box, Typography, makeStyles } from "@material-ui/core";
import styled from 'styled-components';
import { formatter } from "../../../utils/NumberFormatter";

const useStyles = makeStyles({
  title: {
    color: '#5C7B34',
    fontSize: '1em'
  },
  value: {
    fontWeight: 500,
    fontSize: '1.5em'
  }

})

const CustomBox = styled('div')({
  border: '1px solid #BFBFBF',
  padding: '16px',
  boxSizing: 'border-box',
  borderRadius: 4,
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  maxWidth: '200px',
  width: '100%',
  background: 'white'
});



export const Header = ({ money }) => {
  const classes = useStyles();

  return (<Box display="flex" justifyContent="space-between" alignItems="center" mb='1em'>
    <CustomBox >
      <Box display="flex" flexDirection="column" boxSizing="border-box" flex="1">
        <Box>
          <Typography variant='h6' component="span" className={classes.title}>Caja Actual</Typography>
        </Box>
           <Typography variant='h6' component="span" className={classes.value}>${formatter.format(money?.actual_value || 0)} </Typography>
      </Box>
    </CustomBox>
    <CustomBox>
      <Box display="flex" flexDirection="column" boxSizing="border-box" >
        <Typography variant='h6' component="span" className={classes.title}>Caja Virtual</Typography>
        <Typography variant='h6' component="span" className={classes.value}>${formatter.format(money?.virtual_value || 0)} </Typography>
      </Box>
    </CustomBox>
  </Box>
  )
}

