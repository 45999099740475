import { createElement, useState, useEffect, Fragment } from 'react';
import { Card, Box, Typography, Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MonetizationOnRoundedIcon from '@material-ui/icons/MonetizationOnRounded';
import { Link } from 'react-router-dom';
import { DataGrid } from '@material-ui/data-grid';
import { formatter } from '../../utils/NumberFormatter';

const useStyles = makeStyles(theme => ({
  card: {
    minHeight: 52,
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    '& a': {
      textDecoration: 'none',
    },
  },
  main: (props) => ({
    overflow: 'inherit',
    padding: '16px',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .icon': {
      color: '#5C7B34',
    },
  }),
  title: {
    color: '#818181',
    fontSize: '1.2em',
  },
  subtitleWarning: {
    color: '#E59800',
    '&:hover': {
      color: '#E59800',
    }
  },
  subtitleNormal: {
    color: '#343434',
    '&:hover': {
      color: '#343434',
    }
  },
  link: {
    color: '#5C7B34',
    fontWeight: '500',
    fontSize: '1.2em',
    textDecoration: 'none',
    '&:hover': {
      color: '#5C7B34',
    }
  }
}));

const CardWithIcon = ({ icon, title, subtitle, handleClick, buttonText, color, decoration, ...props }) => {
  const classes = useStyles(props);

  return (
    <Card className={classes.card}>
      <div className={classes.main} >
        <Box p="0.5em" className="icon" display="flex" alignSelf="flex-start" >
          {createElement(icon, { sx: { fontSize: '3.5em' } })}
        </Box>
        <Box textAlign="right"  >
          <Typography
            className={classes.title}
            color="textSecondary"
          >
            {title}
          </Typography>
          <Typography variant="h5" component="h2" sx={{ fontSize: '1.6em', fontWeight: '600', marginBottom: '0.4em' }} className={color ? classes.subtitleWarning : classes.subtitleNormal}>
            {decoration ? decoration : ''}{subtitle ? subtitle : 0}
          </Typography>
          <Button onClick={handleClick} className={classes.link}>{buttonText}</Button>
        </Box>
      </div>
    </Card>
  );
};

const columns = [
  {
    field: 'name',
    headerName: 'Nombre',
    width: 120,
  },
  {
    field: 'actual_value',
    headerName: 'Valor Actual',
    type: 'number',
    width: 150,
    valueFormatter: (params) => {
      const value = (params.row.actual_value);
      return `$${formatter.format(value)} `;
    },
  },
  {
    field: 'virtual_value',
    headerName: 'Valor Virtual',
    type: 'number',
    width: 150,
    valueFormatter: (params) => {
      const value = (params.row.virtual_value);
      return `$${formatter.format(value)} `;
    },
  },
  {
    field: 'link',
    headerName: '',
    width: 100,
    renderCell: (params) => {
      return (<Link style={{
        color: '#5C7B34',
        fontWeight: '500',
        fontSize: '1.2em',
        textDecoration: 'none',
        '&:hover': {
          color: '#5C7B34',
        }
      }} to={`/caja/${params.row.name == 'cereales' ? 'cereal' : params.row.name}`} >Ver</Link>)
    },
  },

];


export const MoneyBoxDetail = ({ money_box, ...props }) => {
  const [show, setShow] = useState(false);
  const [total, setTotal] = useState(0);
  useEffect(() => {
    if (money_box) {
      setTotal(money_box?.reduce((acc, item) => acc + item.actual_value, 0))
    }
  }, [money_box])

  const handleClick = () => {
    setShow(true);
  }

  const handleClose = () => {
    setShow(false);
  }
  return (
    <Fragment>
      <CardWithIcon
        handleClick={handleClick}
        icon={MonetizationOnRoundedIcon}
        title={'Estado de cajas'}
        color={true}
        decoration={'$'}
        subtitle={formatter.format(total || 0)}
        buttonText="VER DETALLE"
      />
      <Dialog
        open={show}
        onClose={handleClose}
        aria-labelledby="Cajas detalle"
        aria-describedby="monto actual de las cajas"

      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Cajas
        </DialogTitle>
        <DialogContent dividers>
          <div style={{ height: 550, width: '550px' }}>
            {money_box && <DataGrid
              rows={money_box}
              columns={columns}
              pageSize={15}
              disableCheckboxSelection
              disableSelectionOnClick
              {...money_box}
            />}
          </div>
        </DialogContent>
      </Dialog>
    </Fragment>
  )
}
