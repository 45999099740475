import { AppBar } from 'react-admin';
import { Typography }  from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import logo from '../assets/Logo.png';

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
    logo: {
        maxHeight: '30px',
        maxWidth: "145px",
        marginBottom: 5,
        marginTop: 5,
    },
});

export const CustomAppBar = props => {
    const classes = useStyles();
    return (
        <AppBar {...props} color='inherit'>
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />
            <img src={logo} alt="logo" className={classes.logo} />
            <span className={classes.spacer} />
        </AppBar>
    );
};
