import {
  SelectInput,
  AutocompleteInput,
  ReferenceInput,
  TextInput,
  required,
  BooleanInput,
} from "react-admin";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { ProducersQuickCreate } from "../../producers/quickcreate/PeopleQuickCreate";
import { TruckOwnerQuickCreate } from "../../truckowners/quickcreate/PeopleQuickCreate";
import { useFormState } from "react-final-form";

const useStyles = makeStyles({
  input: {
    maxWidth: "250px",
  },
  ul: {
    listStyle: 'none',
    margin:0,
    marginBottom: '1em',
    padding:0,
    '& > li': {
      textTransform: 'capitalize',
      fontWeight: 500,
      fontSize: '1em',
      padding: '4px 0',
    }
  },
});
export const OptionPayment = ({ money_box_id ,moneybox_debts}) => {
  const classes = useStyles();
  const { values } = useFormState();
  const choices_origin = [{ id: "moneybox", name: "Caja" }];
  const choices_destination = [
    { id: "moneybox", name: "Caja", reference: "money_box" },
    { id: "producer", name: "Productor", reference: "producers" },
    { id: "truckowner", name: "Dueño de camión", reference: "truckowners" },
    { id: "other", name: "Otro" },
  ];

  return (
    <Box>
      <Typography component="span" variant="h6">
        Origen
      </Typography>
      <Box display="flex">
        <Box mr="1em">
          <SelectInput
            choices={choices_origin}
            source="origin_type"
            label="Origen"
            defaultValue={"moneybox"}
          />
        </Box>
        <ReferenceInput
          source={"origin_id"}
          reference={"money_box"}
          label={"Nombre"}
          filterToQuery={searchText => ({ search: searchText })}
          defaultValue={money_box_id}
          validate={[required()]}
          className={classes.input}
        >
          <AutocompleteInput
            source="origin_id"
            optionValue="id"
            optionText="name"
            resettable
          />
        </ReferenceInput>
      </Box>
      <Typography component="span" variant="h6">
        Destino
      </Typography>
      <Box display="flex">
        <Box mr="1em">
          <SelectInput
            choices={choices_destination}
            source="destination_type"
            label="Destino"
          />
        </Box>
        {values?.destination_type && values?.destination_type !== "other" ? (
          <ReferenceInput
            source={"destination_id"}
            filterToQuery={searchText => ({ search: searchText })}
            reference={
              choices_destination.find(
                (item) => item.id == values.destination_type
              )?.reference
            }
            label={"Nombre"}
            validate={[required()]}
            className={classes.input}
          >
            <AutocompleteInput
              source="destination_id"
              optionValue="id"
              optionText="name"
              resettable
            />
          </ReferenceInput>
        ) : values?.origin_type !== "null" ? (
          <TextInput source="entity" label="Nombre" className={classes.input} validate={[required()]}/>
        ) : null}
        {values?.destination_type == "producer" && (
          <ProducersQuickCreate source="destination_id" />
        )}
        {values?.destination_type == "truckowner" && (
          <TruckOwnerQuickCreate source="destination_id" />
        )}
      </Box>
      {(values?.destination_type == "moneybox" ||
        values?.destination_type == "other") &&
        <BooleanInput source="payDebt" label="Saldar deuda" />
      }
      {(values?.origin_type == 'moneybox' && values?.destination_type == 'moneybox') && 
        <ul className={classes.ul}>
            {moneybox_debts && Object.keys(moneybox_debts?.debts)?.map((key, index) => {
              return (
                <li key={index}>{moneybox_debts.money_box.find(v => v.id == key)?.name}: $ {moneybox_debts?.debts[key]} </li>
              )
            })}
          </ul>
      }
    </Box>
  );
};
