import * as React from "react";
import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CREATE,
  SaveButton,
  SimpleForm,
  Toolbar,
  showNotification,
  TextInput,
  NumberInput,
} from "react-admin";
import { Box, makeStyles, Typography, InputAdornment } from '@material-ui/core';
import CancelButton from "./CancelButton";
import { validateCUITCUILRequired, validateEmail, validateRequired, validateRequiredNumbers } from "../../../utils/Validations";

export const PeopleSimpleFields = ({ title }) => {
  return (
    <Box display="flex" flexDirection="column" style={{ maxWidth: "360px" }}>
      <Typography variant="h5" component="span" >{title}</Typography>
      <TextInput
        source='name'
        label='Nombre y Apellido o Razón Social'
        variant="filled"
        style={{ maxWidth: "360px" }}
        validate={validateRequired}
      />
      <TextInput
        source='email'
        label='Email'
        type="email"
        variant="filled"
        validate={validateEmail}
      />
      <TextInput
        source='phone_number'
        label='Telefono'
        type="phone"
        variant="filled"
        validate={validateRequiredNumbers}
      />
      <TextInput
        source="cuil_cuit"
        label="CUIL-CUIT"
        variant="filled"
        helperText={"formato válido xx-xxxxxxxx-x"}
        validate={validateCUITCUILRequired}
      />
      <TextInput
        source="cbu_alias"
        label="CBU ALIAS"
        variant="filled"
      />
      <NumberInput
        source="initial_adjustment"
        label="Ajuste inicial"
        step={0.1}
        variant="filled"
        validate={validateRequiredNumbers}
        InputProps={{
          startAdornment: (<InputAdornment position='start'>$</InputAdornment>
          )
        }}
      />
    </Box>
  );
}

const PostQuickCreateToolbar = ({ submitting, onCancel, ...props }) => (
  <Toolbar {...props} style={{ width: '100%', boxSizing: 'border-box', display: 'flex', justifyContent: 'space-between' }}>
    <SaveButton />
    <CancelButton onClick={onCancel} />
  </Toolbar>
);

const useStyles = makeStyles({
  form: { padding: 0 },
});

const PeopleDialog = ({ onCancel, onSave, record, ...props }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const submitting = useSelector(state => state.admin.loading > 0);

  const handleSave = useCallback(
    values => {
      dispatch({
        type: "QUICK_CREATE",
        payload: { data: values },
        meta: {
          fetch: CREATE,
          resource: "truckowners",
          onSuccess: {
            callback: ({ payload: { data } }) => {
              onSave(data);
              dispatch(showNotification("Dueño " + data.name + "creado con éxito", "info"));
            },
          },
          onFailure: {
            callback: ({ error }) => {
              dispatch(showNotification(error, "error"));
            },
          },
        },
      });
    },
    [dispatch, onSave],
  );

  return (
    <SimpleForm
      save={handleSave}
      saving={submitting}
      redirect={false}
      initialValues={{initial_adjustment:0}}
      toolbar={
        <PostQuickCreateToolbar onCancel={onCancel} submitting={submitting} />
      }
      classes={{ form: classes.form }}
      {...props}
    >
      <PeopleSimpleFields />
    </SimpleForm >
  );
};

export default PeopleDialog;
