import {
  TextInput,
  FormDataConsumer,
  SimpleFormIterator,
  ArrayInput,
} from "react-admin";
import {
  Box,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { CustomAddButton } from './CustomAddButton';
import { CustomDeleteButton } from './CustomDeleteButton';
import { PeopleSimpleFields } from "./PeopleDialogs";
import { LocationInput, CustomName } from "./LocationSelect";

const useStyles = makeStyles({
  array: {
    alignItems: 'center',
  }
})

const PeopleCreate = ({ title }) => {
  const classes = useStyles();
  return (
    <>
      <PeopleSimpleFields title={`Nuevo ${title}`} />
      <Typography variant="subtitle2" component="span">Establecimientos</Typography>
      <ArrayInput source='settlements' style={{ maxWidth: '46vw' }} label="">
        <SimpleFormIterator disableReordering={true} getItemLabel={(index) => ''} addButton={<CustomAddButton label={"AGREGAR ESTABLECIMIENTO"} />} removeButton={<CustomDeleteButton className={classes.array} />}>
          <FormDataConsumer>
            {({ getSource, scopedFormData }) => {
              return (
                <Box display='flex' flexDirection='column' ml={'1em'}>
                  <Box display="flex">
                    <Box mr={3}>
                      <LocationInput source={getSource('locale')} />
                    </Box>
                    <Box mr={3}>
                      <TextInput source={getSource("settlement")} label="Establecimiento" variant="filled" />
                    </Box>
                    {scopedFormData?.locale && <CustomName source={getSource("localename")} record={scopedFormData} />}
                  </Box>
                </Box>
              );
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </>
  );
};

export default PeopleCreate;
