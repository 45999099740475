import { Fragment } from "react";
import { useDispatch } from "react-redux";
import { useCallback, useState, useEffect } from "react";
import { Button, Dialog } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import TruckDialog from "./TruckDialog";
import { useForm, useFormState } from "react-final-form";
import { crudGetMatching, useGetOne } from "react-admin"; // eslint-disable-line import/no-unresolved

export const TruckQuickCreate = ({source, owner_id, truck_id}) => {
    const dispatch = useDispatch();
    const { values } = useFormState();
    const { change } = useForm();
    const [showCreateDialog, setShowCreateDialog] = useState(false);
    const [newTruckId, setNewTruckId] = useState("");
    const { data } = useGetOne("trucks",truck_id);

    const handleNewClick = useCallback(
        (event) => {
            event.stopPropagation();
            event.preventDefault();
            setShowCreateDialog(true);
        },
        [setShowCreateDialog]
    );

    const handleCloseCreate = useCallback(() => {
        setShowCreateDialog(false);
    }, [setShowCreateDialog]);

    const handleSave = useCallback(
        truck => {
            setShowCreateDialog(false);
            setNewTruckId(truck.id);
            change(source, truck.id);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [setShowCreateDialog, change],
    );

    useEffect(() => {
        dispatch(
            crudGetMatching(
                "trucks",
                "trucks@truck_id",
                { page: 1, perPage: 25 },
                { field: "id", order: "DESC" },
            ),
        );
    }, [dispatch, newTruckId]);
    return (
        <Fragment>
            <Button style={{ maxHeight: '40px', marginTop: '8px', border: '0', marginLeft: '1em' }} size="small" onClick={handleNewClick} startIcon={truck_id ? <EditIcon/> : < AddIcon />}>
            {truck_id ? "Editar" : "NUEVO CAMION"}
            </Button>
            <Dialog
                fullWidth
                open={showCreateDialog}
                onClose={handleCloseCreate}
                onClick={(e) => e.stopPropagation()}
                aria-label={truck_id ? "Editar" : "Nuevo Camion"}
            >
                <DialogTitle>{truck_id ? "Editar Camion" : "Nuevo Camion"}</DialogTitle>
                <DialogContent style={{ padding: 0 }}>
                    <TruckDialog
                        data={data}
                        owner_id={owner_id}
                        onCancel={handleCloseCreate}
                        onSave={handleSave}
                        basePath="/trucks"
                        resource="trucks"
                        record={values}
                    />
                </DialogContent>
            </Dialog>
        </Fragment>
    )
}
