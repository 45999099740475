export const cebada_manipuleo = 0.20;
export const girasol_manipuleo = 0.20;
export const maiz_manipuleo = 0.25;
export const soja_manipuleo = 0.25;
export const trigo_manipuleo = 0.10;
export const sorgo_granifero_manipuleo = 0.25;
export const cebada_volatil = 0.3;
export const girasol_volatil = 0.5;
export const maiz_volatil = 0.3;
export const soja_volatil = 0.5;
export const trigo_volatil = 0.3;
export const sorgo_granifero_volatil = 0.2;



/** tables */
export const sorgo_granifero = [
    { id: 1, name:'0', merma: '0'},
    { id: 15.1, name:'15.1', merma: 1.85 },
    { id: 15.2, name:'15.2', merma: 1.97 },
    { id: 15.3, name:'15.3', merma: 2.08 },
    { id: 15.4, name:'15.4', merma: 2.20 },
    { id: 15.5, name:'15.5', merma: 2.31 },
    { id: 15.6, name:'15.6', merma: 2.43 },
    { id: 15.7, name:'15.7', merma: 2.54 },
    { id: 15.8, name:'15.8', merma: 2.66 },
    { id: 15.9, name:'15.9', merma: 2.77 },
    { id: 16.0, name:'16.0', merma: 2.89 },
    { id: 16.1, name:'16.1', merma: 3.01 },
    { id: 16.2, name:'16.2', merma: 3.12 },
    { id: 16.3, name:'16.3', merma: 3.24 },
    { id: 16.4, name:'16.4', merma: 3.35 },
    { id: 16.5, name:'16.5', merma: 3.47 },
    { id: 16.6, name:'16.6', merma: 3.58 },
    { id: 16.7, name:'16.7', merma: 3.70 },
    { id: 16.8, name:'16.8', merma: 3.82 },
    { id: 16.9, name:'16.9', merma: 3.93 },
    { id: 17.0, name:'17.0', merma: 4.05 },
    { id: 17.1, name:'17.1', merma: 4.16 },
    { id: 17.2, name:'17.2', merma: 4.28 },
    { id: 17.3, name:'17.3', merma: 4.39 },
    { id: 17.4, name:'17.4', merma: 4.51 },
    { id: 17.5, name:'17.5', merma: 4.62 },
    { id: 17.6, name:'17.6', merma: 4.74 },
    { id: 17.7, name:'17.7', merma: 4.86 },
    { id: 17.8, name:'17.8', merma: 4.97 },
    { id: 17.9, name:'17.9', merma: 5.09 },
    { id: 18.0, name:'18.0', merma: 5.20 },
    { id: 18.1, name:'18.1', merma: 5.32 },
    { id: 18.2, name:'18.2', merma: 5.43 },
    { id: 18.3, name:'18.3', merma: 5.55 },
    { id: 18.4, name:'18.4', merma: 5.66 },
    { id: 18.5, name:'18.5', merma: 5.78 },
    { id: 18.6, name:'18.6', merma: 5.90 },
    { id: 18.7, name:'18.7', merma: 6.01 },
    { id: 18.8, name:'18.8', merma: 6.13 },
    { id: 18.9, name:'18.9', merma: 6.24 },
    { id: 19.0, name:'19.0', merma: 6.36 },
    { id: 19.1, name:'19.1', merma: 6.47 },
    { id: 19.2, name:'19.2', merma: 6.59 },
    { id: 19.3, name:'19.3', merma: 6.71 },
    { id: 19.4, name:'19.4', merma: 6.82 },
    { id: 19.5, name:'19.5', merma: 6.94 },
    { id: 19.6, name:'19.6', merma: 7.05 },
    { id: 19.7, name:'19.7', merma: 7.17 },
    { id: 19.8, name:'19.8', merma: 7.28 },
    { id: 19.9, name:'19.9', merma: 7.40 },
    { id: 20.0, name:'20.0', merma: 7.51 },
    { id: 20.1, name:'20.1', merma: 7.63 },
    { id: 20.2, name:'20.2', merma: 7.75 },
    { id: 20.3, name:'20.3', merma: 7.86 },
    { id: 20.4, name:'20.4', merma: 7.98 },
    { id: 20.5, name:'20.5', merma: 8.09 },
    { id: 20.6, name:'20.6', merma: 8.21 },
    { id: 20.7, name:'20.7', merma: 8.32 },
    { id: 20.8, name:'20.8', merma: 8.44 },
    { id: 20.9, name:'20.9', merma: 8.55 },
    { id: 21.0, name:'21.0', merma: 8.67 },
    { id: 21.1, name:'21.1', merma: 8.79 },
    { id: 21.2, name:'21.2', merma: 8.90 },
    { id: 21.3, name:'21.3', merma: 9.02 },
    { id: 21.4, name:'21.4', merma: 9.13 },
    { id: 21.5, name:'21.5', merma: 9.25 },
    { id: 21.6, name:'21.6', merma: 9.36 },
    { id: 21.7, name:'21.7', merma: 9.48 },
    { id: 21.8, name:'21.8', merma: 9.60 },
    { id: 21.9, name:'21.9', merma: 9.71 },
    { id: 22.0, name:'22.0', merma: 9.83 },
    { id: 22.1, name:'22.1', merma: 9.94 },
    { id: 22.2, name:'22.2', merma: 10.06 },
    { id: 22.3, name:'22.3', merma: 10.17 },
    { id: 22.4, name:'22.4', merma: 10.29 },
    { id: 22.5, name:'22.5', merma: 10.40 },
    { id: 22.6, name:'22.6', merma: 10.52 },
    { id: 22.7, name:'22.7', merma: 10.64 },
    { id: 22.8, name:'22.8', merma: 10.75 },
    { id: 22.9, name:'22.9', merma: 10.87 },
    { id: 23.0, name:'23.0', merma: 10.98 },
    { id: 23.1, name:'23.1', merma: 11.10 },
    { id: 23.2, name:'23.2', merma: 11.21 },
    { id: 23.3, name:'23.3', merma: 11.33 },
    { id: 23.4, name:'23.4', merma: 11.45 },
    { id: 23.5, name:'23.5', merma: 11.56 },
    { id: 23.6, name:'23.6', merma: 11.68 },
    { id: 23.7, name:'23.7', merma: 11.79 },
    { id: 23.8, name:'23.8', merma: 11.91 },
    { id: 23.9, name:'23.9', merma: 12.02 },
    { id: 24.0, name:'24.0', merma: 12.14 },
    { id: 24.1, name:'24.1', merma: 12.25 },
    { id: 24.2, name:'24.2', merma: 12.37 },
    { id: 24.3, name:'24.3', merma: 12.49 },
    { id: 24.4, name:'24.4', merma: 12.60 },
    { id: 24.5, name:'24.5', merma: 12.72 },
    { id: 24.6, name:'24.6', merma: 12.83 },
    { id: 24.7, name:'24.7', merma: 12.95 },
    { id: 24.8, name:'24.8', merma: 13.06 },
    { id: 24.9, name:'24.9', merma: 13.18 },
    { id: 25.0, name:'25.0', merma: 13.29 }
];

export const trigo = [
    { id: 1, name:'0', merma: '0'},
    { id: 14.10, name:'14.10', merma: 0.69 },
    { id: 14.20, name:'14.20', merma: 0.81 },
    { id: 14.30, name:'14.30', merma: 0.92 },
    { id: 14.40, name:'14.40', merma: 1.04 },
    { id: 14.50, name:'14.50', merma: 1.16 },
    { id: 14.60, name:'14.60', merma: 1.27 },
    { id: 14.70, name:'14.70', merma: 1.39 },
    { id: 14.80, name:'14.80', merma: 1.50 },
    { id: 14.90, name:'14.90', merma: 1.62 },
    { id: 15.00, name:'15.00', merma: 1.73 },
    { id: 15.10, name:'15.10', merma: 1.85 },
    { id: 15.20, name:'15.20', merma: 1.97 },
    { id: 15.30, name:'15.30', merma: 2.08 },
    { id: 15.40, name:'15.40', merma: 2.20 },
    { id: 15.50, name:'15.50', merma: 2.31 },
    { id: 15.60, name:'15.60', merma: 2.43 },
    { id: 15.70, name:'15.70', merma: 2.54 },
    { id: 15.80, name:'15.80', merma: 2.66 },
    { id: 15.90, name:'15.90', merma: 2.77 },
    { id: 16.00, name:'16.00', merma: 2.89 },
    { id: 16.10, name:'16.10', merma: 3.01 },
    { id: 16.20, name:'16.20', merma: 3.12 },
    { id: 16.30, name:'16.30', merma: 3.24 },
    { id: 16.40, name:'16.40', merma: 3.35 },
    { id: 16.50, name:'16.50', merma: 3.47 },
    { id: 16.60, name:'16.60', merma: 3.58 },
    { id: 16.70, name:'16.70', merma: 3.70 },
    { id: 16.80, name:'16.80', merma: 3.81 },
    { id: 16.90, name:'16.90', merma: 3.93 },
    { id: 17.00, name:'17.00', merma: 4.05 },
    { id: 17.10, name:'17.10', merma: 4.16 },
    { id: 17.20, name:'17.20', merma: 4.28 },
    { id: 17.30, name:'17.30', merma: 4.39 },
    { id: 17.40, name:'17.40', merma: 4.51 },
    { id: 17.50, name:'17.50', merma: 4.62 },
    { id: 17.60, name:'17.60', merma: 4.74 },
    { id: 17.70, name:'17.70', merma: 4.86 },
    { id: 17.80, name:'17.80', merma: 4.97 },
    { id: 17.90, name:'17.90', merma: 5.09 },
    { id: 18.00, name:'18.00', merma: 5.20 },
    { id: 18.10, name:'18.10', merma: 5.32 },
    { id: 18.20, name:'18.20', merma: 5.43 },
    { id: 18.30, name:'18.30', merma: 5.55 },
    { id: 18.40, name:'18.40', merma: 5.66 },
    { id: 18.50, name:'18.50', merma: 5.78 },
    { id: 18.60, name:'18.60', merma: 5.90 },
    { id: 18.70, name:'18.70', merma: 6.01 },
    { id: 18.80, name:'18.80', merma: 6.13 },
    { id: 18.90, name:'18.90', merma: 6.24 },
    { id: 19.00, name:'19.00', merma: 6.36 },
    { id: 19.10, name:'19.10', merma: 6.47 },
    { id: 19.20, name:'19.20', merma: 6.59 },
    { id: 19.30, name:'19.30', merma: 6.71 },
    { id: 19.40, name:'19.40', merma: 6.82 },
    { id: 19.50, name:'19.50', merma: 6.94 },
    { id: 19.60, name:'19.60', merma: 7.05 },
    { id: 19.70, name:'19.70', merma: 7.17 },
    { id: 19.80, name:'19.80', merma: 7.28 },
    { id: 19.90, name:'19.90', merma: 7.40 },
    { id: 20.00, name:'20.00', merma: 7.51 },
    { id: 20.10, name:'20.10', merma: 7.63 },
    { id: 20.20, name:'20.20', merma: 7.75 },
    { id: 20.30, name:'20.30', merma: 7.86 },
    { id: 20.40, name:'20.40', merma: 7.98 },
    { id: 20.50, name:'20.50', merma: 8.09 },
    { id: 20.60, name:'20.60', merma: 8.21 },
    { id: 20.70, name:'20.70', merma: 8.32 },
    { id: 20.80, name:'20.80', merma: 8.44 },
    { id: 20.90, name:'20.90', merma: 8.55 },
    { id: 21.00, name:'21.00', merma: 8.67 },
    { id: 21.10, name:'21.10', merma: 8.79 },
    { id: 21.20, name:'21.20', merma: 8.90 },
    { id: 21.30, name:'21.30', merma: 9.02 },
    { id: 21.40, name:'21.40', merma: 9.13 },
    { id: 21.50, name:'21.50', merma: 9.25 },
    { id: 21.60, name:'21.60', merma: 9.36 },
    { id: 21.70, name:'21.70', merma: 9.48 },
    { id: 21.80, name:'21.80', merma: 9.60 },
    { id: 21.90, name:'21.90', merma: 9.71 },
    { id: 22.00, name:'22.00', merma: 9.83 },
    { id: 22.10, name:'22.10', merma: 9.94 },
    { id: 22.20, name:'22.20', merma: 10.06 },
    { id: 22.30, name:'22.30', merma: 10.17 },
    { id: 22.40, name:'22.40', merma: 10.29 },
    { id: 22.50, name:'22.50', merma: 10.40 },
    { id: 22.60, name:'22.60', merma: 10.52 },
    { id: 22.70, name:'22.70', merma: 10.64 },
    { id: 22.80, name:'22.80', merma: 10.75 },
    { id: 22.90, name:'22.90', merma: 10.87 },
    { id: 23.00, name:'23.00', merma: 10.98 },
    { id: 23.10, name:'23.10', merma: 11.10 },
    { id: 23.20, name:'23.20', merma: 11.21 },
    { id: 23.30, name:'23.30', merma: 11.33 },
    { id: 23.40, name:'23.40', merma: 11.45 },
    { id: 23.50, name:'23.50', merma: 11.56 },
    { id: 23.60, name:'23.60', merma: 11.68 },
    { id: 23.70, name:'23.70', merma: 11.79 },
    { id: 23.80, name:'23.80', merma: 11.91 },
    { id: 23.90, name:'23.90', merma: 12.02 },
    { id: 24.00, name:'24.00', merma: 12.14 },
    { id: 24.10, name:'24.10', merma: 12.25 },
    { id: 24.20, name:'24.20', merma: 12.37 },
    { id: 24.30, name:'24.30', merma: 12.49 },
    { id: 24.40, name:'24.40', merma: 12.60 },
    { id: 24.50, name:'24.50', merma: 12.72 },
    { id: 24.60, name:'24.60', merma: 12.83 },
    { id: 24.70, name:'24.70', merma: 12.95 },
    { id: 24.80, name:'24.80', merma: 13.06 },
    { id: 24.90, name:'24.90', merma: 13.18 },
    { id: 25.00, name:'25.00', merma: 13.29 },
];

export const soja = [
    { id: 1, name:'0', merma: '0'},
    { id: 13.6, name:'13.6', merma: 0.69 },
    { id: 13.7, name:'13.7', merma: 0.80 },
    { id: 13.8, name:'13.8', merma: 0.92 },
    { id: 13.9, name:'13.9', merma: 1.03 },
    { id: 14.0, name:'14.0', merma: 1.15 },
    { id: 14.1, name:'14.1', merma: 1.26 },
    { id: 14.2, name:'14.2', merma: 1.38 },
    { id: 14.3, name:'14.3', merma: 1.49 },
    { id: 14.4, name:'14.4', merma: 1.61 },
    { id: 14.5, name:'14.5', merma: 1.72 },
    { id: 14.6, name:'14.6', merma: 1.84 },
    { id: 14.7, name:'14.7', merma: 1.95 },
    { id: 14.8, name:'14.8', merma: 2.07 },
    { id: 14.9, name:'14.9', merma: 2.18 },
    { id: 15.0, name:'15.0', merma: 2.30 },
    { id: 15.1, name:'15.1', merma: 2.41 },
    { id: 15.2, name:'15.2', merma: 2.53 },
    { id: 15.3, name:'15.3', merma: 2.64 },
    { id: 15.4, name:'15.4', merma: 2.76 },
    { id: 15.5, name:'15.5', merma: 2.87 },
    { id: 15.6, name:'15.6', merma: 2.99 },
    { id: 15.7, name:'15.7', merma: 3.10 },
    { id: 15.8, name:'15.8', merma: 3.22 },
    { id: 15.9, name:'15.9', merma: 3.33 },
    { id: 16.0, name:'16.0', merma: 3.45 },
    { id: 16.1, name:'16.1', merma: 3.56 },
    { id: 16.2, name:'16.2', merma: 3.68 },
    { id: 16.3, name:'16.3', merma: 3.79 },
    { id: 16.4, name:'16.4', merma: 3.91 },
    { id: 16.5, name:'16.5', merma: 4.02 },
    { id: 16.6, name:'16.6', merma: 4.14 },
    { id: 16.7, name:'16.7', merma: 4.25 },
    { id: 16.8, name:'16.8', merma: 4.37 },
    { id: 16.9, name:'16.9', merma: 4.48 },
    { id: 17.0, name:'17.0', merma: 4.60 },
    { id: 17.1, name:'17.1', merma: 4.71 },
    { id: 17.2, name:'17.2', merma: 4.83 },
    { id: 17.3, name:'17.3', merma: 4.94 },
    { id: 17.4, name:'17.4', merma: 5.06 },
    { id: 17.5, name:'17.5', merma: 5.17 },
    { id: 17.6, name:'17.6', merma: 5.29 },
    { id: 17.7, name:'17.7', merma: 5.40 },
    { id: 17.8, name:'17.8', merma: 5.52 },
    { id: 17.9, name:'17.9', merma: 5.63 },
    { id: 18.0, name:'18.0', merma: 5.75 },
    { id: 18.1, name:'18.1', merma: 5.86 },
    { id: 18.2, name:'18.2', merma: 5.98 },
    { id: 18.3, name:'18.3', merma: 6.09 },
    { id: 18.4, name:'18.4', merma: 6.21 },
    { id: 18.5, name:'18.5', merma: 6.32 },
    { id: 18.6, name:'18.6', merma: 6.44 },
    { id: 18.7, name:'18.7', merma: 6.55 },
    { id: 18.8, name:'18.8', merma: 6.67 },
    { id: 18.9, name:'18.9', merma: 6.78 },
    { id: 19.0, name:'19.0', merma: 6.90 },
    { id: 19.1, name:'19.1', merma: 7.01 },
    { id: 19.2, name:'19.2', merma: 7.13 },
    { id: 19.3, name:'19.3', merma: 7.24 },
    { id: 19.4, name:'19.4', merma: 7.36 },
    { id: 19.5, name:'19.5', merma: 7.47 },
    { id: 19.6, name:'19.6', merma: 7.59 },
    { id: 19.7, name:'19.7', merma: 7.70 },
    { id: 19.8, name:'19.8', merma: 7.82 },
    { id: 19.9, name:'19.9', merma: 7.93 },
    { id: 20.0, name:'20.0', merma: 8.05 },
    { id: 20.1, name:'20.1', merma: 8.16 },
    { id: 20.2, name:'20.2', merma: 8.28 },
    { id: 20.3, name:'20.3', merma: 8.39 },
    { id: 20.4, name:'20.4', merma: 8.51 },
    { id: 20.5, name:'20.5', merma: 8.62 },
    { id: 20.6, name:'20.6', merma: 8.74 },
    { id: 20.7, name:'20.7', merma: 8.85 },
    { id: 20.8, name:'20.8', merma: 8.97 },
    { id: 20.9, name:'20.9', merma: 9.08 },
    { id: 21.0, name:'21.0', merma: 9.20 },
    { id: 21.1, name:'21.1', merma: 9.31 },
    { id: 21.2, name:'21.2', merma: 9.43 },
    { id: 21.3, name:'21.3', merma: 9.54 },
    { id: 21.4, name:'21.4', merma: 9.66 },
    { id: 21.5, name:'21.5', merma: 9.77 },
    { id: 21.6, name:'21.6', merma: 9.89 },
    { id: 21.7, name:'21.7', merma: 10.00 },
    { id: 21.8, name:'21.8', merma: 10.11 },
    { id: 21.9, name:'21.9', merma: 10.23 },
    { id: 22.0, name:'22.0', merma: 10.34 },
    { id: 22.1, name:'22.1', merma: 10.46 },
    { id: 22.2, name:'22.2', merma: 10.57 },
    { id: 22.3, name:'22.3', merma: 10.69 },
    { id: 22.4, name:'22.4', merma: 10.80 },
    { id: 22.5, name:'22.5', merma: 10.92 },
    { id: 22.6, name:'22.6', merma: 11.03 },
    { id: 22.7, name:'22.7', merma: 11.15 },
    { id: 22.8, name:'22.8', merma: 11.26 },
    { id: 22.9, name:'22.9', merma: 11.38 },
    { id: 23.0, name:'23.0', merma: 11.49 },
    { id: 23.1, name:'23.1', merma: 11.61 },
    { id: 23.2, name:'23.2', merma: 11.72 },
    { id: 23.3, name:'23.3', merma: 11.84 },
    { id: 23.4, name:'23.4', merma: 11.95 },
    { id: 23.5, name:'23.5', merma: 12.07 },
    { id: 23.6, name:'23.6', merma: 12.18 },
    { id: 23.7, name:'23.7', merma: 12.30 },
    { id: 23.8, name:'23.8', merma: 12.41 },
    { id: 23.9, name:'23.9', merma: 12.53 },
    { id: 24.0, name:'24.0', merma: 12.64 },
    { id: 24.1, name:'24.1', merma: 12.76 },
    { id: 24.2, name:'24.2', merma: 12.87 },
    { id: 24.3, name:'24.3', merma: 12.99 },
    { id: 24.4, name:'24.4', merma: 13.10 },
    { id: 24.5, name:'24.5', merma: 13.22 },
    { id: 24.6, name:'24.6', merma: 13.33 },
    { id: 24.7, name:'24.7', merma: 13.45 },
    { id: 24.8, name:'24.8', merma: 13.56 },
    { id: 24.9, name:'24.9', merma: 13.68 },
    { id: 25.0, name:'25.0', merma: 13.79 }
];
export const maiz = [
    { id: 1, name:'0', merma: '0'},
    { id: 14.6, name:'14.6', merma: 1.27 },
    { id: 14.7, name:'14.7', merma: 1.39 },
    { id: 14.8, name:'14.8', merma: 1.50 },
    { id: 14.9, name:'14.9', merma: 1.62 },
    { id: 15.0, name:'15.0', merma: 1.73 },
    { id: 15.1, name:'15.1', merma: 1.85 },
    { id: 15.2, name:'15.2', merma: 1.97 },
    { id: 15.3, name:'15.3', merma: 2.08 },
    { id: 15.4, name:'15.4', merma: 2.20 },
    { id: 15.5, name:'15.5', merma: 2.31 },
    { id: 15.6, name:'15.6', merma: 2.43 },
    { id: 15.7, name:'15.7', merma: 2.54 },
    { id: 15.8, name:'15.8', merma: 2.66 },
    { id: 15.9, name:'15.9', merma: 2.77 },
    { id: 16.0, name:'16.0', merma: 2.89 },
    { id: 16.1, name:'16.1', merma: 3.01 },
    { id: 16.2, name:'16.2', merma: 3.12 },
    { id: 16.3, name:'16.3', merma: 3.24 },
    { id: 16.4, name:'16.4', merma: 3.35 },
    { id: 16.5, name:'16.5', merma: 3.47 },
    { id: 16.6, name:'16.6', merma: 3.58 },
    { id: 16.7, name:'16.7', merma: 3.70 },
    { id: 16.8, name:'16.8', merma: 3.82 },
    { id: 16.9, name:'16.9', merma: 3.93 },
    { id: 17.0, name:'17.0', merma: 4.05 },
    { id: 17.1, name:'17.1', merma: 4.16 },
    { id: 17.2, name:'17.2', merma: 4.28 },
    { id: 17.3, name:'17.3', merma: 4.39 },
    { id: 17.4, name:'17.4', merma: 4.51 },
    { id: 17.5, name:'17.5', merma: 4.62 },
    { id: 17.6, name:'17.6', merma: 4.74 },
    { id: 17.7, name:'17.7', merma: 4.86 },
    { id: 17.8, name:'17.8', merma: 4.97 },
    { id: 17.9, name:'17.9', merma: 5.09 },
    { id: 18.0, name:'18.0', merma: 5.20 },
    { id: 18.1, name:'18.1', merma: 5.32 },
    { id: 18.2, name:'18.2', merma: 5.43 },
    { id: 18.3, name:'18.3', merma: 5.55 },
    { id: 18.4, name:'18.4', merma: 5.66 },
    { id: 18.5, name:'18.5', merma: 5.78 },
    { id: 18.6, name:'18.6', merma: 5.90 },
    { id: 18.7, name:'18.7', merma: 6.01 },
    { id: 18.8, name:'18.8', merma: 6.13 },
    { id: 18.9, name:'18.9', merma: 6.24 },
    { id: 19.0, name:'19.0', merma: 6.36 },
    { id: 19.1, name:'19.1', merma: 6.47 },
    { id: 19.2, name:'19.2', merma: 6.59 },
    { id: 19.3, name:'19.3', merma: 6.71 },
    { id: 19.4, name:'19.4', merma: 6.82 },
    { id: 19.5, name:'19.5', merma: 6.94 },
    { id: 19.6, name:'19.6', merma: 7.05 },
    { id: 19.7, name:'19.7', merma: 7.17 },
    { id: 19.8, name:'19.8', merma: 7.28 },
    { id: 19.9, name:'19.9', merma: 7.40 },
    { id: 20.0, name:'20.0', merma: 7.51 },
    { id: 20.1, name:'20.1', merma: 7.63 },
    { id: 20.2, name:'20.2', merma: 7.75 },
    { id: 20.3, name:'20.3', merma: 7.86 },
    { id: 20.4, name:'20.4', merma: 7.98 },
    { id: 20.5, name:'20.5', merma: 8.09 },
    { id: 20.6, name:'20.6', merma: 8.21 },
    { id: 20.7, name:'20.7', merma: 8.32 },
    { id: 20.8, name:'20.8', merma: 8.44 },
    { id: 20.9, name:'20.9', merma: 8.55 },
    { id: 21.0, name:'21.0', merma: 8.67 },
    { id: 21.1, name:'21.1', merma: 8.79 },
    { id: 21.2, name:'21.2', merma: 8.90 },
    { id: 21.3, name:'21.3', merma: 9.02 },
    { id: 21.4, name:'21.4', merma: 9.13 },
    { id: 21.5, name:'21.5', merma: 9.25 },
    { id: 21.6, name:'21.6', merma: 9.36 },
    { id: 21.7, name:'21.7', merma: 9.48 },
    { id: 21.8, name:'21.8', merma: 9.60 },
    { id: 21.9, name:'21.9', merma: 9.71 },
    { id: 22.0, name:'22.0', merma: 9.83 },
    { id: 22.1, name:'22.1', merma: 9.94 },
    { id: 22.2, name:'22.2', merma: 10.06 },
    { id: 22.3, name:'22.3', merma: 10.17 },
    { id: 22.4, name:'22.4', merma: 10.29 },
    { id: 22.5, name:'22.5', merma: 10.40 },
    { id: 22.6, name:'22.6', merma: 10.52 },
    { id: 22.7, name:'22.7', merma: 10.64 },
    { id: 22.8, name:'22.8', merma: 10.75 },
    { id: 22.9, name:'22.9', merma: 10.87 },
    { id: 23.0, name:'23.0', merma: 10.98 },
    { id: 23.1, name:'23.1', merma: 11.10 },
    { id: 23.2, name:'23.2', merma: 11.21 },
    { id: 23.3, name:'23.3', merma: 11.33 },
    { id: 23.4, name:'23.4', merma: 11.45 },
    { id: 23.5, name:'23.5', merma: 11.56 },
    { id: 23.6, name:'23.6', merma: 11.68 },
    { id: 23.7, name:'23.7', merma: 11.79 },
    { id: 23.8, name:'23.8', merma: 11.91 },
    { id: 23.9, name:'23.9', merma: 12.02 },
    { id: 24.0, name:'24.0', merma: 12.14 },
    { id: 24.1, name:'24.1', merma: 12.25 },
    { id: 24.2, name:'24.2', merma: 12.37 },
    { id: 24.3, name:'24.3', merma: 12.49 },
    { id: 24.4, name:'24.4', merma: 12.60 },
    { id: 24.5, name:'24.5', merma: 12.72 },
    { id: 24.6, name:'24.6', merma: 12.83 },
    { id: 24.7, name:'24.7', merma: 12.95 },
    { id: 24.8, name:'24.8', merma: 13.06 },
    { id: 24.9, name:'24.9', merma: 13.18 },
    { id: 25.0, name:'25.0', merma: 13.29 },
];

export const cebada = [
    { id: 1, name:'0', merma: '0'},
    { id: 14.10, name:'14.10', merma: 0.69 },
    { id: 14.20, name:'14.20', merma: 0.81 },
    { id: 14.30, name:'14.30', merma: 0.92 },
    { id: 14.40, name:'14.40', merma: 1.04 },
    { id: 14.50, name:'14.50', merma: 1.16 },
    { id: 14.60, name:'14.60', merma: 1.27 },
    { id: 14.70, name:'14.70', merma: 1.39 },
    { id: 14.80, name:'14.80', merma: 1.50 },
    { id: 14.90, name:'14.90', merma: 1.62 },
    { id: 15.00, name:'15.00', merma: 1.73 },
    { id: 15.10, name:'15.10', merma: 1.85 },
    { id: 15.20, name:'15.20', merma: 1.97 },
    { id: 15.30, name:'15.30', merma: 2.08 },
    { id: 15.40, name:'15.40', merma: 2.20 },
    { id: 15.50, name:'15.50', merma: 2.31 },
    { id: 15.60, name:'15.60', merma: 2.43 },
    { id: 15.70, name:'15.70', merma: 2.54 },
    { id: 15.80, name:'15.80', merma: 2.66 },
    { id: 15.90, name:'15.90', merma: 2.77 },
    { id: 16.00, name:'16.00', merma: 2.89 },
    { id: 16.10, name:'16.10', merma: 3.01 },
    { id: 16.20, name:'16.20', merma: 3.12 },
    { id: 16.30, name:'16.30', merma: 3.24 },
    { id: 16.40, name:'16.40', merma: 3.35 },
    { id: 16.50, name:'16.50', merma: 3.47 },
    { id: 16.60, name:'16.60', merma: 3.58 },
    { id: 16.70, name:'16.70', merma: 3.70 },
    { id: 16.80, name:'16.80', merma: 3.82 },
    { id: 16.90, name:'16.90', merma: 3.93 },
    { id: 17.00, name:'17.00', merma: 4.05 },
    { id: 17.10, name:'17.10', merma: 4.16 },
    { id: 17.20, name:'17.20', merma: 4.28 },
    { id: 17.30, name:'17.30', merma: 4.39 },
    { id: 17.40, name:'17.40', merma: 4.51 },
    { id: 17.50, name:'17.50', merma: 4.62 },
    { id: 17.60, name:'17.60', merma: 4.74 },
    { id: 17.70, name:'17.70', merma: 4.86 },
    { id: 17.80, name:'17.80', merma: 4.97 },
    { id: 17.90, name:'17.90', merma: 5.09 },
    { id: 18.00, name:'18.00', merma: 5.20 },
    { id: 18.10, name:'18.10', merma: 5.32 },
    { id: 18.20, name:'18.20', merma: 5.43 },
    { id: 18.30, name:'18.30', merma: 5.55 },
    { id: 18.40, name:'18.40', merma: 5.66 },
    { id: 18.50, name:'18.50', merma: 5.78 },
    { id: 18.60, name:'18.60', merma: 5.90 },
    { id: 18.70, name:'18.70', merma: 6.01 },
    { id: 18.80, name:'18.80', merma: 6.13 },
    { id: 18.90, name:'18.90', merma: 6.24 },
    { id: 19.00, name:'19.00', merma: 6.36 },
    { id: 19.10, name:'19.10', merma: 6.47 },
    { id: 19.20, name:'19.20', merma: 6.59 },
    { id: 19.30, name:'19.30', merma: 6.71 },
    { id: 19.40, name:'19.40', merma: 6.82 },
    { id: 19.50, name:'19.50', merma: 6.94 },
    { id: 19.60, name:'19.60', merma: 7.05 },
    { id: 19.70, name:'19.70', merma: 7.17 },
    { id: 19.80, name:'19.80', merma: 7.28 },
    { id: 19.90, name:'19.90', merma: 7.40 },
    { id: 20.00, name:'20.00', merma: 7.51 },
    { id: 20.10, name:'20.10', merma: 7.63 },
    { id: 20.20, name:'20.20', merma: 7.75 },
    { id: 20.30, name:'20.30', merma: 7.86 },
    { id: 20.40, name:'20.40', merma: 7.98 },
    { id: 20.50, name:'20.50', merma: 8.09 },
    { id: 20.60, name:'20.60', merma: 8.21 },
    { id: 20.70, name:'20.70', merma: 8.32 },
    { id: 20.80, name:'20.80', merma: 8.44 },
    { id: 20.90, name:'20.90', merma: 8.55 },
    { id: 21.00, name:'21.00', merma: 8.67 },
    { id: 21.10, name:'21.10', merma: 8.79 },
    { id: 21.20, name:'21.20', merma: 8.90 },
    { id: 21.30, name:'21.30', merma: 9.02 },
    { id: 21.40, name:'21.40', merma: 9.13 },
    { id: 21.50, name:'21.50', merma: 9.25 },
    { id: 21.60, name:'21.60', merma: 9.36 },
    { id: 21.70, name:'21.70', merma: 9.48 },
    { id: 21.80, name:'21.80', merma: 9.60 },
    { id: 21.90, name:'21.90', merma: 9.71 },
    { id: 22.00, name:'22.00', merma: 9.83 },
    { id: 22.10, name:'22.10', merma: 9.94 },
    { id: 22.20, name:'22.20', merma: 10.06 },
    { id: 22.30, name:'22.30', merma: 10.17 },
    { id: 22.40, name:'22.40', merma: 10.29 },
    { id: 22.50, name:'22.50', merma: 10.40 },
    { id: 22.60, name:'22.60', merma: 10.52 },
    { id: 22.70, name:'22.70', merma: 10.64 },
    { id: 22.80, name:'22.80', merma: 10.75 },
    { id: 22.90, name:'22.90', merma: 10.87 },
    { id: 23.00, name:'23.00', merma: 10.98 },
    { id: 23.10, name:'23.10', merma: 11.10 },
    { id: 23.20, name:'23.20', merma: 11.21 },
    { id: 23.30, name:'23.30', merma: 11.33 },
    { id: 23.40, name:'23.40', merma: 11.45 },
    { id: 23.50, name:'23.50', merma: 11.56 },
    { id: 23.60, name:'23.60', merma: 11.68 },
    { id: 23.70, name:'23.70', merma: 11.79 },
    { id: 23.80, name:'23.80', merma: 11.91 },
    { id: 23.90, name:'23.90', merma: 12.02 },
    { id: 24.00, name:'24.00', merma: 12.14 },
    { id: 24.10, name:'24.10', merma: 12.25 },
    { id: 24.20, name:'24.20', merma: 12.37 },
    { id: 24.30, name:'24.30', merma: 12.49 },
    { id: 24.40, name:'24.40', merma: 12.60 },
    { id: 24.50, name:'24.50', merma: 12.72 },
    { id: 24.60, name:'24.60', merma: 12.83 },
    { id: 24.70, name:'24.70', merma: 12.95 },
    { id: 24.80, name:'24.80', merma: 13.06 },
    { id: 24.90, name:'24.90', merma: 13.18 },
    { id: 25.00, name:'25.00', merma: 13.29 },
];

export const girasol = [
    { id: 1, name:'0', merma: '0'},
    { id: 11.1, name:'11.1', merma: 0.67 },
    { id: 11.2, name:'11.2', merma: 0.78 },
    { id: 11.3, name:'11.3', merma: 0.89 },
    { id: 11.4, name:'11.4', merma: 1.01 },
    { id: 11.5, name:'11.5', merma: 1.12 },
    { id: 11.6, name:'11.6', merma: 1.23 },
    { id: 11.7, name:'11.7', merma: 1.34 },
    { id: 11.8, name:'11.8', merma: 1.45 },
    { id: 11.9, name:'11.9', merma: 1.56 },
    { id: 12.0, name:'12.0', merma: 1.68 },
    { id: 12.1, name:'12.1', merma: 1.79 },
    { id: 12.2, name:'12.2', merma: 1.90 },
    { id: 12.3, name:'12.3', merma: 2.01 },
    { id: 12.4, name:'12.4', merma: 2.12 },
    { id: 12.5, name:'12.5', merma: 2.23 },
    { id: 12.6, name:'12.6', merma: 2.35 },
    { id: 12.7, name:'12.7', merma: 2.46 },
    { id: 12.8, name:'12.8', merma: 2.57 },
    { id: 12.9, name:'12.9', merma: 2.68 },
    { id: 13.0, name:'13.0', merma: 2.79 },
    { id: 13.1, name:'13.1', merma: 2.91 },
    { id: 13.2, name:'13.2', merma: 3.02 },
    { id: 13.3, name:'13.3', merma: 3.13 },
    { id: 13.4, name:'13.4', merma: 3.24 },
    { id: 13.5, name:'13.5', merma: 3.35 },
    { id: 13.6, name:'13.6', merma: 3.46 },
    { id: 13.7, name:'13.7', merma: 3.58 },
    { id: 13.8, name:'13.8', merma: 3.69 },
    { id: 13.9, name:'13.9', merma: 3.80 },
    { id: 14.0, name:'14.0', merma: 3.91 },
    { id: 14.1, name:'14.1', merma: 4.02 },
    { id: 14.2, name:'14.2', merma: 4.13 },
    { id: 14.3, name:'14.3', merma: 4.25 },
    { id: 14.4, name:'14.4', merma: 4.36 },
    { id: 14.5, name:'14.5', merma: 4.47 },
    { id: 14.6, name:'14.6', merma: 4.58 },
    { id: 14.7, name:'14.7', merma: 4.69 },
    { id: 14.8, name:'14.8', merma: 4.80 },
    { id: 14.9, name:'14.9', merma: 4.92 },
    { id: 15.0, name:'15.0', merma: 5.03 },
    { id: 15.1, name:'15.1', merma: 5.14 },
    { id: 15.2, name:'15.2', merma: 5.25 },
    { id: 15.3, name:'15.3', merma: 5.36 },
    { id: 15.4, name:'15.4', merma: 5.47 },
    { id: 15.5, name:'15.5', merma: 5.59 },
    { id: 15.6, name:'15.6', merma: 5.70 },
    { id: 15.7, name:'15.7', merma: 5.81 },
    { id: 15.8, name:'15.8', merma: 5.92 },
    { id: 15.9, name:'15.9', merma: 6.03 },
    { id: 16.0, name:'16.0', merma: 6.15 },
    { id: 16.1, name:'16.1', merma: 6.26 },
    { id: 16.2, name:'16.2', merma: 6.37 },
    { id: 16.3, name:'16.3', merma: 6.48 },
    { id: 16.4, name:'16.4', merma: 6.59 },
    { id: 16.5, name:'16.5', merma: 6.70 },
    { id: 16.6, name:'16.6', merma: 6.82 },
    { id: 16.7, name:'16.7', merma: 6.93 },
    { id: 16.8, name:'16.8', merma: 7.04 },
    { id: 16.9, name:'16.9', merma: 7.15 },
    { id: 17.0, name:'17.0', merma: 7.26 },
    { id: 17.1, name:'17.1', merma: 7.37 },
    { id: 17.2, name:'17.2', merma: 7.49 },
    { id: 17.3, name:'17.3', merma: 7.60 },
    { id: 17.4, name:'17.4', merma: 7.71 },
    { id: 17.5, name:'17.5', merma: 7.82 },
    { id: 17.6, name:'17.6', merma: 7.93 },
    { id: 17.7, name:'17.7', merma: 8.04 },
    { id: 17.8, name:'17.8', merma: 8.16 },
    { id: 17.9, name:'17.9', merma: 8.27 },
    { id: 18.0, name:'18.0', merma: 8.38 },
    { id: 18.1, name:'18.1', merma: 8.49 },
    { id: 18.2, name:'18.2', merma: 8.60 },
    { id: 18.3, name:'18.3', merma: 8.72 },
    { id: 18.4, name:'18.4', merma: 8.83 },
    { id: 18.5, name:'18.5', merma: 8.94 },
    { id: 18.6, name:'18.6', merma: 9.05 },
    { id: 18.7, name:'18.7', merma: 9.16 },
    { id: 18.8, name:'18.8', merma: 9.27 },
    { id: 18.9, name:'18.9', merma: 9.39 },
    { id: 19.0, name:'19.0', merma: 9.50 },
    { id: 19.1, name:'19.1', merma: 9.61 },
    { id: 19.2, name:'19.2', merma: 9.72 },
    { id: 19.3, name:'19.3', merma: 9.83 },
    { id: 19.4, name:'19.4', merma: 9.94 },
    { id: 19.5, name:'19.5', merma: 10.06 },
    { id: 19.6, name:'19.6', merma: 10.17 },
    { id: 19.7, name:'19.7', merma: 10.28 },
    { id: 19.8, name:'19.8', merma: 10.39 },
    { id: 19.9, name:'19.9', merma: 10.50 },
    { id: 20.0, name:'20.0', merma: 10.61 },
    { id: 20.1, name:'20.1', merma: 10.73 },
    { id: 20.2, name:'20.2', merma: 10.84 },
    { id: 20.3, name:'20.3', merma: 10.95 },
    { id: 20.4, name:'20.4', merma: 11.06 },
    { id: 20.5, name:'20.5', merma: 11.17 },
    { id: 20.6, name:'20.6', merma: 11.28 },
    { id: 20.7, name:'20.7', merma: 11.40 },
    { id: 20.8, name:'20.8', merma: 11.51 },
    { id: 20.9, name:'20.9', merma: 11.62 },
    { id: 21.0, name:'21.0', merma: 11.73 },
    { id: 21.1, name:'21.1', merma: 11.84 },
    { id: 21.2, name:'21.2', merma: 11.96 },
    { id: 21.3, name:'21.3', merma: 12.07 },
    { id: 21.4, name:'21.4', merma: 12.18 },
    { id: 21.5, name:'21.5', merma: 12.29 },
    { id: 21.6, name:'21.6', merma: 12.40 },
    { id: 21.7, name:'21.7', merma: 12.51 },
    { id: 21.8, name:'21.8', merma: 12.63 },
    { id: 21.9, name:'21.9', merma: 12.74 },
    { id: 22.0, name:'22.0', merma: 12.85 },
    { id: 22.1, name:'22.1', merma: 12.96 },
    { id: 22.2, name:'22.2', merma: 13.07 },
    { id: 22.3, name:'22.3', merma: 13.18 },
    { id: 22.4, name:'22.4', merma: 13.30 },
    { id: 22.5, name:'22.5', merma: 13.41 },
    { id: 22.6, name:'22.6', merma: 13.52 },
    { id: 22.7, name:'22.7', merma: 13.63 },
    { id: 22.8, name:'22.8', merma: 13.74 },
    { id: 22.9, name:'22.9', merma: 13.85 },
    { id: 23.0, name:'23.0', merma: 13.97 },
    { id: 23.1, name:'23.1', merma: 14.08 },
    { id: 23.2, name:'23.2', merma: 14.19 },
    { id: 23.3, name:'23.3', merma: 14.30 },
    { id: 23.4, name:'23.4', merma: 14.41 },
    { id: 23.5, name:'23.5', merma: 14.53 },
    { id: 23.6, name:'23.6', merma: 14.64 },
    { id: 23.7, name:'23.7', merma: 14.75 },
    { id: 23.8, name:'23.8', merma: 14.86 },
    { id: 23.9, name:'23.9', merma: 14.97 },
    { id: 24.0, name:'24.0', merma: 15.08 },
    { id: 24.1, name:'24.1', merma: 15.20 },
    { id: 24.2, name:'24.2', merma: 15.31 },
    { id: 24.3, name:'24.3', merma: 15.42 },
    { id: 24.4, name:'24.4', merma: 15.53 },
    { id: 24.5, name:'24.5', merma: 15.64 },
    { id: 24.6, name:'24.6', merma: 15.75 },
    { id: 24.7, name:'24.7', merma: 15.87 },
    { id: 24.8, name:'24.8', merma: 15.98 },
    { id: 24.9, name:'24.9', merma: 16.09 },
    { id: 25.0, name:'25.0', merma: 16.20 }
];

const names = [ 
    {name:'cebada','value' : cebada, 'manipuleo': cebada_manipuleo , 'volatil': cebada_volatil},
    {name:'girasol','value' : girasol, 'manipuleo': girasol_manipuleo,  'volatil': girasol_volatil},
    {name:'maiz','value' : maiz, 'manipuleo': maiz_manipuleo, 'volatil': maiz_volatil },
    {name:'soja','value' : soja, 'manipuleo': soja_manipuleo,  'volatil': soja_volatil},
    {name:'trigo','value' : trigo, 'manipuleo': trigo_manipuleo,  'volatil': trigo_volatil},
    {name:'sorgo_granifero','value' : sorgo_granifero, 'manipuleo': sorgo_granifero_manipuleo,  'volatil': sorgo_granifero_volatil},
];
export function getMerma(name){
    let selected = names.find(item => item.name === name);
    if (selected){
        return selected.value;
    }
    return [];
}

export function getMermaManipuleo(name){
    let selected =  names.find(item => item.name === name);
    if (selected){
        return {manipuleo: selected.manipuleo, volatil: selected.volatil};
    }
    return null;
}